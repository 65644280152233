import { forwardRef, Ref } from 'react';
import { PrincipalModel } from '@app/Models/WebApiModels';
import { ServerSelect } from './ServerSelect';
import { OnChangeValue } from 'react-select';

type PersonPickerProps<IsMulti extends boolean> = {
    url: string;
    value?: PrincipalModel | PrincipalModel[];
    onChange?: (value?: OnChangeValue<PrincipalModel, IsMulti> | null) => void;
    isDisabled?: boolean;
    multi?: IsMulti;
};

function PersonPickerInternal<IsMulti extends boolean>(props: PersonPickerProps<IsMulti>, ref?: React.ForwardedRef<ServerSelect<PrincipalModel, IsMulti>>) {
    return <ServerSelect<PrincipalModel, IsMulti> {...props} ref={ref} keyField="id" labelField="displayName" />;
}

export const PersonPicker = forwardRef(PersonPickerInternal) as <IsMulti extends boolean>(
    props: PersonPickerProps<IsMulti> & {
        ref?: React.ForwardedRef<ServerSelect<PrincipalModel, IsMulti>>;
    }
) => ReturnType<typeof PersonPickerInternal>;

import { forwardRef, Ref } from 'react';
import { CategoryDto } from '@app/Models/WebApiModels';
import { ServerSelect } from './ServerSelect';
import { OnChangeValue } from 'react-select';

type CategoryPickerProps = {
    url: string;
    value?: CategoryDto | CategoryDto[];
    onChange?: (value?: OnChangeValue<CategoryDto, false> | null) => void;
    isDisabled?: boolean;
};

export const CategoryPicker = forwardRef((props: CategoryPickerProps, ref: Ref<ServerSelect<CategoryDto>>) => {
    return <ServerSelect<CategoryDto> {...props} ref={ref} keyField="id" labelField="displayName" groupField="codeName" multi={false} />;
});

import { DateTimeService } from '@app/Services/DateTimeService';

export type FlightTarget = {
    id: string;
    airlineRcd: string;
    number: number;
    date: Date;
    legSequenceNumber: number;
};

export type FlightInformation = FlightTarget & {
    airlineRcd: string;
    originRcd: string;
    destinationRcd: string;
};

class TargetConstructor {
    
    private _parseRange = (range: string, reverse: boolean) => {
        const bytes = [];
        for (let i = 0; i < range.length; i += 2) {
            const byte = parseInt(range.substr(i, 2), 16);
            bytes.push(byte);
        }
        
        reverse && bytes.reverse();
        return bytes;
    }

    public fromFlightId = (flightId: string): FlightTarget => {
        //    Guid:  35918bc9-196d-40ea-9779-889d79b753f0
        //    Bytes: C9 8B 91 35 6D 19 EA 40 97 79 88 9D 79 B7 53 F0
        const guid = flightId.split('-');
        const bytes = [];

        bytes.push(...this._parseRange(guid[0], true));
        bytes.push(...this._parseRange(guid[1], true));
        bytes.push(...this._parseRange(guid[2], true));
        bytes.push(...this._parseRange(guid[3], false));
        bytes.push(...this._parseRange(guid[4], false));

        const date = DateTimeService.date(2000 + bytes[0], bytes[1] - 1, bytes[2]);
        const airlineRcd = String.fromCharCode(bytes[3]) + String.fromCharCode(bytes[4]);
        const flightNumber = bytes[5] + bytes[6] * 256;
        const legSequenceNumber = bytes[13];

        return {
            id: flightId,
            date: date,
            airlineRcd: airlineRcd,
            number: flightNumber,
            legSequenceNumber: legSequenceNumber
        };
    }

    public getFlightInformationFromFlightId = (flightId: string): FlightInformation => {
        //    Guid:  35918bc9-196d-40ea-9779-889d79b753f0
        //    Bytes: C9 8B 91 35 6D 19 EA 40 97 79 88 9D 79 B7 53 F0
        const guid = flightId.split('-');
        const bytes = [];

        bytes.push(...this._parseRange(guid[0], true));
        bytes.push(...this._parseRange(guid[1], true));
        bytes.push(...this._parseRange(guid[2], true));
        bytes.push(...this._parseRange(guid[3], false));
        bytes.push(...this._parseRange(guid[4], false));

        const date = DateTimeService.date(2000 + bytes[0], bytes[1] - 1, bytes[2]);
        const flightNumber = bytes[5] + bytes[6] * 256;
        const airlineRcd = String.fromCharCode(bytes[3], bytes[4]);
        const originRcd = String.fromCharCode(bytes[7], bytes[8], bytes[9]);
        const destinationRcd = String.fromCharCode(bytes[10], bytes[11], bytes[12]);      
        const legSequenceNumber = bytes[13];  

        return {
            id: flightId,
            date: date,
            number: flightNumber,
            destinationRcd: destinationRcd,
            originRcd: originRcd,
            airlineRcd: airlineRcd,
            legSequenceNumber: legSequenceNumber
        };
    }
}

export const targetConstructor = new TargetConstructor();
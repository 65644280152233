import { action, makeObservable, observable, transaction } from 'mobx';
import Axios, { CancelTokenSource } from 'axios';

import { PromiseCompletion } from '@app/Classes/PromiseCompletion';
import ApiService from '@app/Services/ApiService';
import { GanttFlight } from '../FlightsGanttTypes';

import { Range } from '../Classes/Range';

export type OnFlightChangedHandler = (flgihtId: string) => void;

export default class FlightStore<T extends GanttFlight> {
    @observable.shallow flights: Map<string, T> = new Map();
    private _cancellationSource: CancelTokenSource;
    private _flightsUrl: string;
    private _loader: PromiseCompletion;
    private _loadedDays: Set<number> = new Set();
    private _onFlightChanged: OnFlightChangedHandler;

    constructor(loader: PromiseCompletion, flightsUrl: string, onFlightChanged: OnFlightChangedHandler) {
        makeObservable(this);
        this._flightsUrl = flightsUrl;
        this._loader = loader;
        this._cancellationSource = Axios.CancelToken.source();
        this._onFlightChanged = onFlightChanged;
    }

    public cancel() {
        this._cancellationSource.cancel('Clear Flights');
        this._cancellationSource = Axios.CancelToken.source();
    }

    public updateUrl(url: string) {
        this._flightsUrl = url;
    }

    @action
    clear() {
        this.flights = new Map<string, T>();
        this._loadedDays.clear();
        this.cancel();
    }

    public hasDate(date: Date) {
        return this._loadedDays.has(date.getTime());
    }

    update(range: Range<Date>, clearView: boolean): Promise<void>;
    update(flightIds: string[], clearView: boolean): Promise<void>;

    @action.bound
    async update(rangeOrFlightIds: Range<Date> | string[], clearView: boolean) {
        const params = Array.isArray(rangeOrFlightIds) ? {
            ids: rangeOrFlightIds.join(',')
        } : {
            dateFrom: rangeOrFlightIds.from,
            dateTo: rangeOrFlightIds.to,
        };

        const { data: dailyData } = await this._loader.add(async () => {
            return await ApiService.get<T[]>(this._flightsUrl, params, {
                cancellationToken: this._cancellationSource.token,
                completion: this._loader
            });
        });

        transaction(() => {
            if (clearView) {
                this.clear();
            }
            const newIds: Set<string> = new Set();
            dailyData.forEach((item) => {
                const isUpdate = this.flights.has(item.id);
                this.flights.set(item.id, item);
                if (isUpdate) {
                    this._onFlightChanged(item.id);
                }
                this._loadedDays.add(item.date.getTime());
                newIds.add(item.id);
            });
            if (!clearView && !Array.isArray(rangeOrFlightIds)) {
                const flightsToRemove: string[] = [];
                for (const f of this.flights.values()) {
                    if (rangeOrFlightIds.from <= f.date && f.date <= rangeOrFlightIds.to && !newIds.has(f.id)) {
                        flightsToRemove.push(f.id);
                    }
                }
                for (const id of flightsToRemove) {
                    this.flights.delete(id);
                }
            }
        });
    }
}

import { ReactSelect } from './ReactSelect/ReactSelect';

type LongHaulShortHaulPickerProps = {
    isLongHaul?: boolean;
    isDisabled?: boolean;
    onChange: (isLongHaul?: boolean) => void;
};

enum LhShType {
    LongHaul = 'LongHaul',
    ShortHaul = 'ShortHaul'
}

export function LongHaulShortHaulPicker(props: LongHaulShortHaulPickerProps) {
    const { isLongHaul, isDisabled, onChange } = props;

    const options = [
        { label: 'Only Long Haul', value: LhShType.LongHaul },
        { label: 'Only Short Haul', value: LhShType.ShortHaul }
    ];

    let value: { label: string; value: LhShType } | undefined;
    if (isLongHaul === true) value = options[0];
    if (isLongHaul === false) value = options[1];

    return (
        <ReactSelect
            value={value}
            options={options}
            placeholder=""
            isClearable
            isDisabled={isDisabled}
            onSelect={(value) => {
                const val = Array.isArray(value) ? value[0] : value;

                let isLongHaul: boolean | undefined;
                if (val?.value === LhShType.LongHaul) isLongHaul = true;
                if (val?.value === LhShType.ShortHaul) isLongHaul = false;
                onChange(isLongHaul);
            }}
            getOptionLabel={(x) => x.label}
            getOptionValue={(x) => x.value}
        />
    );
}

import * as React from 'react';
import { extendObservable, observable, action, runInAction, makeObservable } from 'mobx';
import { Form, FormGroup, Label, Col, Input, Card, CardTitle, CardBody } from 'reactstrap';
import { observer } from 'mobx-react';

import { ApiUrls, DateTime, ParameterType } from '@app/AppConstants';
import { PromiseCompletion } from '@app/Classes/PromiseCompletion';

import { BaseFormModel } from '@app/Services/BaseFormModel';
import { DateTimeService } from '@app/Services/DateTimeService';
import {
    addDateValidator,
    addDateTimeValidator,
    addNumberValidator,
    addRequiredValidator,
    isDateTime,
    isRequiredWhen
} from '@app/Services/Validation';
import ApiService from '@app/Services/ApiService';
import { extensions } from '@app/Services/Extensions';

import { FormCheckbox, FormInput, FormSimpleSelect, FormSwitcher } from '@app/Components/FormControls/FormControls';
import { DatePickerValidatedControl } from '@app/Components/DateTimeControls/DateTimeControls';
import {
    ModalButtonType,
    ModalWindow,
    ModalDialogOptions,
    IModalDialogContent,
    ModalButtonOptions
} from '@app/Components/Modal/Modal';
import {
    JobTriggerShortInfo,
    JobDetailsModel,
    JobSaveParametersModel,
    JobParameterDescriptionModel,
    JobParameterModel,
    CronValidationResult,
    JobMisfireInstruction
} from '../../Models/JobListModels';
import './job-parameters-dialog.scss';

type OnSubmitHandler = (type: ModalButtonType, details: JobSaveParametersModel) => Promise<boolean>;

type JobParametersDialogProps = {
    showTriggerState: boolean;
    jobData: JobDetailsModel;
    trigger?: JobTriggerShortInfo | null;
    onSubmit: OnSubmitHandler;
};

class FormModel extends BaseFormModel {
    @observable
    isActive: boolean = true;

    @observable
    @isRequiredWhen('needUseCron', true)
    cronSchedule: string = '';

    @observable
    @isDateTime()
    @isRequiredWhen('needUseCron', false)
    dateTimeSchedule: string = '';

    @observable
    needUseCron?: boolean;

    @observable
    triggerName: string = '';

    @observable
    useZrhTimeZone: boolean = false;

    @observable
    misfireLogic: JobMisfireInstruction = JobMisfireInstruction.Default;

    [key: string]: unknown;

    constructor(parameters?: JobParameterDescriptionModel[]) {
        super();
        makeObservable(this);
        parameters?.forEach((jobParameter: JobParameterDescriptionModel) => {
            if (!jobParameter.name) return;
            extendObservable(this, {
                [jobParameter.name]: ''
            });
            if (jobParameter.isRequired)
                addRequiredValidator(this, jobParameter.name, '', jobParameter.displayName);
            switch (jobParameter.type) {
                case ParameterType.DateTime:
                    addDateTimeValidator(this, jobParameter.name, '', jobParameter.displayName);
                    break;
                case ParameterType.Date:
                    addDateValidator(this, jobParameter.name, '', jobParameter.displayName);
                    break;
                case ParameterType.Integer:
                    addNumberValidator(this, jobParameter.name, '', jobParameter.displayName);
                    break;
            }
        });
    }
}

@observer
export class JobParametersDialog extends React.Component<JobParametersDialogProps> implements IModalDialogContent<void> {
    private _store: JobParametersDialogStore;
    private _jobNameSpan: React.RefObject<HTMLSpanElement> = React.createRef();
    private _jobNameInput: React.RefObject<HTMLInputElement> = React.createRef();

    constructor(props: JobParametersDialogProps) {
        super(props);
        makeObservable(this);
        this._store = new JobParametersDialogStore(props.showTriggerState, props.jobData, props.onSubmit, props.trigger);
    }

    private _getButtons(): ModalButtonOptions<void>[] {
        const { showTriggerState } = this._store;
        if (!showTriggerState) {
            return [{
                title: 'Run',
                color: 'success',
                type: ModalButtonType.RunNow,
                onClick: async () => {
                    await this._store.submit(ModalButtonType.RunNow);
                }
            }];
        }
        if (!this._store.trigger && showTriggerState) {
            return [{
                title: 'Create',
                color: 'success',
                type: ModalButtonType.CreateNew,
                isDisabled: !this._store.form.triggerName || !!this._store.validityMessage.name,
                onClick: async () => {
                    await this._store.submit(ModalButtonType.CreateNew);
                }
            }];
        }

        return [{
            title: 'Save',
            color: 'success',
            type: ModalButtonType.CreateNew,
            onClick: async () => {
                await this._store.submit(ModalButtonType.Save);
            },
            children: [{
                title: 'Run now',
                type: ModalButtonType.RunNow,
                onClick: async () => {
                    await this._store.submit(ModalButtonType.RunNow);
                }
            }, {
                title: 'Save and run now',
                type: ModalButtonType.SaveAndRunNow,
                onClick: async () => {
                    await this._store.submit(ModalButtonType.SaveAndRunNow);
                }
            }]
        }];
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        this._store.window = window;

        const buttons: ModalButtonOptions<void>[] = [];

        buttons.push({
            type: ModalButtonType.Cancel,
            onClick: () => {
                window.close();
            }
        });
        buttons.push(...this._getButtons());

        if (this._store.trigger) {
            buttons.push({
                type: ModalButtonType.Delete,
                color: 'danger',
                alignLeft: true,
                onClick: async () => {
                    const res = await this._store.submit(ModalButtonType.Delete);
                    if (res) {
                        window.close(ModalButtonType.Delete);
                    }
                }
            });
        }

        return {
            width: 700,
            buttons: buttons,
            loader: this._store.loader
        };
    }

    componentDidMount() {
        const span = this._jobNameSpan.current as Element;
        const input = this._jobNameInput.current;
        if (input) {
            input.style.paddingLeft = span.clientWidth.toString() + 'px';
        }
    }

    render() {
        const { showTriggerState } = this.props;
        return (
            <div className="job-parameters-dialog">
                <Form onSubmit={(e) => e.preventDefault()}>
                    {!this._store.trigger && showTriggerState && (
                        <FormGroup row key="field_triggerName">
                            <Label sm={4}>Name</Label>
                            <Col sm={8}>
                                <span className="job-name" ref={this._jobNameSpan}>
                                    {this._store.jobTriggerPrefix}
                                </span>
                                <FormInput formModel={this._store.form} name="triggerName" innerRef={this._jobNameInput}
                                    invalid={!!this._store.validityMessage.name} onChange={this._onChangeName} />
                                <div className="job-validation-error mt-1">{this._store.validityMessage.name}</div>
                            </Col>
                        </FormGroup>
                    )}
                    {showTriggerState && this._renderMainParameters()}
                    {this._store.parameters?.map(this._controlRenderer)}
                </Form>
                {this._renderTriggerInfo()}
            </div>
        );
    }

    @action.bound
    private _onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
        const newValue = event.target.value;
        this._store.form.triggerName = newValue;

        const triggerName = this._store.jobTriggerPrefix + newValue;

        extensions.executeTimeout(this._checkValidity, 200, this, triggerName);
    }

    private async _checkValidity(triggerName: string) {
        const params = {
            triggerName: triggerName
        };
        const { data: result } = await ApiService.get<boolean>(ApiUrls.CheckTriggerNameUrl, params);
        runInAction(() => {
            if (result === true) {
                this._store.updateValidityMessage('');
            } else {
                this._store.updateValidityMessage('Name is not unique');
            }
        });
    }

    private _controlRenderer = (jobParameter: JobParameterDescriptionModel) => {
        let control: JSX.Element;
        const parameterName = jobParameter.name as keyof FormModel;
        switch (jobParameter.type) {
            case ParameterType.DateTime:
                control = <DatePickerValidatedControl pickerMode="datetime" formModel={this._store.form}
                    name={parameterName} />;
                break;
            case ParameterType.Date:
                control =
                    <DatePickerValidatedControl pickerMode="date" formModel={this._store.form} name={parameterName} />;
                break;
            case ParameterType.Boolean:
                control =
                    <FormCheckbox label={jobParameter.displayName} formModel={this._store.form} name={parameterName}
                        checked={false} />;
                break;
            case ParameterType.MultiLine:
                control = <FormInput formModel={this._store.form} name={parameterName} type="textarea" />;
                break;
            case ParameterType.Enum:
                control = <FormSimpleSelect formModel={this._store.form} name={jobParameter.name}
                    options={jobParameter.enumValues || []} />;
                break;
            default:
                control = <FormInput formModel={this._store.form} name={parameterName}
                    formNamePrefix={this.props.jobData.name} autoComplete="on" />;
                break;
        }
        return (
            <FormGroup row key={'field_' + jobParameter.name}>
                <Label sm={4}>{jobParameter.type === ParameterType.Boolean ? '' : jobParameter.displayName}</Label>
                <Col sm={8}>{control}</Col>
            </FormGroup>
        );
    };

    private _renderTriggerInfo() {
        if (!this._store.trigger) return '';
        const { lastRunDateTime, nextRunDateTime } = this._store.trigger;
        const lastRun = lastRunDateTime && DateTimeService.toUiDateTime(lastRunDateTime) || 'never';
        const nextRun = nextRunDateTime && DateTimeService.toUiDateTime(nextRunDateTime) || 'never';
        return (
            <div className="job-info-details">Last run: {lastRun} | Next run: {nextRun}</div>
        );
    }

    private _renderMainParameters() {
        const { validityMessage, form, isParametersOpen } = this._store;
        return (
            <Card className={'job-main-parameters' + (isParametersOpen ? ' job-main-parameters-open' : '')}>
                <CardBody>
                    <CardTitle onClick={this._store.toggle}>
                        {!isParametersOpen && <i className="fa fa-caret-right" />}
                        {isParametersOpen && <i className="fa fa-caret-down" />}
                        &nbsp;
                        {(isParametersOpen ? 'Hide' : 'Show') + ' Main Parameters'}
                    </CardTitle>
                    {isParametersOpen && (
                        <React.Fragment>
                            <FormGroup row>
                                <Label sm={4}>Active</Label>
                                <Col sm={8}>
                                    <FormSwitcher formModel={form} name="isActive" checked={false} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Use Cron</Label>
                                <Col sm={1}>
                                    <Input checked={form.needUseCron} type="radio" name="radioChooseAlternative"
                                        id="needUseCron_0" onChange={this._onChangeUseCron} />{' '}
                                </Col>
                                <Col sm={7}>
                                    <FormInput
                                        disabled={!form.needUseCron}
                                        formModel={form}
                                        name="cronSchedule"
                                        title={validityMessage.explanation}
                                        changeHandler={this._onChangeCronSchedule}
                                    />
                                    {form.needUseCron && (
                                        <>
                                            {!!validityMessage.errorMessage && <div
                                                className="job-validation-error mt-1">{validityMessage.errorMessage}</div>}
                                            {this.renderUseCronExplanation()}
                                        </>
                                    )}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Start Once</Label>
                                <Col sm={1}>
                                    <Input checked={!this._store.form.needUseCron} type="radio"
                                        name="radioChooseAlternative" id="needUseCron_1"
                                        onChange={this._onChangeUseCron} />{' '}
                                </Col>
                                <Col sm={7}>
                                    <DatePickerValidatedControl disabled={this._store.form.needUseCron}
                                        pickerMode="datetime" formModel={this._store.form}
                                        name="dateTimeSchedule" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>UseZrhTimeZone</Label>
                                <Col sm={8}>
                                    <FormSwitcher formModel={form} name="useZrhTimeZone" checked={false} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>MisfireLogic</Label>
                                <Col sm={8}>
                                    <FormSimpleSelect formModel={this._store.form} name="misfireLogic" hasNoEmptyOption
                                        options={this._store.jobMisfireInstructions} />
                                </Col>
                            </FormGroup>
                        </React.Fragment>
                    )}
                </CardBody>
            </Card>
        );
    }

    private renderUseCronExplanation() {
        return (
            <div style={{ fontSize: 12, color: '#999' }}>
                <div className="d-flex justify-content-between mt-2 mb-2">
                    <table>
                        <tbody>
                            <tr>
                                <th>second</th>
                                <td className="pl-4">0 - 59</td>
                            </tr>
                            <tr>
                                <th>minute</th>
                                <td className="pl-4">0 - 59</td>
                            </tr>
                            <tr>
                                <th>hour</th>
                                <td className="pl-4">0 - 23</td>
                            </tr>
                            <tr>
                                <th>day (month)</th>
                                <td className="pl-4">1 - 31</td>
                            </tr>
                            <tr>
                                <th>month</th>
                                <td className="pl-4">1 - 12, JAN-DEC</td>
                            </tr>
                            <tr>
                                <th>day (week)</th>
                                <td className="pl-4">0 - 6, SUN-SAT</td>
                            </tr>
                        </tbody>
                    </table>
                    <table style={{ height: 'min-content' }}>
                        <tbody>
                            <tr>
                                <th>*</th>
                                <td className="pl-3">any value</td>
                            </tr>
                            <tr>
                                <th>,</th>
                                <td className="pl-3">value list separator</td>
                            </tr>
                            <tr>
                                <th>-</th>
                                <td className="pl-3">range of values</td>
                            </tr>
                            <tr>
                                <th>/</th>
                                <td className="pl-3">step values</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <span>E.g:<b> "0 30 10-13 * * WED,FRI"</b> - trigger fires every half hour (at 10:30, 11:30, 12:30, and 13:30), on every Wednesday and Friday.</span>
            </div>
        );
    }

    @action.bound
    private _onChangeUseCron(event: React.ChangeEvent<HTMLInputElement>) {
        const id = (event.target as HTMLInputElement).id;
        const idParts = id.split('_');
        if (idParts.length !== 2) {
            throw new Error('Invalid element id=' + id);
        }
        const radioNumber = +id.split('_')[1];
        this._store.form.needUseCron = !radioNumber;
    }

    @action.bound
    private _onChangeCronSchedule(event: React.ChangeEvent<HTMLInputElement>) {
        const newValue = event.target.value;
        this._store.form.cronSchedule = newValue;

        extensions.executeTimeout(this._checkCronScheduleValidity, 100, this, newValue);
    }

    @action
    private async _checkCronScheduleValidity(cronExpression: string) {
        if (!cronExpression) return;

        const url = ApiUrls.JobCronValidation + `/${encodeURI(cronExpression).replace(/\?/g, '%3F')}`;
        const { data } = await ApiService.postTypedData<CronValidationResult>(url);

        this._store.validityMessage.errorMessage = data.validationErrorMessage ?? '';
        this._store.validityMessage.explanation = data.expressionSummary ?? '';
    }
}

class JobParametersDialogStore {
    @observable isParametersOpen: boolean = false;
    @observable validityMessage: { name: string, errorMessage: string, explanation: string } = {
        name: '',
        errorMessage: '',
        explanation: ''
    };
    jobTriggerPrefix: string = '';
    data: JobDetailsModel;
    parameters?: JobParameterDescriptionModel[];
    form: FormModel;
    trigger: JobTriggerShortInfo | null;
    window: ModalWindow<void> | null = null;
    loader: PromiseCompletion = new PromiseCompletion();
    showTriggerState: boolean;
    _onSubmit: OnSubmitHandler;
    jobMisfireInstructions: string[] = [JobMisfireInstruction.Default, JobMisfireInstruction.DoNothing, JobMisfireInstruction.FireAndProceed, JobMisfireInstruction.IgnoreMisfires];

    constructor(showTriggerState: boolean, jobData: JobDetailsModel, onSubmit: OnSubmitHandler, trigger?: JobTriggerShortInfo | null) {
        makeObservable(this);
        this.data = jobData;
        this.jobTriggerPrefix = this.data.name + '_';
        this.parameters = jobData.parameters;
        this.form = new FormModel(this.parameters);
        this.trigger = trigger || null;
        this.showTriggerState = showTriggerState;
        this._onSubmit = onSubmit;
        this._initFormValues();
        this._initMainParameters();
    }

    @action
    public updateValidityMessage = (message: string = '') => {
        this.validityMessage.name = message;
    };

    @action
    public async submit(type: ModalButtonType) {
        if (!this.form.validate() && type !== ModalButtonType.Delete) {
            return;
        }

        const jobParams: Array<JobParameterModel> = [];
        let newTriggerName: string = '';
        this.parameters?.forEach((jobParameter: JobParameterDescriptionModel) => {
            const name = jobParameter.name;
            let value = this.form[name] as string;
            switch (jobParameter.type) {
                case ParameterType.DateTime: {
                    value = value = value && DateTimeService.format(DateTimeService.parseUiDateTime(value), DateTime.jsonFullFormat);
                    break;
                }
                case ParameterType.Date: {
                    value = value && DateTimeService.format(DateTimeService.parseUiDate(value), DateTime.jsonDateFormat);
                    break;
                }
            }
            if (value)
                jobParams.push({ name: name || '', value });
        });

        const dateTimeSchedule = this.form.dateTimeSchedule && DateTimeService.parseUiDateTime(this.form.dateTimeSchedule) || null;
        if (type === ModalButtonType.RunNow || type === ModalButtonType.SaveAndRunNow) {
            newTriggerName = (this.trigger && this.trigger.name) || this.data.name || '';
            jobParams.push({ name: 'NewTriggerName', value: newTriggerName });
        }
        const jobDetails: JobSaveParametersModel = {
            parameters: jobParams,
            triggerName: (this.trigger && this.trigger.name) || this.jobTriggerPrefix + this.form.triggerName,
            jobName: this.data.name ?? '',
            isActive: this.form.isActive,
            cronSchedule: this.form.needUseCron ? this.form.cronSchedule : '',
            dateTimeSchedule: (!this.form.needUseCron && dateTimeSchedule) || undefined,
            useZrhTimeZone: this.form.useZrhTimeZone,
            misfireLogic: this.form.misfireLogic
        };

        const result = await this.loader.add(async () => {
            return await this._onSubmit(type, jobDetails);
        });

        if (result) {
            this.window?.close();
        }
        return result;
    }

    private _initFormValues() {
        this.parameters?.map((jobParameter: JobParameterDescriptionModel) => {
            if (jobParameter.value) {
                const parameterValues = JSON.parse(jobParameter.value);
                for (let prop in parameterValues) {
                    const date = DateTimeService.parse(parameterValues[prop], DateTime.jsonDateFormat);
                    if (!isNaN(date.getTime())) {
                        parameterValues[prop] = DateTimeService.toUiDate(date);
                    }
                    this.form[prop] = parameterValues[prop];
                }
            }
        });
    }

    private _initMainParameters() {
        let date = '';
        if (this.trigger) {
            date = this.trigger.dateTimeSchedule ? DateTimeService.toUiDateTime(this.trigger.dateTimeSchedule) : '';
            this.form.isActive = this.trigger.isActive;
            this.form.useZrhTimeZone = this.trigger.useZrhTimeZone;
            this.form.misfireLogic = this.trigger.misfireLogic;
            this.form.cronSchedule = this.trigger.cronSchedule || '';
        } else {
            date = this.data.dateTimeSchedule ? DateTimeService.toUiDateTime(this.data.dateTimeSchedule) : '';
            this.form.cronSchedule = this.data.cronSchedule || '';
        }
        if (this.showTriggerState) {
            this.form.needUseCron = !!this.form.cronSchedule;
        }
        this.form.dateTimeSchedule = this.form.needUseCron ? '' : date;

        if (!this.trigger && this.showTriggerState) {
            addRequiredValidator(this.form, 'triggerName', '', 'Name of trigger');
        }
    }

    @action.bound
    public toggle() {
        this.isParametersOpen = !this.isParametersOpen;
    }
}

type MapFunction = (match: string, number: number, index: number) => JSX.Element | null;

export default class ReactService {
    /**
     * Given a string, replace every substring that is matched by the `match` regex
     * with the result of calling `fn` on matched substring. The result will be an
     * array with all odd indexed elements containing the replacements. The primary
     * use case is similar to using String.prototype.replace except for React.
     *
     * React will happily render an array as children of a react element, which
     * makes this approach very useful for tasks like surrounding certain text
     * within a string with react elements.
     *
     * Example:
     * matchReplace(
     *   'Emphasize all phone numbers like 884-555-4443.',
     *   /([\d|-]+)/g,
     *   (number, i) => <strong key={i}>{number}</strong>
     * );
     * // => ['Emphasize all phone numbers like ', <strong>884-555-4443</strong>, '.'
     *
     * @param {string} str
     * @param {regexp|str} match Must contain a matching group
     * @param {function} fn
     * @return {array}
     */
    public static replaceString(str: string, match: RegExp, fn: MapFunction): (string | JSX.Element | null)[] {
        let curCharStart = 0;
        let curCharLen = 0;

        if (!str) {
            return [];
        }

        const srtSplit = str.split(match);

        const result: (string | JSX.Element | null)[] = [];
        // Apply fn to all odd elements
        let i = 0;
        // eslint-disable-next-line no-global-assign
        for (length = srtSplit.length; i < length; i++) {
            if (i % 2 === 0) {
                result.push(srtSplit[i]);
                continue;
            }
            curCharLen = srtSplit[i].length;
            curCharStart += srtSplit[i - 1].length;

            result.push(fn(srtSplit[i], i, curCharStart));
            curCharStart += curCharLen;
        }

        return result;
    }
}

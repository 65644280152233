import { DownloadService } from './DownloadService';

type Class<T = unknown> = new (...args: unknown[]) => T;

class ServiceClass {
    private _servicesRegistrations: Set<Class> = new Set();
    private _servicesInstances: Map<Class, unknown> = new Map();

    constructor () {
        this._servicesRegistrations.add(DownloadService);
    }

    public resolve<T> (type: Class<T>): T {
        if (!this._servicesRegistrations.has(type)) {
            throw new Error(`Type ${type} is not registered`);
        }

        let existingInstance = this._servicesInstances.get(type);
        if (existingInstance) {
            return existingInstance as T;
        }

        const newInstance = new type() as T;
        this._servicesInstances.set(type, newInstance);

        return newInstance;
    }
}

export const service = new ServiceClass();
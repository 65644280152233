import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, action, computed, makeObservable } from 'mobx';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { DropdownItem, DropdownToggle, DropdownMenu, Button, ButtonDropdown } from 'reactstrap';

import { Timer } from '@services/Timer';
import ApiService from '@app/Services/ApiService';
import { PromiseCompletion, CompletionType } from '@app/Classes/PromiseCompletion';

import { Icon } from '@components/Icon';
import { ToolTipItem } from '@components/ToolTipItem';
import { modalService } from '@components/Modal/Modal';
import { SelfTestBadge } from '../SelfTestBadge/SelfTestBadge';
import './self-test-status.scss';

type SelfTestStatusProps = {
    url: string;
    fixUrl?: string;
    icon: IconName;
    fixName?: string;
    autorefresh?: boolean;
    onClick?: () => {};
}

type SelfTestResult = {
    name: string;
    status: string;
    title: string;
    message: string;
}

@observer
export class SelfTestStatus extends React.Component<SelfTestStatusProps> {
    readonly _store: SelfTestStatusStore;

    constructor (props: SelfTestStatusProps) {
        super(props);
        makeObservable(this);
        const { url, fixUrl, autorefresh } = props;
        this._store = new SelfTestStatusStore(url, fixUrl, autorefresh);
    }

    componentWillUnmount () {
        this._store.stop();
    }

    renderIcon (id: string) {
        return (
            <div className="icon-container">
                <Icon name={this._store.fixing.isPending ? 'sync' : this.props.icon}
                      className={this._store.fixing.isPending ? 'fa-spin' : ''}/>
                {this._store.data?.title &&
                    <ToolTipItem text={this._store.data.title} className="tooltip-wide" targetId={id}/>}
                {this._store.data?.message && (
                    <span className="job-queue-self-test-status" title="">
                        {this._store.data.message}
                    </span>
                )}
            </div>
        );
    }

    render () {
        const { fixUrl } = this.props;
        const isDropdown = fixUrl && !this._store.fixing.isPending;

        return <SelfTestBadge>{isDropdown ? this._renderDropdown() : this._renderButton()}</SelfTestBadge>;
    }

    private _renderDropdown () {
        const { url, fixName } = this.props;
        const { color, isDropdownOpen, toggleDropdown } = this._store;
        const id = url.replace(/\//g, '-') + '-tooltip';
        return (
            <ButtonDropdown isOpen={isDropdownOpen} toggle={toggleDropdown} size="sm">
                <DropdownToggle id={id} caret color={color} disabled={!this._store.dataLoading.isCompleted}>
                    {this.renderIcon(id)}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={this._handleDropdownClick}>{fixName || 'Run fix'}</DropdownItem>
                </DropdownMenu>
            </ButtonDropdown>
        );
    }

    private _renderButton () {
        const id = this.props.url.replace(/\//g, '-') + '-tooltip';
        const { color } = this._store;

        return (
            <Button id={id} size="sm" className="self-test-btn badge-btn" color={color}
                    disabled={!this._store.dataLoading.isCompleted} onClick={this.props.onClick}>
                {this.renderIcon(id)}
            </Button>
        );
    }

    private _handleDropdownClick = async () => {
        const { fixName } = this.props;
        const confirmed = await modalService.showConfirmation(`Do you really want to execute '${fixName}'?`);

        if (confirmed) {
            void this._store.runFixAction();
        }
    };
}

class SelfTestStatusStore {
    @observable public data: SelfTestResult;
    @observable public isDropdownOpen: boolean = false;
    @observable public fixing: PromiseCompletion = new PromiseCompletion();
    @observable public dataLoading: PromiseCompletion = new PromiseCompletion(CompletionType.Completed);

    readonly _url: string;
    readonly _fixUrl?: string;
    readonly _timer: Timer = new Timer();

    constructor (url: string, fixUrl?: string, autorefresh?: boolean) {
        makeObservable(this);
        this._url = url;
        this._fixUrl = fixUrl;

        if (autorefresh) {
            this._timer.initRequestInterval(this.loadData);
        } else {
            void this.loadData();
        }
    }

    @computed
    get color (): string {
        if (!this.dataLoading.isCompleted) return 'secondary';

        if (!this.data) return 'danger';

        switch (this.data.status) {
            case 'Success':
                return 'success';
            case 'Warning':
                return 'warning';
            default:
                return 'danger';
        }
    }

    @action
    public stop () {
        if (this._timer) this._timer.stop();
    }

    @action.bound
    public toggleDropdown () {
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    @action
    public async runFixAction () {
        if (!this._fixUrl) return;

        const fixRequest = ApiService.postData(this._fixUrl, null);
        this.fixing.subscribe(fixRequest);

        await fixRequest;
        await this.loadData();
    }

    @action.bound
    public async loadData () {
        const dataRequest = ApiService.postTypedData<SelfTestResult>(this._url, null);
        this.dataLoading.subscribe(dataRequest);

        this.data = (await dataRequest).data;
    }
}

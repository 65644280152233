import { BaseComponent } from './BaseComponent/BaseComponent';
import { Modals } from './Modal/Modal';
import { NotificationContainer } from './ToastNotification';

export const BaseLayout = () => {
    return (
        <>
            <BaseComponent/>
            <Modals/>
            <NotificationContainer/>
        </>
    );
};
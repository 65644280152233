
export type OptionsModel = {
    label: string;
    value: string;
};

export type JobProgressMaxValue = {
    triggerId: string;
    maxValue: number;
};

export type TriggerExtended = JobTriggerShortInfo & {
    isManual?: boolean
};

export type JobProgressValue = {
    triggerId: string;
    jobName: string;
    value: number;
    maxValue: number;
};


export enum JobStatus {
    Fail = 'Fail',
    Pending = 'Pending',
    Success = 'Success',
}

export enum SchedulerStatus {
    FailedToStart = 'FailedToStart',
    SchedulingJobs = 'SchedulingJobs',
    SchedulingPostponedJobs = 'SchedulingPostponedJobs',
    ServiceNotStarted = 'ServiceNotStarted',
    ShuttingDown = 'ShuttingDown',
    Started = 'Started',
    Starting = 'Starting',
    StutDown = 'StutDown',
    WaitingForDatabase = 'WaitingForDatabase',
}

export type JobStatusMessage = {
    name: string;
    triggerId: string;
    status?: JobStatus;
    lastErrorMessage: string;
    progressMessage?: string;
    jobExecutionInfo?: JobExecutionInfo;
    timestampDateTimeUtc: Date;
};

export type JobExecutionInfo = {
    lastRunDateTimeUtc?: Date;
    nextRunDateTimeUtc?: Date;
    lastRunTriggerName: string | null;
    nextRunTriggerName: string | null;
    lastInfoMessage: string | null;
};

export type ApiV1JobList = {
    jobs?: ApiV1Job[];
    status: SchedulerStatus;
    startupException?: string;
};

export type ApiV1Job = {
    id?: string;
    name: string;
    description?: string;
    jobType?: string;
    lastRunDateTimeUtc?: Date;
    nextRunDateTimeUtc?: Date;
    lastRunTriggerName?: string;
    nextRunTriggerName?: string;
    lastErrorMessage?: string;
    lastStatus?: JobStatus;
    timestampDateTimeUtc: Date;
    triggers: JobTriggerShortInfo[];
    lastExecutionInfoMessage?: string;
};

export type JobTriggerShortInfo = {
    name?: string;
    isActive: boolean;
    cronSchedule?: string;
    nextRunDateTime?: Date;
    lastRunDateTime?: Date;
    status?: JobStatus;
    progressMax?: number;
    progressCurrent?: number;
    currentMessage?: string;
    dateTimeSchedule?: Date;
    useZrhTimeZone: boolean;
    misfireLogic: JobMisfireInstruction;
};

export type ApiV1JobTrigger = {
    executionId?: string;
    id: string;
    nextRunDateTime?: Date;
    lastRunDateTime?: Date;
    lastErrorMessage?: string;
    status?: JobStatus;
    isActive: boolean;
    cronSchedule?: string;
    progressMessage?: string;
};

export type JobStartModel = {
    parameters?: JobParameterModel[];
};

export type JobParameterModel = {
    name: string;
    value?: string;
};

export type JobDetailsModel = {
    name?: string;
    isActive: boolean;
    cronSchedule?: string;
    dateTimeSchedule?: Date;
    parameters?: JobParameterDescriptionModel[];
};

export type JobParameterDescriptionModel = {
    name: string;
    value?: string;
    displayName?: string;
    type: JobParameterType;
    isRequired: boolean;
    enumValues?: OptionsModel[];
};

export enum JobParameterType {
    Boolean = 'Boolean',
    Date = 'Date',
    DateTime = 'DateTime',
    Enum = 'Enum',
    Integer = 'Integer',
    Multiline = 'Multiline',
    String = 'String',
}

export enum JobMisfireInstruction {
    Default = 'Default',
    DoNothing = 'DoNothing',
    FireAndProceed = 'FireAndProceed',
    IgnoreMisfires = 'IgnoreMisfires'
}

export type JobSaveParametersModel = {
    jobName: string;
    triggerName: string;
    isActive: boolean;
    cronSchedule?: string;
    dateTimeSchedule?: Date;
    parameters?: JobParameterModel[];
    useZrhTimeZone: boolean;
    misfireLogic: JobMisfireInstruction;
};

export type JobSaveParametersResult = {
    errors?: string[];
    hasErrors: boolean;
};

export type CronValidationResult = {
    isValid: boolean;
    validationErrorMessage?: string;
    expressionSummary?: string;
    nextInvalidTime?: Date;
};
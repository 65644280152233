import { action, computed } from 'mobx';

export class Timer {
    private _timer: number | null;
    private _interval: number | null = null;
    private readonly _duration: number = 5000;

    constructor (duration?: number) {
        if (duration) {
            this._duration = duration;
        }
    }

    @action
    public stop () {
        if (this._timer) {
            window.clearTimeout(this._timer);
            this._timer = null;
        }
        if (this._interval) {
            window.clearInterval(this._interval);
            this._interval = null;
        }
    }

    @action
    public initInterval (initFunction: Function, timeOut?: number) {
        this._timer = window.setTimeout(() => {
            initFunction();
            this._interval = window.setInterval(() => {
                initFunction();
            }, this._duration);
        }, timeOut || 0);
    }

    @action
    public initRequestInterval (request: () => Promise<void>, timeOut?: number) {
        this._timer = window.setTimeout(async () => {
            try {
                await request();
            } finally {
                if (this._timer) {
                    this.initRequestInterval(request, this._duration);
                }
            }
        }, timeOut || 0);
    }

    @action initTimeout (initFunction: Function, timeout: number) {
        this.stop();
        this._timer = window.setTimeout(() => {
            initFunction();
        }, timeout);
    }

    @computed
    public get hasActualTimer () {
        return this._timer !== null;
    }
}

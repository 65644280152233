import { forwardRef, Ref } from 'react';
import { ServerSelect } from './ServerSelect';
import { OnChangeValue } from 'react-select';

type DepartmentPickerProps<IsMulti extends boolean> = {
    url: string;
    value?: string | string[];
    onChange?: (value?: OnChangeValue<string, IsMulti> | null) => void;
    isDisabled?: boolean;
    multi?: IsMulti;
};

function DepartmentPickerInternal<IsMulti extends boolean>(props: DepartmentPickerProps<IsMulti>, ref?: React.ForwardedRef<ServerSelect<string, IsMulti>>) {
    return <ServerSelect<string, IsMulti> ref={ref} {...props} getOptionLabel={(x) => x} getOptionValue={(x) => x} />;
}

export const DepartmentPicker = forwardRef(DepartmentPickerInternal) as <IsMulti extends boolean>(
    props: DepartmentPickerProps<IsMulti> & {
        ref?: React.ForwardedRef<ServerSelect<string, IsMulti>>;
    }
) => ReturnType<typeof DepartmentPickerInternal>;

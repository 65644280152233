
import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';

import { extensions } from '@services/Extensions';
import { DateTimeService } from '@services/DateTimeService';
import { FlightIdInfo } from '@app/Models/FlightScheduleModels';
import { ApiUrls, KeyboardCodes } from '@app/AppConstants';
import ApiService from '@app/Services/ApiService';

type DutyFilterHandler = (arg: string) => void;
type FlightResolveHandler = (id: string, flight: FlightIdInfo) => void;

type FlightDutyFilterProps = {
    className: string;
    value: string;
    onChange: DutyFilterHandler;
    onFlightFound: FlightResolveHandler;
};

@observer
export default class FlightDutyFilter extends React.Component<FlightDutyFilterProps, {}> {
    private _store: DutyFilterStore = new DutyFilterStore();

    constructor(props: FlightDutyFilterProps) {
        super(props);
        makeObservable(this);
        this._store.updateDefault(this.props.value, this.props.onChange, this.props.onFlightFound);
    }

    componentDidUpdate(prevProps: FlightDutyFilterProps) {
        if (prevProps.value !== this.props.value || prevProps.onChange !== this.props.onChange || prevProps.onFlightFound !== this.props.onFlightFound) {
            this._store.updateDefault(this.props.value, this.props.onChange, this.props.onFlightFound);
        }
    }

    render() {
        const { className } = this.props;
        const { inputValue, flightDetails } = this._store;

        return (
            <div className="flight-search">
                {flightDetails && (
                    <div className="bs-tooltip-top tooltip show" x-placement="top" data-placement="top">
                        <div className="tooltip-inner">{this._getFlightDetails()}</div>
                        <span className="arrow" />
                    </div>
                )}
                <input className={className} type="text"
                    id="flight_list_filter"
                    value={inputValue}
                    onKeyDown={this.onInputKeyDown}
                    onBlur={this._onInputBlur}
                    onChange={this._onInputChange}
                    onClick={this._onInputClick}
                    placeholder="🔎︎" />
                {!!inputValue && <button className="close-icon" onClick={() => this._store.applyFilter('')} />}
            </div>
        );
    }

    private _getFlightDetails() {
        const f = this._store.flightDetails;
        if (f) {
            return f.airlineRcd + '-' + f.flightNumber + ' ' + f.originRcd + '-' + f.destinationRcd + ' (' + f.legNumber + ') - ' + DateTimeService.toUiDate(f.date);
        }
        return '';
    }

    @action.bound
    private _onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        this._store.inputValue = event.target.value;
    }

    @action.bound
    private _onChangeTimeout() {
        this._store.applyFilter(this._store.inputValue);
    }

    @action.bound
    private onInputKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        let target = event.target as HTMLInputElement;
        if (target && event.code === KeyboardCodes.ENTER) {
            this._store.applyFilter(target.value, true);
        } else if (target && event.code === KeyboardCodes.ESCAPE) {
            this._store.applyFilter('');
        } else if (target && event.code === KeyboardCodes.BACKSPACE && target.value) {
            const selection = (target.selectionEnd || 0) - (target.selectionStart || 0);
            if (target.value.length === selection) {
                this._store.applyFilter('');
            }
        }

        extensions.executeTimeout(this._onChangeTimeout, 500);
    }

    @action.bound
    private _onInputClick(event: React.MouseEvent<HTMLInputElement>) {
        (event.target as HTMLInputElement).select();
    }

    @action.bound
    private _onInputBlur(event: React.FocusEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement;
        if (!target) return;
        this._store.applyFilter(target.value);
    }
}

class DutyFilterStore {
    @observable inputValue: string = '';
    @observable lastAppliedFilterValue: string = '';
    @observable flightDetails: FlightIdInfo | null;
    onChange: DutyFilterHandler;
    onFlightFound: FlightResolveHandler;

    constructor() {
        makeObservable(this);
    }

    private _isGuid(uuid: string) {
        let s = '' + uuid;

        if (s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$') === null) {
            return false;
        }
        return true;
    }

    @action
    public setFlightDetails(info: FlightIdInfo) {
        this.flightDetails = info;
    }

    @action
    public updateDefault(newValue: string, onChange: DutyFilterHandler, onFlightFound: FlightResolveHandler) {
        this.lastAppliedFilterValue = newValue || '';
        this.inputValue = this.lastAppliedFilterValue;
        this.onChange = onChange;
        this.onFlightFound = onFlightFound;
    }

    @action
    public async applyFilter(value: string, force?: boolean) {
        if (this.lastAppliedFilterValue !== value || force) {
            this.lastAppliedFilterValue = value;
            this.inputValue = this.lastAppliedFilterValue;
            this.onChange(value);

            this.flightDetails = null;
            if (this._isGuid(value)) {
                const { data } = await ApiService.get<FlightIdInfo>(ApiUrls.FlightDecodeUrl + '?flightId=' + value);
                this.setFlightDetails(data);
                this.onFlightFound(value, data);
            }
        }
    }
}
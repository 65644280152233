import { Aircraft } from '@app/AppConstants';
import { ReactSelect } from './ReactSelect/ReactSelect';
import { OnChangeValue } from 'react-select';

type AircraftTypePickerProps<IsMulti extends boolean> = {
    value?: string | string[];
    onChange?: (value?: OnChangeValue<string, IsMulti> | null) => void;
    isDisabled?: boolean;
    multi?: IsMulti;
};

export function AircraftTypePicker<IsMulti extends boolean>(props: AircraftTypePickerProps<IsMulti>) {
    const { value, isDisabled, multi, onChange } = props;

    return <ReactSelect<string, IsMulti> value={value} options={Aircraft.Types} placeholder="" isSearchable isClearable isDisabled={isDisabled} multi={multi} onSelect={onChange} getOptionLabel={(x) => x} getOptionValue={(x) => x} />;
}

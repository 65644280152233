import { ApiUrls } from '@app/AppConstants';
import PromiseQueue from '@app/Classes/PromiseQueue';
import { ConfigurationModel } from '@app/Models/WebApiModels';
import ApiService from '@app/Services/ApiService';
import { extensions } from '@app/Services/Extensions';

type ConfigurationEntryKey = {
    group: string;
    key: string;
};

type ConfigurationEntryPending = ConfigurationEntryKey & {
    resolve: (value: string | null) => void;
    reject: (error: Error) => void;
    isLoading: boolean;
};

type ConfigurationEntry = ConfigurationEntryKey & {
    value: string | null;
};

class ConfigurationStore {
    private _pendingLoadConfigurations: ConfigurationEntryPending[] = [];
    private _localConfigurations: ConfigurationEntry[] = [];
    private _saveQueue: PromiseQueue<ConfigurationModel>;

    constructor() {
        this._saveQueue = new PromiseQueue<ConfigurationModel>();
    }

    public get(group: string, key: string): Promise<string | null> {
        return new Promise<string | null>((resolve, reject) => {
            const localConfigValue = this._localConfigurations.find(x => x.group === group && x.key === key);
            if (localConfigValue) {
                resolve(localConfigValue.value);
                return;
            }

            this._pendingLoadConfigurations.push({
                key: key,
                group: group,
                resolve: resolve,
                reject: reject,
                isLoading: !!this._pendingLoadConfigurations.find(x => x.group === group && x.key === key)
            });
            extensions.executeTimeout(this._loadPendingConfiguration, 1, this);
        });
    }

    public set(group: string, key: string, value: string | null) {
        this._saveQueue.add({
            key: key,
            group: group,
            value: value === null ? void 0 : value
        }, this._saveConfigurationInternal);

        const localConfigValue = this._localConfigurations.find(x => x.group === group && x.key === key);
        if (localConfigValue) {
            localConfigValue.value = value;
        } else {
            this._localConfigurations.push({
                group: group,
                key: key,
                value: value
            });
        }
    }

    private async _loadPendingConfiguration() {
        const configurationsToLoad = this._pendingLoadConfigurations.filter(x => !x.isLoading);
        configurationsToLoad.forEach(k => k.isLoading = true);
        const loadKeys = configurationsToLoad.map(k => k.group + '.' + k.key);
        const distinctKeys = [...new Set(loadKeys)];
        const params = {
            keys: distinctKeys.join(',')
        };

        try {
            const result = (await ApiService.get<ConfigurationModel[]>(ApiUrls.ConfigurationUrl, params)).data;
            result.forEach(r => {
                this._localConfigurations.push({
                    group: r.group!,
                    key: r.key!,
                    value: r.value || null
                });
            });
            configurationsToLoad.forEach(c => {
                const r = result.find(r => r.group === c.group && r.key === c.key);
                this._notifyPendingHandler(c.group, c.key, h => h.resolve(r && r.value || null));
            });

        } catch (er) {
            const error = new Error((er as object).toString());
            configurationsToLoad.forEach(c => {
                this._notifyPendingHandler(c.group, c.key, h => h.reject(error));
            });
        }
    }

    private _notifyPendingHandler(group: string, key: string, callback: (h: ConfigurationEntryPending) => void) {
        for (let i = this._pendingLoadConfigurations.length - 1; i >= 0; i--) {
            const handler = this._pendingLoadConfigurations[i];
            if (!(handler.group === group && handler.key === key)) continue;
            callback(handler);
            this._pendingLoadConfigurations.splice(i, 1);
        }
    }

    private async _saveConfigurationInternal(configuration: ConfigurationModel) {
        await ApiService.putData(ApiUrls.ConfigurationUrl, configuration);
    }
}

export const configurationStore = new ConfigurationStore();
import { useState } from 'react';
import { observer } from 'mobx-react';

import { FilterState } from '@classes/FilterState';
import { VirtualizedToiList } from '@components/VirtualizedToiList/VirtualizedToiList';
import { FilterSection } from './Components/FilterSection';
import './_home-page.scss';

export const HomePage = observer(() => {
    const [filterState, setFilterState] = useState<FilterState>(new FilterState());

    const changeFilters = (newState: FilterState) => {
        setFilterState(newState);
    };

    return (
        <div className="home-page">
            <FilterSection filters={filterState} onChangeFilters={changeFilters} />
            <div className="home-page-content">
                <VirtualizedToiList filters={filterState} />
            </div>
        </div>
    );
});

import * as React from 'react';
import { DropdownItem, DropdownToggle, DropdownMenu, ButtonDropdown } from 'reactstrap';

import { Icon } from '@components/Icon';
import { ApiUrls } from '@app/AppConstants';
import { getEncodedToken } from '@helpers/JWTHelper';
import { appStore } from '@app/AppStore';
import { modalService } from '@components/Modal/Modal';
import { DownloadDayLogDialog } from '../Dialogs/DownloadDayLogDialog';
import { DownloadServiceMessagesDialog } from '../Dialogs/DownloadServiceMessagesDialog';
import { SelfTestBadge } from '../SelfTestBadge/SelfTestBadge';
import { Range } from '@helpers/RangeHelper';

interface IDownloadLogsDropdown {
    dropdownOpen: boolean;
}

type SelfTestLogsProps = {
    range: Range | null
}

export class SelfTestLogs extends React.PureComponent<SelfTestLogsProps, IDownloadLogsDropdown> {

    constructor (props: SelfTestLogsProps) {
        super(props);
        this.toggleDownloadLogsDropdown = this.toggleDownloadLogsDropdown.bind(this);

        this.state = {
            dropdownOpen: false
        };
    }

    toggleDownloadLogsDropdown () {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render () {
        return (
            <SelfTestBadge>
                <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDownloadLogsDropdown} size="sm">
                    <DropdownToggle caret color="success">
                        <Icon name="download"/>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={this._downloadFullLogs}>Download full logs</DropdownItem>
                        <DropdownItem onClick={this._downloadDayLogs}>Download day logs</DropdownItem>
                        <DropdownItem onClick={this._downloadServiceMessages}>Download service messages</DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
            </SelfTestBadge>
        );
    }

    private _downloadFullLogs () {
        const encodedToken = getEncodedToken(appStore.currentToken);
        window.location.href = ApiUrls.ServiceDownloadFullLogsUrl + '?token=' + encodeURIComponent(encodedToken);
    }

    private async _downloadDayLogs () {
        await modalService.show(DownloadDayLogDialog);
    }

    private _downloadServiceMessages = async () => {
        await modalService.show(DownloadServiceMessagesDialog, { range: this.props.range });
    };
}

import * as React from 'react';
import { Badge, BadgeProps } from 'reactstrap';
import './status.scss';

type StatusProps = {
    id?: string;
    pill?: boolean;
    clsPrefix?: string;
} & BadgeProps;

export default class Status extends React.PureComponent<StatusProps, {}> {
    public render() {
        const { id, color, pill, clsPrefix, onClick } = this.props;
        const cls = clsPrefix ? clsPrefix + ' status' : 'status';

        return (
            <Badge id={id} color={color} className={cls} pill={pill} onClick={onClick}>
                {this.props.children}
            </Badge>
        );
    }
}

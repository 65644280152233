import { forwardRef, Ref } from 'react';
import { CountryItem } from '@app/Models/WebApiModels';
import { ServerSelect } from './ServerSelect';
import { OnChangeValue } from 'react-select';

type CountryPickerProps<IsMulti extends boolean> = {
    url: string;
    value?: CountryItem | CountryItem[];
    onChange?: (value?: OnChangeValue<CountryItem, IsMulti> | null) => void;
    isDisabled?: boolean;
    multi?: IsMulti;
};

function CountryPickerInternal<IsMulti extends boolean>(props: CountryPickerProps<IsMulti>, ref?: React.ForwardedRef<ServerSelect<CountryItem, IsMulti>>) {
    return <ServerSelect<CountryItem, IsMulti> {...props} ref={ref} keyField="threeLetterCode" getOptionLabel={(x) => `${x.threeLetterCode} (${x.twoLetterCode}/${x.name})`} />;
}

export const CountryPicker = forwardRef(CountryPickerInternal) as <IsMulti extends boolean>(
    props: CountryPickerProps<IsMulti> & {
        ref?: React.ForwardedRef<ServerSelect<CountryItem, IsMulti>>;
    }
) => ReturnType<typeof CountryPickerInternal>;

import { AircraftDto, AircraftTypeDto, AirportModel, Application, CategoryDto, CountryItem, PrincipalModel, RegionDto } from '@app/Models/WebApiModels';
import { BaseFormModel } from '@app/Services/BaseFormModel';
import { makeObservable, observable } from 'mobx';

export class FilterState extends BaseFormModel {
    @observable search?: string = '';
    @observable clearedSearch?: string;
    @observable dateFrom: string = '';
    @observable dateTo: string = '';
    @observable countries?: CountryItem[];
    @observable categories?: CategoryDto[];
    @observable regions?: RegionDto[];
    @observable airports?: AirportModel[];
    @observable aircrafts?: AircraftDto[];
    @observable aircraftTypes?: string[];
    @observable isActive: boolean = true;
    @observable forLxFlight: boolean = false;

    @observable flightNumRcd?: string = '';
    @observable airlineRcd?: string;
    @observable flightNumber?: number;

    @observable flightIds?: string[];
    @observable flights?: string[];
    @observable flightsDepartureDate?: Date;
    @observable departments?: string[];
    @observable person?: PrincipalModel;
    @observable applicableFor?: string[];

    @observable uncoveredTerms: string[] = [];

    constructor(filters?: Partial<FilterState>) {
        super();
        makeObservable(this);
        if (filters) {
            Object.assign(this, filters);
        }
    }
}
import React from 'react';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import ContextSubMenu from './ContextSubMenu';

type ContextMenuItemProps = {
    title: string | JSX.Element;
    disabled?: boolean;
    className?: string;
    children?: React.ReactNode;
    childrenClassName?: string;
    onShow?: () => void;
    onHide?: () => void;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

@observer
export class ContextMenuItem extends React.Component<ContextMenuItemProps, {}> {
    private _timer: number | null = null;
    private _delay: number = 300;
    @observable isHovered: boolean = false;

    constructor(props: ContextMenuItemProps) {
        super(props);
        makeObservable(this);
    }

    render() {
        const { children, title, disabled } = this.props;
        const hasChildren = !!children;
        let className = 'contextMenu--option' + `${this.props.className ? ` ${this.props.className}` : ''}${disabled ? ' disabled' : ''}${hasChildren ? ' with-children' : ''}`;
        if (this.isHovered) {
            className += ' hover';
        }
        return (
            <div className={className} onClick={this.props.onClick} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut}>
                {title}
                {this.props.children && <ContextSubMenu className={this.props.childrenClassName} children={this.props.children} />}
            </div>
        );
    }

    @action.bound
    private _onMouseOver() {
        this._stopTimer();
        if (!this.isHovered) {
            this._timer = window.setTimeout(() => {
                this.isHovered = true;
                this.props.onShow && this.props.onShow();
            }, this._delay);
        }
    }

    @action.bound
    private _onMouseOut() {
        this._stopTimer();
        if (this.isHovered) {
            this._timer = window.setTimeout(() => {
                this.isHovered = false;
                this.props.onHide && this.props.onHide();
            }, this._delay);
        }
    }

    @action.bound
    private _stopTimer() {
        if (this._timer) {
            window.clearTimeout(this._timer);
            this._timer = null;
        }
    }
}
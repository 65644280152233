import * as React from 'react';
import { observer } from 'mobx-react';
import { action, makeObservable, observable } from 'mobx';

import { AircraftDto, FlightDto, MaintenanceDto } from '@app/Models/FlightScheduleModels';
import { PromiseCompletion } from '@classes/PromiseCompletion';
import { IModalDialogContent, ModalButtonType, ModalDialogOptions, ModalWindow } from '@components/Modal/Modal';
import { FlightSchedule } from '../FlightSchedule/FlightSchedule';
import { GantViewOnSelectedEvent } from '../FlightsGanttView/FlightsGanttView';

export type FlightViewDialogProps = {
    onSelectFlight: (flight: FlightDto | null) => void;
    baseDate?: Date;
};

@observer
export class FlightViewDialog extends React.Component<FlightViewDialogProps> implements IModalDialogContent<void> {
    private _loader: PromiseCompletion = new PromiseCompletion();
    @observable private _selectedFlight: FlightDto | null = null;

    constructor(props: FlightViewDialogProps) {
        super(props);
        makeObservable(this);
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        return {
            title: 'Select leg',
            buttons: [
                {
                    type: ModalButtonType.Cancel,
                    onClick: () => {
                        window.close();
                    }
                },
                {
                    type: ModalButtonType.Save,
                    title: 'Select',
                    onClick: () => {
                        this.props.onSelectFlight(this._selectedFlight);
                        window.close();
                    }
                }
            ],
            disableAutoFocus: true,
            loader: this._loader,
            width: '1000px',
        };
    }

    render() {
        const style = {
            position: 'relative',
            height: '500px',
            marginBottom: '15px'
        } as React.CSSProperties;
        const { baseDate } = this.props;

        return (
            <>
                <div style={style}>
                    <FlightSchedule onGantItemSelected={this._onGantItemSelected} baseDate={baseDate} />
                </div>
                {this._selectedFlight && (
                    <div>
                        Selected leg:
                        <span style={{ margin: '0 5px' }}>
                            {this._selectedFlight.airlineRcd}-{this._selectedFlight.flightNumber}
                            {this._selectedFlight.legSequenceNumber > 1 ? `/${this._selectedFlight.legSequenceNumber}` : ''}
                        </span>
                        {this._selectedFlight.originRcd}-{this._selectedFlight.destinationRcd}
                    </div>
                )}
            </>
        );
    }

    @action.bound
    private _onGantItemSelected(e: GantViewOnSelectedEvent<FlightDto, AircraftDto, MaintenanceDto>) {
        this._selectedFlight = e.selectedFlights.length ? e.selectedFlights[0] : null;
    }
}

import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, action, makeObservable, computed } from 'mobx';
import Select, { components, OptionProps, StylesConfig, FormatOptionLabelMeta, InputActionMeta, SingleValue, MultiValue, ActionMeta, GroupBase, GroupProps, GroupHeadingProps, MenuListProps, OnChangeValue, PropsValue } from 'react-select';
import { FormFeedback } from 'reactstrap';

import './_react-select.scss';

type ReactSelectOption<T> = {
    rawValue: T;
    value: string;
    label: string;
    hint: string;
};

export enum ReactSelectSize {
    Regular,
    Small
}

type ReactSelectProps<T, IsMulti extends boolean> = {
    placeholder?: string;
    value?: T | T[];
    isClearable?: boolean;
    closeOnSelect?: boolean;
    isSearchable?: boolean;
    isCreatable?: boolean;
    backspaceRemovesValue?: boolean;
    hideSelectedOptions?: boolean;
    tabSelectsValue?: boolean;
    options?: (T | GroupBase<T>)[];
    multi?: IsMulti;
    onSelect?: (value?: OnChangeValue<T, IsMulti> | null) => void;
    selectSize?: ReactSelectSize;
    styles?: StylesConfig<ReactSelectOption<T>, boolean>;
    isDisabled?: boolean;
    isLoading?: boolean;
    validationErrors?: string[];
    keyField?: keyof T;
    labelField?: keyof T;
    isOptionDisabled?: (option: ReactSelectOption<T>) => boolean;
    isOptionHiden?: (option: T) => boolean;
    getOptionHint?: (option: T) => string;
    getOptionValue?: (option: T) => string;
    getOptionLabel?: (option: T) => string;
    className?: string;
    inputValue?: string;
    onMenuOpen?: () => void;
    onInputChange?: (value: string) => void;
    formatOptionLabel?: (data: T, formatOptionLabelMeta: FormatOptionLabelMeta<T>) => React.ReactNode;
};

@observer
export class ReactSelect<T, IsMulti extends boolean = false> extends React.Component<ReactSelectProps<T, IsMulti>, {}> {
    private _store: ReactSelectStore<T, IsMulti>;

    constructor(props: ReactSelectProps<T, IsMulti>) {
        super(props);
        makeObservable(this);
        this._store = new ReactSelectStore<T, IsMulti>(this.props.value, this.props.multi, this.props.keyField, this.props.labelField, this.props.options, this.props.getOptionHint, this.props.getOptionLabel, this.props.getOptionValue);
    }

    componentDidUpdate(prevProps: ReactSelectProps<T, IsMulti>) {
        if (prevProps.value !== this.props.value || prevProps.options?.length !== this.props.options?.length) {
            this._store.updateOptions(this.props.options);
            this._store.updateValue(this.props.value);
        }
    }

    render() {
        const { multi, isClearable, closeOnSelect, placeholder, isSearchable, backspaceRemovesValue, hideSelectedOptions, styles, isDisabled, validationErrors, className, isOptionDisabled, inputValue, isCreatable, isLoading, tabSelectsValue } = this.props;
        const classList = ['select-container'];
        if (validationErrors?.length) classList.push('is-invalid');
        if (className) classList.push(className);

        const Option = (props: OptionProps<ReactSelectOption<T>, IsMulti>) => {
            return (
                <div title={props.data.hint} hidden={false}>
                    <components.Option<ReactSelectOption<T>, IsMulti, GroupBase<ReactSelectOption<T>>> {...props}>{props.data.label}</components.Option>
                </div>
            );
        };

        const Group = (props: GroupProps<ReactSelectOption<T>, IsMulti, GroupBase<ReactSelectOption<T>>>) => {
            return <components.Group<ReactSelectOption<T>, IsMulti, GroupBase<ReactSelectOption<T>>> {...props} headingProps={{ ...props.headingProps }} />;
        };

        const GroupHeading = ({ ...props }: GroupHeadingProps<ReactSelectOption<T>, IsMulti>) => {
            return (
                <div
                    className="collapse-group-heading"
                    onClick={() => {
                        document.querySelector(`#${props.id}`)?.parentElement?.parentElement?.classList.toggle('collapsed-group');
                    }}
                >
                    <components.GroupHeading<ReactSelectOption<T>, IsMulti, GroupBase<ReactSelectOption<T>>> {...props} />
                </div>
            );
        };

        const MenuList = (props: MenuListProps<ReactSelectOption<T>, IsMulti, GroupBase<ReactSelectOption<T>>>) => {
            const newProps: MenuListProps<ReactSelectOption<T>, IsMulti, GroupBase<ReactSelectOption<T>>> = {
                ...props,
                children: props.children && Array.isArray(props.children) ? props.children.map((c) => (c.props.children.length == 1 ? c.props.children[0] : { ...c, props: { ...c.props, className: 'collapsed-group' } })) : props.children
            };

            return <components.MenuList<ReactSelectOption<T>, IsMulti, GroupBase<ReactSelectOption<T>>> {...newProps} />;
        };

        return (
            <div className="w-100">
                <Select<ReactSelectOption<T>, IsMulti, GroupBase<ReactSelectOption<T>>>
                    isMulti={multi}
                    styles={styles}
                    isDisabled={isDisabled}
                    className={classList.join(' ')}
                    classNamePrefix="select-filter"
                    placeholder={placeholder}
                    closeMenuOnSelect={closeOnSelect || !multi}
                    isClearable={!!isClearable}
                    hideSelectedOptions={!!hideSelectedOptions}
                    backspaceRemovesValue={!!backspaceRemovesValue}
                    isSearchable={isSearchable || isCreatable}
                    onChange={this._selectHandler}
                    onMenuClose={this._closeHandler}
                    onMenuOpen={this._openHandler}
                    options={this._options}
                    isLoading={isLoading}
                    tabSelectsValue={tabSelectsValue}
                    isOptionDisabled={isOptionDisabled}
                    value={this._store.currentValue}
                    components={{ Option, Group, GroupHeading, MenuList }}
                    inputValue={inputValue}
                />
                {!!validationErrors?.length && <FormFeedback>{validationErrors && validationErrors.map((error: string) => <div key={error}>{error}</div>)}</FormFeedback>}
            </div>
        );
    }

    @action.bound
    private _selectHandler(selected: OnChangeValue<ReactSelectOption<T>, IsMulti>, { action }: ActionMeta<ReactSelectOption<T>>) {
        const { onSelect, multi } = this.props;
        const { setValue } = this._store;

        setValue(selected);

        const isClearAction = action === 'clear' || action === 'remove-value';
        if (!multi || isClearAction) {
            onSelect && onSelect(this._store.currentRawValue ?? null);
        }
    }

    @action.bound
    private _closeHandler() {
        const { multi, onSelect } = this.props;
        const { currentRawValue } = this._store;
        if (!multi) return;
        if (currentRawValue) {
            onSelect && onSelect(currentRawValue);
        }
    }

    @action.bound
    private _openHandler() {
        const { onMenuOpen } = this.props;

        onMenuOpen && onMenuOpen();
    }

    private get _options() {
        if (!this.props.options) return [];

        const options = this._store.mapOptions(this.props.options) ?? [];

        return options;
    }

    public static GenerateGroupedOptionsFrom<T>(obj: T[], valKey?: keyof T, labKey?: keyof T, groupKey?: keyof T): GroupBase<T>[] {
        if (obj.length && typeof obj[0] === 'string') {
            return obj.map((e) => {
                const value = e as unknown as string;
                return { value, label: value, options: [] };
            });
        }

        if (!valKey || !labKey || !groupKey) return [];

        const resultMap = new Map<string, { value: T; label: string }[]>();
        for (const candidate of obj) {
            const [group] = `${candidate[groupKey]}`.split('/');

            if (resultMap.has(group)) {
                resultMap.get(group)?.push({ value: candidate, label: `${candidate[labKey]}` });
            } else {
                resultMap.set(group, [{ value: candidate, label: `${candidate[labKey]}` }]);
            }
        }

        const resultArr: GroupBase<T>[] = [];

        for (const [key, value] of resultMap.entries()) {
            resultArr.push({
                label: key,
                options: value.map((e) => e.value)
            });
        }

        return resultArr;
    }
}

class ReactSelectStore<T, IsMulti extends boolean> {
    @observable currentValue: PropsValue<ReactSelectOption<T>>;
    @observable private _getOptionLabel?: (option: T) => string;
    @observable private _getOptionValue?: (option: T) => string;
    @observable private _getOptionHint?: (option: T) => string;

    @observable private _keyField?: keyof T;
    @observable private _labelField?: keyof T;

    @observable private _options?: (T | GroupBase<T>)[];
    @observable private _multi: IsMulti;

    constructor(value?: T | T[] | null, multi?: IsMulti, keyField?: keyof T, labelField?: keyof T, options?: (T | GroupBase<T>)[], getOptionHint: ((option: T) => string) | undefined = undefined, getOptionLabel: ((option: T) => string) | undefined = undefined, getOptionValue: ((option: T) => string) | undefined = undefined) {
        makeObservable(this);

        this._multi = multi ?? (false as IsMulti);
        this._keyField = keyField;
        this._labelField = labelField;
        this._options = options;
        this._getOptionLabel = getOptionLabel;
        this._getOptionValue = getOptionValue;
        this._getOptionHint = getOptionHint;

        this.updateValue(value);
    }

    @action.bound
    updateOptions(options?: (T | GroupBase<T>)[]) {
        this._options = options;
    }

    @action.bound
    updateValue(value?: T | T[] | null) {
        const getActualValue = (val?: T | T[] | null, options?: (T | GroupBase<T>)[]) => {
            if (!options || options.length == 0 || !val) return val;
            const values = Array.isArray(val) ? val : [val];
            const res: T[] = [];
            for (let v of values) {
                for (let o of options) {
                    const groupOption = o as GroupBase<T>;
                    if (groupOption.options != undefined) {
                        for (let o1 of groupOption.options) {
                            if (this._getOptionValueInternal(v) === this._getOptionValueInternal(o1 as T)) {
                                res.push(o1);
                            }
                        }
                    } else if (this._getOptionValueInternal(v) === this._getOptionValueInternal(o as T)) {
                        res.push(o as T);
                    }
                }
            }

            return Array.isArray(val) ? res : res[0];
        };

        const actualValue = getActualValue(value, this._options);

        this.currentValue = this.mapValue(actualValue);
    }

    @action.bound
    setValue(value?: PropsValue<ReactSelectOption<T>>) {
        this.currentValue = value ?? null;
    }

    @computed
    public get currentRawValue(): OnChangeValue<T, IsMulti> | null {
        return this._multi 
            ? ((this.currentValue as ReactSelectOption<T>[] | null)?.map((x) => x.rawValue) as OnChangeValue<T, IsMulti>) 
            : ((this.currentValue as ReactSelectOption<T> | null)?.rawValue as OnChangeValue<T, IsMulti>);
    }

    private _getOptionValueInternal(option: T) {
        if (this._keyField) {
            return option[this._keyField];
        } else if (this._getOptionValue) {
            return this._getOptionValue(option);
        }

        throw new Error("Failed to get option's value");
    }

    public mapValue(value?: T | T[] | null): PropsValue<ReactSelectOption<T>> {
        if (value === null || value == undefined) return null;

        const getLabel = (v: T) => {
            if (this._labelField) {
                return v[this._labelField];
            } else if (this._getOptionLabel) {
                return this._getOptionLabel(v);
            }

            throw new Error("Failed to get option's label");
        };

        const map = (v: T) => {
            return {
                rawValue: v,
                hint: this._getOptionHint?.(v),
                label: getLabel(v),
                value: this._getOptionValueInternal(v)
            } as ReactSelectOption<T>;
        };

        if (Array.isArray(value)) {
            return value.map((v) => map(v));
        }

        return map(value);
    }

    public mapOptions(options: (T | GroupBase<T>)[]): (ReactSelectOption<T> | GroupBase<ReactSelectOption<T>>)[] {
        if (options.length == 0) return [];

        const groupBase = options as GroupBase<T>[];
        if (groupBase[0].options != undefined) {
            const arr: GroupBase<ReactSelectOption<T>>[] = [];
            groupBase.forEach((group) => {
                arr.push({
                    label: group.label,
                    options: this.mapValue(group.options as T[]) as ReactSelectOption<T>[]
                });
            });

            return arr;
        } else {
            const values = options as T[];
            return this.mapValue(values) as ReactSelectOption<T>[];
        }
    }
}

import * as React from 'react';
import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Input, InputProps, Label, FormFeedback } from 'reactstrap';

import { BaseFormModel } from '@services/BaseFormModel';
import './_form-controls.scss';

export class SelectItem {
    value: string;
    label: string;
}

export type FormInputType<T> = Omit<InputProps, 'name'> & ({
    name?: string;
    formModel?: undefined;
} | {
    name: keyof T;
    formModel?: T;
});

export type IFormCheckboxProps<T extends BaseFormModel> = FormInputType<T> & {
    label?: string;
    checked: boolean;
    invalid?: boolean;
    disabled?: boolean;
    changeHandler?: Function | void;
    bsSize?: 'lg' | 'sm';
    className?: string;
    topAligned?: boolean;
    smallValidationError?: boolean;
    customLabel?: () => JSX.Element;
};

@observer
export class FormCheckbox<T extends BaseFormModel> extends React.Component<IFormCheckboxProps<T>> {

    constructor (props: IFormCheckboxProps<T>) {
        super(props);
        makeObservable(this);
    }

    public render () {
        const {
            label,
            name,
            formModel,
            checked,
            invalid,
            disabled,
            changeHandler,
            bsSize,
            className,
            topAligned,
            title,
            customLabel,
            ...rest
        } = this.props;
        let isChecked: boolean = checked;
        let isInvalid: boolean | undefined = invalid;
        const classList: string[] = ['checkbox'];
        if (formModel && name) {
            const value = Boolean(formModel.getValue(name as keyof T));
            isChecked = value === true;
            isInvalid = formModel.validated ? !formModel.isValid(name as keyof T) : formModel.validated;
        }
        if (bsSize) classList.push(bsSize);
        if (className) classList.push(className);
        if (topAligned) classList.push('top');

        return (
            <div className='checkbox-wrapper'>
                <Label className={classList.join(' ')} title={title}>
                    <Input
                        checked={isChecked ?? false}
                        invalid={isInvalid}
                        disabled={disabled}
                        type="checkbox"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            if (formModel) {
                                formModel.setValue(name as keyof T, event.target.checked);
                            }
                            if (changeHandler) changeHandler();
                        }}
                        name={name as string}
                        {...rest}
                    />
                    {customLabel ? customLabel() : <span>{label}</span>}
                </Label>
                {formModel && formModel.validated && formModel.invalidFields.includes(name as string) && (
                    <FormFeedback style={{ display: 'block' }}>
                        {formModel.errorFor(name as keyof T).map((error: string) => (
                            <div key={(name as string) + error}>{error}</div>
                        ))}
                    </FormFeedback>
                )}
            </div>
        );
    }
}

export interface IFormInputProps<T extends BaseFormModel> extends Omit<InputProps, 'name'> {
    formModel: T;
    name: keyof T;
    invalid?: boolean;
    smallValidationError?: boolean;
    placeholder?: string;
    changeHandler?: Function;
    transformValueHandler?: (val: string) => string;
    style?: React.CSSProperties;
    className?: string;
    hideValidationErrors?: boolean;
    disabled?: boolean;
}

@observer
export class FormInput<T extends BaseFormModel> extends React.Component<IFormInputProps<T>> {

    constructor (props: IFormInputProps<T>) {
        super(props);
        makeObservable(this);
    }

    public render () {
        const {
            formModel,
            name,
            changeHandler,
            invalid,
            transformValueHandler,
            formNamePrefix,
            style,
            className,
            hideValidationErrors,
            disabled,
            ...rest
        } = this.props;
        const fieldValue = formModel.getValue(name);
        const isInvalid: boolean | undefined = invalid || (formModel.validated ? !formModel.isValid(name) : formModel.validated);
        return (
            <>
                <Input style={style} className={className}
                       name={(formNamePrefix ?? '') + (name as string)}
                       value={fieldValue as unknown as string}
                       autoComplete="off"
                       invalid={isInvalid}
                       onChange={(ev) => {
                           const value = transformValueHandler ? transformValueHandler(ev.target.value) : ev.target.value;
                           formModel.setValue(name, value);
                           changeHandler?.(ev);
                       }}
                       disabled={disabled}                       
                       {...rest}
                />
                {!hideValidationErrors && formModel.validated && formModel.invalidFields.includes(name as string) && (
                    <FormFeedback style={{ display: 'block' }}>
                        {formModel.errorFor(name).map((error: string) => (
                            <div key={(name as string) + error}>{error}</div>
                        ))}
                    </FormFeedback>
                )}
            </>
        );
    }
}

export interface IFormSelectProps<T extends BaseFormModel> extends Omit<InputProps, 'name'> {
    formModel: T;
    name: keyof T;
    options: (SelectItem | string)[];
    hasEmpty?: boolean;
    placeholder?: string;
    customOptions?: JSX.Element[];
    changeHandler?: Function;
    customInputChangeHandler?: Function;
    customSelectChangeHandler?: Function;
    showField?: boolean;
    smallValidationError?: boolean;
    disabled?: boolean;
}

@observer
export class FormSelect<T extends BaseFormModel> extends React.Component<IFormSelectProps<T>> {

    constructor (props: IFormSelectProps<T>) {
        super(props);
        makeObservable(this);
    }

    public render () {
        const {
            formModel,
            name,
            placeholder,
            options,
            customOptions,
            hasEmpty,
            changeHandler,
            showField,
            customInputChangeHandler,
            customSelectChangeHandler,
            changeYearPeriodHandler,
            ...rest
        } = this.props;
        const fieldValue = formModel.getValue(name);
        const selectComponent = (
            <>
                <Input
                    name={name as string}
                    value={fieldValue as unknown as string}
                    invalid={formModel.validated ? !formModel.isValid(name) : formModel.validated}
                    onFocus={(event: React.ChangeEvent<HTMLElement>) => {
                        if (showField && options) {
                            const index = options.findIndex((opt) => {
                                const val = typeof opt === 'string' ? opt : opt.value;
                                return val.toLowerCase() === String(fieldValue).toLowerCase();
                            });
                            const select = event.target as HTMLSelectElement;
                            select.selectedIndex = index;
                        }
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        formModel.setValue(name, event.target.value);
                        if (changeYearPeriodHandler) changeYearPeriodHandler();
                        if (customSelectChangeHandler) {
                            customSelectChangeHandler();
                        } else if (changeHandler) {
                            changeHandler();
                        }
                    }}
                    placeholder={placeholder}
                    type="select"
                    {...rest}
                >
                    {hasEmpty && <option value=""/>}
                    {!customOptions &&
                        options.map((option: SelectItem | string) => {
                            const value = typeof option === 'string' ? option : option.value;
                            const label = typeof option === 'string' ? option : option.label;
                            return (
                                <option value={value} key={value}>
                                    {label}
                                </option>
                            );
                        })}
                    {customOptions}
                </Input>
                {formModel.validated && formModel.invalidFields.includes(name as string) && (
                    <FormFeedback style={{ display: 'block' }}>
                        {formModel.errorFor(name).map((error: string) => (
                            <div key={(name as string) + error}>{error}</div>
                        ))}
                    </FormFeedback>
                )}
            </>
        );
        if (showField) {
            return (
                <div className="select-field">
                    <Input
                        name={name as string}
                        value={fieldValue as unknown as string}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            formModel.setValue(name, event.target.value);
                            if (customInputChangeHandler) {
                                customInputChangeHandler();
                            } else if (changeHandler) {
                                changeHandler();
                            }
                        }}
                        autoComplete="off"
                        {...rest}
                    />
                    {selectComponent}
                </div>
            );
        } else {
            return selectComponent;
        }
    }
}

@observer
export class FormSwitcher<T extends BaseFormModel> extends React.Component<IFormCheckboxProps<T>> {

    constructor (props: IFormCheckboxProps<T>) {
        super(props);
        makeObservable(this);
    }

    public render () {
        const { name, formModel, changeHandler, label } = this.props;
        if (formModel) {
            const value = formModel.getValue(name as keyof T);
            return (
                <div className="switcher-wrapper">
                    {label && <span className="switcher-label">{label}</span>}
                    <Label className="switcher">
                        <Input
                            type="checkbox"
                            checked={!!value}
                            name={name as string}
                            invalid={formModel.validated ? !formModel.isValid(name as keyof T) : formModel.validated}
                            disabled={this.props.disabled}
                            onChange={(ev) => {
                                formModel.setValue(name as keyof T, ev.target.checked);
                                if (changeHandler) changeHandler();
                            }}
                        />
                        <span/>
                    </Label>
                    {formModel.validated && formModel.invalidFields.includes(name as string) && (
                        <FormFeedback style={{ display: 'block' }}>
                            {formModel.errorFor(name as keyof T).map((error: string) => (
                                <div key={(name as string) + error}>{error}</div>
                            ))}
                        </FormFeedback>
                    )}
                </div>
            );
        }
    }
}

export interface IFormSimpleSelectProps<T extends BaseFormModel> extends Omit<InputProps, 'name'> {
    formModel: T;
    name: keyof T;
    options: string[] | SelectItem[];
    placeholder?: string;
    hasNoEmptyOption?: boolean;
    onChanged?: Function | void;
}

@observer
export class FormSimpleSelect<T extends BaseFormModel> extends React.Component<IFormSimpleSelectProps<T>, {}> {

    constructor(props: IFormSimpleSelectProps<T>) {
        super(props);
        makeObservable(this);
    }

    public render() {
        const { formModel, name, onChanged, placeholder, options, hasNoEmptyOption } = this.props;
        const value = formModel.getValue(name);
        return (
            <>
                <Input
                    name={name as string}
                    value={value as string}
                    invalid={formModel.validated ? !formModel.isValid(name) : formModel.validated}
                    onChange={ev => {
                        formModel.setValue(name, ev.target.value);
                        if (onChanged) onChanged(ev.target.value);
                    }}
                    placeholder={placeholder}
                    type="select"
                >
                    {!hasNoEmptyOption && <option value="" />}
                    {options.map((option: string | SelectItem) => {
                        const value = typeof option === 'string' ? option : option.value;
                        const label = typeof option === 'string' ? option : option.label;
                        return (
                            <option value={value} key={value}>
                                {label}
                            </option>
                        );
                    })}
                </Input>
                <FormFeedback>{formModel.errorFor(name)}</FormFeedback>
            </>
        );
    }
}


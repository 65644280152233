interface ICallbackFunction extends Function {
	selectTimeOut?: number;
	amountOfCall?: number;
}

class Extensions {

	public executeTimeout(callBack: Function, timeout: number, scope?: unknown, ...args: unknown[]) {
		const extendedFunction = <ICallbackFunction>callBack;
		if (extendedFunction.selectTimeOut) {
			clearTimeout(extendedFunction.selectTimeOut);
		}
        extendedFunction.selectTimeOut = window.setTimeout(() => {
			callBack.apply(scope, args);
		}, timeout);
	}

    public executeTimeoutWithCounter(callBack: Function, timeout: number, scope?: unknown, ...args: unknown[]) {
        const extendedFunction = <ICallbackFunction>callBack;
        if (extendedFunction.selectTimeOut) {
            clearTimeout(extendedFunction.selectTimeOut);
            extendedFunction.amountOfCall = extendedFunction.amountOfCall ? extendedFunction.amountOfCall + 1 : 1;
        }
        extendedFunction.selectTimeOut = window.setTimeout(() => {
            args.push(extendedFunction.amountOfCall || 1);
            extendedFunction.amountOfCall = 0;
            callBack.apply(scope, args);
        }, timeout);
    }
    
    public getDecimal(num: number): number {
        return num > 0 ? (num % 1) : (-num % 1);
    }
    
    public deepCopy(obj: unknown): unknown {
        // tslint:disable-next-line: no-any
        let copy: any;

        if (null == obj || 'object' !== typeof obj) return obj;

        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        if (obj instanceof Array) {
            copy = [];
            for (let i = 0; i < obj.length; i++) {
                copy[i] = this.deepCopy(obj[i]);
            }
            return copy;
        }

        if (obj instanceof Object) {
            copy = {};
            for (let attr in obj) {
                // tslint:disable-next-line: no-any
                if (Object.hasOwnProperty.call(obj, attr)) copy[attr] = this.deepCopy((obj as any)[attr]);
            }
            return copy;
        }

        return {};
    }
}

export const extensions = new Extensions();
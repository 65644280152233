import React from 'react';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { observer } from 'mobx-react';

import { AircraftDto, BufferItem, FlightDto, FlightIdInfo, MaintenanceDto } from '@app/Models/FlightScheduleModels';
import { ApiUrls, DateTime, PrincipalConfig } from '@app/AppConstants';
import { DateTimeService } from '@services/DateTimeService';
import { extensions } from '@services/Extensions';
import { targetConstructor } from '@classes/TargetConstructor';
import { configurationStore } from '@stores/ConfigurationStore';

import FlightsGanttView, { GantViewOnSelectedEvent, RenderExtendedControlsArgs } from '@components/FlightsGanttView/FlightsGanttView';
import { FlightRenderArgs, GanttViewState, GantZoom, NavigateBehavior } from '@components/FlightsGanttView/FlightsGanttTypes';
import GantViewFilter, { GantFilter } from './Gant/GantViewFilter';
import FlightListContextMenu from './Gant/FlightListContextMenu';
import './_virtualized-flight-schedule.scss';

type FlightScheduleProps = {
    allowMultiSelect?: boolean;
    allowContextMenu?: boolean;
    onGantItemSelected: (e: GantViewOnSelectedEvent<FlightDto, AircraftDto, MaintenanceDto>) => void;
    baseDate?: Date;
};

@observer
export class FlightSchedule extends React.Component<FlightScheduleProps> {
    @observable private _gantFilter: GantFilter | null = null;
    @observable private _gantZoom: GantZoom | null = null;
    @observable private _bufferedIds: string[] = [];
    private _gantViewRef: React.RefObject<FlightsGanttView<FlightDto>> = React.createRef();
    private _viewState: GanttViewState | null = null;

    constructor(props: FlightScheduleProps) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        void this._loadState();
    }

    render() {
        return (
            <>
                {this._gantFilter && this._viewState && typeof this._gantZoom === 'number' && (
                    <>
                        <GantViewFilter 
                            defaultFilter={this._gantFilter} 
                            onChange={this._onGantFilterChange} 
                            onFlightFound={this._onFlightFound} 
                            onRefresh={async () => this._gantViewRef.current?.refresh()} 
                            onDateChange={(date) => this._gantViewRef.current?.navigateTo(null, date, NavigateBehavior.center, true)} 
                        />

                        <FlightsGanttView<FlightDto>
                            ref={this._gantViewRef}
                            viewState={this._viewState}
                            initialZoom={this._gantZoom}
                            showInactive={this._gantFilter.showInactive}
                            filterText={this._gantFilter.filterText}
                            aircraftsUrl={ApiUrls.AircraftsUrl + '?onlyWk=false'}
                            maintenancesUrl={ApiUrls.MaintenancesUrl}
                            flightsUrl={this._gantFilter.flightsUrl}
                            onSelected={this._onGantItemSelected}
                            onScroll={this._onGantScroll}
                            onZoom={this._onGantZoom}
                            onFlightRender={this._onGanttFlightRender}
                            onRenderExtendedControls={this._onGanttRenderExtendedControls}
                            onFlightMatch={this._onGanttFlightMatch}
                            bufferedIds={this._bufferedIds}
                            allowMultiSelect={this.props.allowMultiSelect}
                        />
                    </>
                )}
            </>
        );
    }

    private _onGanttFlightRender = (e: FlightRenderArgs<FlightDto>) => {
        let { flight, className: aircraftItemClassName } = e;

        if (flight.status !== 'Actual') {
            if (flight.status !== 'Changed') {
                aircraftItemClassName += ' inactive';
            } else {
                aircraftItemClassName += ' changed';
            }
        }
        e.className = aircraftItemClassName;
    };

    @action
    private _onGanttRenderExtendedControls = (e: RenderExtendedControlsArgs) => {
        if (!this.props.allowContextMenu) return null;
        return <FlightListContextMenu container={e.container} loader={e.loader} onRefresh={async () => this._gantViewRef.current?.refresh()} onUpdateBuffer={this._onUpdateBuffer} />;
    };

    private _onGanttFlightMatch = (flight: FlightDto, filter: string) => {
        if (flight.netlineOpsLegId?.toString() === filter) {
            return true;
        }

        if (flight.enzianId?.toString() === filter) {
            return true;
        }

        return false;
    };

    private async _loadState() {
        let [date, filter, showInactive, zoom, flightsUrl, scrollTop, scrollLeft, timestamp] = await Promise.all<string | null>([
            configurationStore.get(PrincipalConfig.FlightsGantGroup, 'date'),
            configurationStore.get(PrincipalConfig.FlightsGantGroup, 'filter'),
            configurationStore.get(PrincipalConfig.FlightsGantGroup, 'showInactive'),
            configurationStore.get(PrincipalConfig.FlightsGantGroup, 'zoom'),
            configurationStore.get(PrincipalConfig.FlightsGantGroup, 'flightsUrl'),
            configurationStore.get(PrincipalConfig.FlightsGantGroup, 'scrollTop'),
            configurationStore.get(PrincipalConfig.FlightsGantGroup, 'scrollLeft'),
            configurationStore.get(PrincipalConfig.FlightsGantGroup, 'timestamp')
        ]);
        let activeDate: Date;

        runInAction(() => {
            if (timestamp) {
                const hoursPassed = DateTimeService.diffHours(DateTimeService.now(), new Date(timestamp));
                if (hoursPassed > 10) {
                    date = DateTimeService.format(DateTimeService.now(), DateTime.jsonDateFormat);
                    scrollTop = '';
                    scrollLeft = '';
                }
            }

            const params = new URLSearchParams(window.location.search);
            const dateString = params.get('date');
            if (dateString) {
                const dateFromQuery = DateTimeService.parse(dateString, DateTime.jsonDateFormat);
                activeDate = dateFromQuery;
            }
            else {
                activeDate = this.props.baseDate ?? DateTimeService.today();

                if(date){
                    const parsedDate = DateTimeService.parse(date, DateTime.jsonDateFormat);
                    const isValidDate = DateTimeService.isValidDate(parsedDate);
                    if(isValidDate) activeDate = parsedDate;
                }
            }

            this._gantFilter = {
                date: activeDate,
                filterText: filter ?? '',
                showInactive: showInactive === 'true',
                flightsUrl: flightsUrl ?? ApiUrls.FlightsFullUrl
            };
            this._gantZoom = zoom ? +zoom : GantZoom.x1;

            this._viewState = {
                dateFrom: DateTimeService.addDays(activeDate, -this._viewportDateOffset),
                dateTo: DateTimeService.addDays(activeDate, this._viewportDateOffset),
                viewportDateFrom: activeDate,
                scrollTop: scrollTop ? +scrollTop : void 0,
                scrollLeft: scrollLeft ? +scrollLeft : void 0
            };
        });
        reaction(
            () => this._gantZoom,
            () => {
                this._viewState!.dateFrom = DateTimeService.addDays(activeDate, -this._viewportDateOffset);
                this._viewState!.dateTo = DateTimeService.addDays(activeDate, this._viewportDateOffset);
            }
        );
        this._resolveUrlFlight();
    }

    @action
    private async _resolveUrlFlight() {
        const params = new URLSearchParams(window.location.search);
        const flightIdParam = params.get('flightId');
        if (flightIdParam) {
            const flight = targetConstructor.getFlightInformationFromFlightId(flightIdParam);

            await this._gantViewRef.current?.refreshFlightsRange(flight.date);
            await this._gantViewRef.current?.waitLoader();
            this._onFlightFound(flight.id, {
                airlineRcd: flight.airlineRcd,
                flightNumber: flight.number,
                date: flight.date,
                destinationRcd: flight.destinationRcd,
                originRcd: flight.originRcd,
                legNumber: flight.legSequenceNumber
            });
        }
    }

    @action.bound
    private _onGantFilterChange(filter: GantFilter) {
        const oldFilter = this._gantFilter;
        if (oldFilter) {
            if (!DateTimeService.isSameDate(oldFilter.date, filter.date)) {
                void configurationStore.set(PrincipalConfig.FlightsGantGroup, 'date', DateTimeService.format(filter.date, DateTime.jsonDateFormat));
            }
            if (oldFilter.filterText !== filter.filterText) {
                void configurationStore.set(PrincipalConfig.FlightsGantGroup, 'filter', filter.filterText);
            }
            if (oldFilter.showInactive !== filter.showInactive) {
                void configurationStore.set(PrincipalConfig.FlightsGantGroup, 'showInactive', filter.showInactive ? 'true' : 'false');
            }
            if (oldFilter.flightsUrl !== filter.flightsUrl) {
                void configurationStore.set(PrincipalConfig.FlightsGantGroup, 'flightsUrl', filter.flightsUrl);
            }
            void configurationStore.set(PrincipalConfig.FlightsGantGroup, 'timestamp', DateTimeService.now().toJSON());
        }
        this._gantFilter = filter;
    }

    @action.bound
    private _onUpdateBuffer(buffer: BufferItem | null) {
        this._bufferedIds = buffer?.flight?.id ? [buffer?.flight?.id] : [];
    }

    @action.bound
    private _onFlightFound(id: string, flight: FlightIdInfo) {
        this._gantViewRef.current?.navigateTo(id, flight.date, NavigateBehavior.center, true);
    }

    @action.bound
    private _onGantItemSelected(e: GantViewOnSelectedEvent<FlightDto, AircraftDto, MaintenanceDto>) {
        this.props.onGantItemSelected(e);
    }

    @action.bound
    private _onGantScroll(scrollTop: number, scrollLeft: number) {
        extensions.executeTimeout(this._persistView, 300, this, scrollTop, scrollLeft);
    }

    @action.bound
    private _onGantZoom(zoom: GantZoom) {
        this._gantZoom = zoom;
        void configurationStore.set(PrincipalConfig.FlightsGantGroup, 'zoom', zoom.toString());
    }

    @action.bound
    private _persistView(scrollTop: number, scrollLeft: number) {
        void configurationStore.set(PrincipalConfig.FlightsGantGroup, 'scrollTop', scrollTop.toString());
        void configurationStore.set(PrincipalConfig.FlightsGantGroup, 'scrollLeft', scrollLeft.toString());
    }

    @computed
    private get _viewportDateOffset(): number {
        const zoom = this._gantZoom ? this._gantZoom : 0.5;
        return Math.ceil(5 * 2 * zoom);
    }
}

import * as React from 'react';
import './job-list-filter.scss';

type JobListFilterProps = {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default class JobListFilter extends React.PureComponent<JobListFilterProps, {}> {
    public render() {
        const { value, onChange } = this.props;
        return (
            <div className="job-list-filter">
                <input className="form-control" value={value} onChange={onChange} placeholder="Filter" />
            </div>
        );
    }
}

import { TermsInput, TermsInputProps } from '@components/TermsInput/TermsInput';

const tokens: { [key: string]: RegExp } = {
    aircraft: /^([a-zA-Z]{2}-[a-zA-Z]{3})/,
    flightLeg: /^([a-zA-Z]{2}(-)?\d{1,4})[\\/](\d{1})/,
    flight: /^([a-zA-Z]{2}(-)?\d{1,4})/,
    flightId: /^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}{0,1})/,
    flightDeparture: /^today\b|^yesterday\b|^tomorrow\b/i,
    username: /^@[A-Za-z0-9_.-]+/,
    hashtag: /^#[A-Za-z0-9_-]+/,
    word: /^\S+/,
    ws: /^\s+/m,
};

const toks = Object.entries(tokens);

const keyWordTokenBlackList: (keyof typeof tokens)[] = ['flightId', 'flight', 'flightDeparture'];

const render = (next: string, uncoveredTerms?: string[]): (JSX.Element | string)[] | null => {
    const out: (JSX.Element | string)[] = [];

    const matches = detectMatches(next);

    if (!matches) return null;

    const units = ([] as QueryMatchUnit[])
            .concat(...Object.entries(matches.matches).map(e => e[1]))
            .sort((a,b) => a.sortOrder - b.sortOrder);

    units.forEach(unit => {
        out.push(
            <span key={unit.sortOrder} className={`messageInput-${unit.token}` + (uncoveredTerms?.indexOf(unit.value) !== -1 ? ' uncovered-term' : '')}>
                {unit.value}
            </span>
        )
    })

    return out;
};

const detectMatches = (next: string) => {
    const result: QueryInputTermsMatches = {
        source: next,
        clearedSource: next,
        matches: {
            remain: []
        }
    };
    const keyWordsBlackList = [];
    let remain = next;
    let order = 0;

    if (!next) {
        return null;
    }

    while (remain.length) {
        let match: RegExpMatchArray | null = null;
        for (const [token, re] of toks) {
            match = remain.match(re);

            if (match) {
                if (keyWordTokenBlackList.indexOf(token) !== -1) {
                    keyWordsBlackList.push(match[0]);
                }

                if (!result.matches[token])
                    result.matches[token] = [];

                result.matches[token].push({
                    sortOrder: order,
                    value: match[0],
                    token
                });
                
                order += 1;
                break;
            }
        }

        if (!match) {
            break;
        }

        remain = remain.slice(match[0].length);
    }

    if (remain.length) {
        result.matches.remain.push({
            value: remain,
            sortOrder: order + 1,
            token: 'remain'
        });
    }

    keyWordsBlackList.forEach(baned => {
        result.clearedSource = result.clearedSource.replace(baned, '').trim();
    })

    return result;
}

type QueryMatchUnit = {
    sortOrder: number, 
    value: string,
    token: string
}
export type QueryInputTermsMatches = {
    source: string,
    clearedSource: string,
    matches: {
        [key: keyof typeof tokens]: QueryMatchUnit[],
        remain: QueryMatchUnit[]
    }
}
type QueryInputInputProps = Omit<TermsInputProps, 'render'|'onChange'> & {
    onMatchedChange: (matches?: QueryInputTermsMatches | null) => void;
    uncoveredTerms?: string[];
};

export function QueryInput (props: QueryInputInputProps) {
    return (
        <TermsInput
            className="messageInput"
            showSearchIcon
            render={render}
            onChange={(value) => props.onMatchedChange(detectMatches(value))}
            {...props}
        />
    );
}
import { action, makeObservable, observable } from 'mobx';

import { ApiUrls } from '@app/AppConstants';
import {
    ApiV1Job,
    JobDetailsModel,
    JobParameterModel,
    JobSaveParametersModel,
    JobTriggerShortInfo
} from '../../Models/JobListModels';
import ApiService from '@app/Services/ApiService';

import { modalService, ModalButtonType } from '@app/Components/Modal/Modal';
import { JobParametersDialog } from '../JobParametersDialog/JobParametersDialog';
import { JobItemProps } from './JobItem';

export default class JobItemStore {
    @observable isOpenDropdown: boolean = false;
    @observable errorToggle: boolean = false;
    @observable isStopping: boolean = false;
    private _job: ApiV1Job;
    private _props: JobItemProps;

    constructor(props: JobItemProps) {
        makeObservable(this);
        this._job = props.job;
        this._props = props;
    }

    statusPending: string = 'Pending';

    @action
    toggleLastError() {
        this.errorToggle = !this.errorToggle;
    }

    @action
    async runJob() {
        const { name } = this._job;
        const { data: job } = await ApiService.get<JobDetailsModel>(ApiUrls.JobParametersUrl + '/' + name, null);

        const parameters = job.parameters;
        const title = `Run ${name} job with parameters`;
        if (parameters && parameters.length > 0) {
            await modalService.show(
                JobParametersDialog,
                {
                    showTriggerState: false,
                    jobData: job,
                    onSubmit: this._onSubmit
                },
                {
                    title: title
                }
            );
        } else {
            if (await modalService.showConfirmation(`Do you really want to run job ${name} now?`)) {
                await this.runTriggerInternal([], name);
            }
        }
    }

    @action.bound
    setIsStopping(value: boolean) {
        this.isStopping = value;
    }

    @action.bound
    async stopJob() {
        const { name } = this._job;

        if (await modalService.showConfirmation(`Do you really want to stop job ${name} before it's over?`)) {
            await ApiService.postData(`${ApiUrls.JobStopUrl}/${name}`);
            this.setIsStopping(true);
        }
    }

    async showJobParams(triggerId: string = '') {
        const { name } = this._job;
        const trigger = (triggerId && this._job.triggers.find((t) => t.name === triggerId)) || null;
        const { data } = await ApiService.get<JobDetailsModel>(ApiUrls.JobParametersUrl + '/' + name + '?triggerName=' + triggerId);

        await this._showJobParamsModal(data, trigger);
    }

    async saveParams(jobName: string, payload: JobSaveParametersModel) {
        const { data } = await ApiService.postTypedData<ApiV1Job>(ApiUrls.JobParametersUrl + '/' + jobName, payload);

        this._job = data;
        this._props.onParametersSaved?.(data);
    }

    private async _showJobParamsModal(data: JobDetailsModel, trigger: JobTriggerShortInfo | null) {
        const title = 'Job Parameters: ' + ((trigger && trigger.name) || `New trigger for ${data.name}`);

        await modalService.show(
            JobParametersDialog,
            {
                showTriggerState: true,
                jobData: data,
                trigger: trigger,
                onSubmit: this._onSubmit
            },
            {
                title: title
            }
        );
    }

    @action.bound
    private async _onSubmit(button: ModalButtonType, result: JobSaveParametersModel) {
        if (button === ModalButtonType.RunNow) {
            await this.runTriggerInternal(result?.parameters ? result.parameters : [], result?.jobName || '');
        } else if (button === ModalButtonType.SaveAndRunNow) {
            await this.saveParams(result?.jobName || '', result as JobSaveParametersModel);
            await this.runTriggerInternal(result?.parameters ? result.parameters : [], result?.jobName || '');
        } else if (button === ModalButtonType.Save) {
            await this.saveParams(result?.jobName || '', result as JobSaveParametersModel);
        } else if (button === ModalButtonType.CreateNew) {
            await this.createNewTrigger(result as JobSaveParametersModel);
        } else if (button === ModalButtonType.Delete) {
            return await this.delete(result as JobSaveParametersModel);
        } else if (button === ModalButtonType.Submit && result?.parameters) {
            await this.runTriggerInternal(result.parameters, result.jobName);
        }
        return true;
    }

    @action
    async createNewTrigger(jobDetails: JobSaveParametersModel) {
        try {
            const { data } = await ApiService.postTypedData<ApiV1Job>(ApiUrls.JobTriggerCreateUrl, jobDetails);
            this._props.onTriggerCreated?.(data);
            this._job = data;
        } catch (conflict) {
            const { response: { data } } = conflict as { response: { data: { errors?: string[], error?: string } } };
            modalService.showError(data.error || data.errors?.join(' ') || JSON.stringify(data));
            throw conflict;
        }
    }

    @action.bound
    async delete(jobDetails: JobSaveParametersModel) {
        if (!(await modalService.showConfirmation('Are you sure you want to delete this trigger?', { color: 'danger' }))) {
            return false;
        }

        const triggerId = (jobDetails && jobDetails.triggerName) || '';
        const jobName = (jobDetails && jobDetails.jobName) || '';
        const { data: job } = await ApiService.deleteData<ApiV1Job>(ApiUrls.JobTriggerDeleteUrl + '?triggerId=' + triggerId + '&jobName=' + jobName);
        this._job = job;
        this._props.onTriggerDeleted?.(job);
        return true;
    }

    @action
    async runTriggerInternal(params: JobParameterModel[], name: string) {
        this.errorToggle = false;
        const { data } = await ApiService.postTypedData<ApiV1Job>(ApiUrls.JobStartUrl + '/' + name, { parameters: params });

        this._job = data;
        this._props.onJobStarted?.(data);
    }

    @action.bound
    toggleDropdown() {
        this.isOpenDropdown = !this.isOpenDropdown;
    }
}

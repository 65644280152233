import React from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { FormFeedback } from 'reactstrap';
import { ActionMeta, GroupBase, MultiValueGenericProps, OnChangeValue, OptionProps, OptionsOrGroups, components } from 'react-select';

import ApiService from '@app/Services/ApiService';
import { MatchComparer, stringSearchService } from '@app/Services/StringSearchService';

export type AsyncServerSelectFilterBase = {
    search?: string;
};

type AsyncServerSelectProps<TOption, TFilter extends AsyncServerSelectFilterBase> = {
    url: string;
    keyField?: keyof TOption;
    labelField?: keyof TOption;
    getOptionLabel?: (option: TOption) => string;
    getOptionValue?: (option: TOption) => string;
    value?: TOption | TOption[] | null;
    isDisabled?: boolean;
    onChange?: (value?: TOption[] | TOption) => void;
    onSelect?: (value?: TOption | TOption[]) => void;
    multi?: boolean;
    filters?: TFilter;
    canClear?: boolean;
    validationErrors?: string[];
    className?: string;
};

@observer
export class AsyncServerSelect<TOption, TFilter extends AsyncServerSelectFilterBase> extends React.Component<AsyncServerSelectProps<TOption, TFilter>, {}> {
    @observable private _store: AsyncServerSelectStore<TOption, TFilter>;

    constructor(props: AsyncServerSelectProps<TOption, TFilter>) {
        super(props);
        makeObservable(this);
        this._store = new AsyncServerSelectStore<TOption, TFilter>(props.url, props.value, props.keyField, props.labelField, props.getOptionLabel, props.getOptionValue);
    }

    componentDidUpdate(prevProps: AsyncServerSelectProps<TOption, TFilter>) {
        if (prevProps.value !== this.props.value) {
            this._store.selected = this.props.value ?? [];
        }
    }

    render() {
        const { canClear, multi, className, validationErrors, isDisabled } = this.props;
        const classList = ['select-container'];
        if (validationErrors?.length) classList.push('is-invalid');
        if (className) classList.push(className);

        return (
            <div className="w-100">
                <AsyncPaginate<TOption, GroupBase<TOption>, unknown, boolean>
                    placeholder=""
                    backspaceRemovesValue={true}
                    closeMenuOnSelect={!multi}
                    isMulti={multi}
                    isDisabled={isDisabled}
                    hideSelectedOptions={false}
                    debounceTimeout={500}
                    loadOptions={this._onLoadOptions}
                    onChange={this._onChangeHandler}
                    onInputChange={this._onInputChangeHandler}
                    onMenuClose={this._onMenuClose}
                    getOptionLabel={(o) => this._store.getOptionLabel(o)}
                    getOptionValue={(o) => this._store.getOptionValue(o)}
                    tabSelectsValue={true}
                    escapeClearsValue={true}
                    className={classList.join(' ')}
                    classNamePrefix="select-filter"
                    isClearable={canClear ?? true}
                    value={this._store.selected}
                    inputValue={this._store.search}
                    components={{
                        Option: this.renderOption,
                        MultiValueLabel: this.renderMultiValueLabel
                    }}
                />
                {!!validationErrors?.length && <FormFeedback>{validationErrors && validationErrors.map((error: string) => <div key={error}>{error}</div>)}</FormFeedback>}
            </div>
        );
    }

    renderOption = (props: OptionProps<TOption>) => {
        const label = this._store.getOptionLabel(props.data);
        const highlightedSearchText = this._renderText(label, false);

        return <components.Option {...props}>{props.isSelected ? label : highlightedSearchText}</components.Option>;
    };

    renderMultiValueLabel = (props: MultiValueGenericProps<TOption>) => {
        return <components.MultiValueLabel {...props}>{props.data && this._store.getOptionValue(props.data)}</components.MultiValueLabel>;
    };

    @action.bound
    private _onChangeHandler(newValue: OnChangeValue<TOption, boolean>, actionMeta: ActionMeta<TOption>) {
        const selected = (Array.isArray(newValue) ? newValue : [newValue]) as TOption[];
        this._store.selected = selected;
        this.props.onChange?.(selected);

        if (!this.props.multi || actionMeta?.action === 'clear') {
            this.props.onSelect?.(selected);
        }
    }

    @action.bound
    private _onInputChangeHandler(search: string) {
        const modifiedInput = search.charAt(search.length - 1) === ',' ? '' : search;
        this._store.search = modifiedInput;
    }

    @action.bound
    private _onMenuClose() {
        this._store.search = '';

        if (this.props.multi) {
            this.props.onSelect?.(this._store.selected);
        }
    }

    @action.bound
    private _onLoadOptions(
        inputValue: string,
        options: OptionsOrGroups<TOption, GroupBase<TOption>>
    ): Promise<{
        options: OptionsOrGroups<TOption, GroupBase<TOption>>;
        hasMore?: boolean;
    }> {
        return this._store.loadOptions(inputValue, options, this.props);
    }

    @computed
    private get _searchTerms() {
        const searchStr = this._store.search;
        return searchStr.split(/[ ,]+/).filter((x) => !!x);
    }

    @action
    private _renderText(text: string | undefined, decorate?: boolean) {
        const comparator: MatchComparer = (v, t, s) => {
            return stringSearchService.prepareString(v)?.indexOf(stringSearchService.prepareString(t) ?? '', s) ?? -1;
        };
        const parts = stringSearchService.markMatches(text, this._searchTerms, comparator);
        return (
            <span style={{ textDecoration: decorate ? 'line-through' : undefined }}>
                {parts.map((p, index) =>
                    p.isMatch ? (
                        <span className="text-match" key={'t' + index}>
                            {`${p.text}`}
                        </span>
                    ) : (
                        p.text
                    )
                )}
            </span>
        );
    }
}

export class AsyncServerSelectStore<TOption, TFilter extends AsyncServerSelectFilterBase> {
    @observable private _url: string;
    @observable.ref public selected: TOption[] | TOption = [];
    @observable public search: string = '';

    @observable private _getOptionLabel?: (option: TOption) => string;
    @observable private _getOptionValue?: (option: TOption) => string;

    @observable private _keyField?: keyof TOption;
    @observable private _labelField?: keyof TOption;

    constructor(url: string, initial: TOption[] | TOption | undefined | null = undefined, keyField: keyof TOption | undefined = undefined, labelField: keyof TOption | undefined = undefined, getOptionLabel: ((option: TOption) => string) | undefined = undefined, getOptionValue: ((option: TOption) => string) | undefined = undefined) {
        makeObservable(this);

        if (initial) {
            this.selected = initial;
        }

        this._url = url;
        this._keyField = keyField;
        this._labelField = labelField;
        this._getOptionLabel = getOptionLabel;
        this._getOptionValue = getOptionValue;
    }

    @action
    public getOptionLabel(option: TOption) {
        if (this._labelField) {
            return option[this._labelField] as string;
        } else if (this._getOptionLabel) {
            return this._getOptionLabel(option);
        } else {
            throw Error('Failed to get option label');
        }
    }

    @action
    public getOptionValue(option: TOption) {
        if (this._keyField) {
            return option[this._keyField] as string;
        } else if (this._getOptionValue) {
            return this._getOptionValue(option);
        } else {
            throw Error('Failed to get option value');
        }
    }

    @action
    public async loadOptions(search: string, prevOptions: OptionsOrGroups<TOption, GroupBase<TOption>>, props: AsyncServerSelectProps<TOption, TFilter>): Promise<{ options: OptionsOrGroups<TOption, GroupBase<TOption>>; hasMore?: boolean }> {
        const pageSize = 30;
        const page = prevOptions.length % pageSize === 0 ? (prevOptions.length + pageSize) / pageSize : 1;

        const sendData: TFilter = props.filters ? Object.assign({}, props.filters) : ({} as TFilter);
        if (search) {
            sendData.search = search;
        }

        const params = {
            search: sendData.search,
            page: page,
            pageSize: pageSize
        };

        const { data } = await ApiService.postTypedData<TOption[]>(this._url, params);

        return {
            options: data,
            hasMore: data.length == pageSize
        };
    }
}

import { action, makeObservable, observable, transaction } from 'mobx';
import Axios, { CancelTokenSource } from 'axios';

import { PromiseCompletion } from '@app/Classes/PromiseCompletion';
import ApiService from '@app/Services/ApiService';
import { Range } from '../Classes/Range';
import { GanttMaintenance } from '../FlightsGanttTypes';

export default class MaintenanceStore<T extends GanttMaintenance> {
    @observable.shallow maintenances: Map<string, T> = new Map();
    private _cancellationSource: CancelTokenSource;
    private _loader: PromiseCompletion;
    private _url: string;

    constructor(url: string, loader: PromiseCompletion) {
        makeObservable(this);
        this._url = url;
        this._cancellationSource = Axios.CancelToken.source();
        this._loader = loader;
    }

    public cancel() {
        this._cancellationSource.cancel('Clear Flights');
        this._cancellationSource = Axios.CancelToken.source();
    }

    @action
    clear() {
        this.maintenances = new Map<string, T>();
        this.cancel();
    }

    @action.bound
    async update(range: Range<Date>, clearView: boolean) {
        const params = {
            dateFrom: range.from,
            dateTo: range.to
        };

        const { data: dailyData } = await ApiService.get<T[]>(this._url, params, {
            cancellationToken: this._cancellationSource.token,
            completion: this._loader
        });

        transaction(() => {
            if (clearView) {
                this.clear();
            }
            dailyData.forEach(item => {
                this.maintenances.set(item.id, item);
            });
        });
    }
}
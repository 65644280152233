import * as React from 'react';
import { observer } from 'mobx-react';

import { BufferItem } from '@app/Models/FlightScheduleModels';
import { PromiseCompletion } from '@classes/PromiseCompletion';
import { targetConstructor } from '@classes/TargetConstructor';
import { DateTimeService } from '@services/DateTimeService';
import { ClipboardService } from '@services/ClipboardService';

import { modalService } from '@components/Modal/Modal';
import ContextMenu, { ContextMenuItem } from '@components/ContextMenu';
import { NotificationHandler } from '@components/ToastNotification';
const FlightMessageHistoryDialog = React.lazy(() => import('./FlightMessageHistoryDialog'));

type FlightListContextMenuMenuProps = {
    container: React.RefObject<HTMLElement>;
    loader: PromiseCompletion;
    onRefresh: () => Promise<void>;
    onUpdateBuffer?: (buffer: BufferItem | null) => void;
};

@observer
export default class FlightListContextMenu extends React.Component<FlightListContextMenuMenuProps, {}> {
    render() {
        return (
            <ContextMenu container={this.props.container}>
                {(element: HTMLElement) => this._renderMenu(element)}
            </ContextMenu>
        );
    }

    private _renderMenu(element: HTMLElement): React.ReactElement<HTMLElement> | null {
        const targetFlightNode = element.closest('.aircraft-item');
        if (!targetFlightNode) return null;

        const flightId = targetFlightNode.getAttribute('data-flight-id');

        if (!flightId) return null;

        return (
            <>
                {this._renderCopyIdItem(flightId)}
                {this._renderServiceBusMessageHistoryItem(flightId)}
            </>
        );
    }

    private _renderServiceBusMessageHistoryItem(flightId: string) {
        return <ContextMenuItem onClick={() => this._showFlightServiceBusMessageHistory(flightId)} title="Service Bus Message History" />;
    }

    private _renderCopyIdItem(flightId: string) {
        return <ContextMenuItem onClick={() => this._copyToClipboard(flightId)} title="Copy ID" />;
    }

    private _showFlightServiceBusMessageHistory = async (flightId: string) => {
        const { airlineRcd, number: flightNumber, date } = targetConstructor.fromFlightId(flightId);
        const title = `Service Bus Message History / ${airlineRcd}-${flightNumber} / ${DateTimeService.toUiDate(date)}`;
        await modalService.show(FlightMessageHistoryDialog, {
            flightId: flightId,
            title
        }, {
            title: title
        });
    };

    private _copyToClipboard(text: string) {
        try {
            ClipboardService.copyToClipboard(text);
            NotificationHandler.showSuccess('Copied!');
        } catch (err) {
            NotificationHandler.showError(`Oops, unable to copy ${text}: ${err}`);
        }
    }
}

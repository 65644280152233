import * as React from 'react';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { ApiUrls } from '@app/AppConstants';
import { MessageStatisticsModel } from '@app/Models';

import { Timer } from '@services/Timer';
import ApiService from '@app/Services/ApiService';
import { DateTimeService } from '@services/DateTimeService';
import { Range } from '@helpers/RangeHelper';

import { Monitoring } from './Components/Monitoring';
import { SelfTestStatus } from './Components/SelfTestStatus/SelfTestStatus';
import { SelfTestQueue } from './Components/SelfTestQueue/SelfTestQueue';
import { RequestGraph } from './Components/RequestGraph';
import { SelfTestLogs } from './Components/SelfTestLogs/SelfTestLogs';

import './_health-board.scss';

@observer
export class HealthBoard extends React.Component {
    private _store: HealthBoardStore = new HealthBoardStore();

    constructor (props: {}) {
        super(props);
        makeObservable(this);
    }

    componentWillUnmount () {
        this._store.stopRefresh();
    }

    public render () {
        return (
            <div className="hb-container">
                <div className="title-row">
                    <div className="status-container">
                        <SelfTestStatus
                            url={ApiUrls.SelfTestJobsUrl}
                            icon="cogs"
                            fixUrl={ApiUrls.ServiceRestartSchedulerUrl}
                            fixName="Restart scheduler"
                        />
                        <SelfTestStatus url={ApiUrls.SelfTestSignalRUrl} icon="bolt"/>
                        <SelfTestStatus url={ApiUrls.SelfTestDatabaseUrl} icon="database"/>
                        <SelfTestQueue/>
                        <SelfTestLogs range={this._store.selectedRange}/>
                        <Monitoring/>
                    </div>
                </div>
                <div className="clearfix">
                    <div className="job-progress-node">
                        <RequestGraph healthBoardStore={this._store}/>
                    </div>
                </div>
            </div>
        );
    }
}

export class HealthBoardStore {
    @observable data: Array<MessageStatisticsModel> = [];
    @observable selectedRange: Range | null = null;
    public date: Date = DateTimeService.now();
    private _timer: Timer = new Timer();

    constructor () {
        makeObservable(this);
        this.initStore();
    }

    initStore () {
        this._timer.initRequestInterval(this.loadData);
    }

    stopRefresh () {
        this._timer.stop();
    }

    setRange (newRange: Range | null) {
        this.selectedRange = newRange;
    }

    async loadDataFromDate (dateTimeFrom: Date) {
        this.stopRefresh();
        void this.loadData(dateTimeFrom);
    }

    @action
    public loadData = async (dateTimeFrom?: Date) => {
        this.date = dateTimeFrom || DateTimeService.now();
        const params = dateTimeFrom ? { dateTimeFrom } : null;
        const { data } = await ApiService.getTypedData<Array<MessageStatisticsModel>>(ApiUrls.MessageStatisticsUrl, params);
        this.data = data || [];
    };
}

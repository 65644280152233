import * as React from 'react';
import { observer } from 'mobx-react';

import { DateTimeService } from '@app/Services/DateTimeService';
import { AircraftActivityDto, aircraftHeight, aircraftLineHeight, getDayWidth, DisplayAircraft, GantZoom, GanttFlight, GanttMaintenance, FlightRenderArgs, getFlightDateFrom, getFlightDateTo, getZoomMultiplier } from './FlightsGanttTypes';

type AircraftLineProps<TFlight extends GanttFlight, TMaintenance extends GanttMaintenance> = {
    aircraft: DisplayAircraft<TFlight, TMaintenance>;
    viewportDays: Array<Date>;
    selectedItemIds: string[];
    bufferedIds: string[];
    index: number;
    zoom: GantZoom;
    isActive: boolean;
    onSelect: (itemId: string, isCtrl: boolean) => void;
    onEnter: () => void;
    onLeave: () => void;
    onFlightRender: (e: FlightRenderArgs<TFlight>) => void;
};

@observer
export class AircraftLine<TFlight extends GanttFlight, TMaintenance extends GanttMaintenance> extends React.Component<AircraftLineProps<TFlight, TMaintenance>, {}> {

    render() {
        const { viewportDays, aircraft, bufferedIds, selectedItemIds, zoom, index, isActive } = this.props;
        const { onEnter, onLeave, onFlightRender } = this.props;

        const dayWidth = getDayWidth(zoom);
        const pixelInMinute = dayWidth / (24 * 60);
        const height = aircraftHeight + aircraftLineHeight * aircraft.lines.length;
        const style: React.CSSProperties = {
            height: height,
            width: viewportDays.length * dayWidth
        };

        if (!viewportDays.length) return null;
        const startDate = viewportDays[0];
        const endDate = DateTimeService.addDays(viewportDays[viewportDays.length - 1], 1);
        const now = DateTimeService.now();

        const rotations: JSX.Element[] = [];

        let lineNumber = 0;
        let itemNumber = 0;
        for (const line of aircraft.lines) {
            lineNumber++;

            for (const block of line.activityBlocks) {
                for (const item of block.items) {
                    itemNumber++;

                    const id = item.flight?.id ?? item.maintenance?.id ?? itemNumber.toString();

                    let text: string | null = null;
                    let aircraftItemClassName = 'aircraft-item';

                    let startUtc: Date;
                    let endUtc: Date;

                    const { flight, connector, maintenance } = item;
                    if (maintenance) {
                        startUtc = maintenance.dateTimeFromUtc;
                        endUtc = maintenance.dateTimeToUtc;

                        text = maintenance.title;
                        aircraftItemClassName += maintenance.isServiceFlight ? ' serviceflight' : ' maintenance';
                    } else if (flight) {
                        startUtc = getFlightDateFrom(flight);
                        endUtc = getFlightDateTo(flight);

                        text = `${flight.originRcd}-${flight.flightNumber}${flight.operationalSuffix ?? ''}${flight.legSequenceNumber > 1 ? `/${flight.legSequenceNumber}` : ''}-${flight.destinationRcd}`;

                        aircraftItemClassName += ' flight';
                        if (flight.originRcd === 'ZRH') {
                            aircraftItemClassName += ' outbound';
                        }

                        const renderArgs: FlightRenderArgs<TFlight> = {
                            flight: flight,
                            className: aircraftItemClassName
                        };
                        onFlightRender(renderArgs);
                        aircraftItemClassName = renderArgs.className;
                    } else if (connector) {
                        startUtc = connector.startUtc;
                        endUtc = connector.endUtc;

                        aircraftItemClassName += ' connector';
                    } else {
                        throw new Error('No data is defined in rotation block item');
                    }

                    if (startUtc > endDate || endUtc < startDate) {
                        continue;
                    }

                    if (bufferedIds.includes(id)) {
                        aircraftItemClassName += ' buffered';
                    }

                    if (selectedItemIds.includes(id)) {
                        aircraftItemClassName += ' selected';
                    }

                    if (startUtc.getTime() < startDate.getTime() && endUtc.getTime() > startDate.getTime()) {
                        startUtc = startDate;
                    }
                    if (endUtc.getTime() > endDate.getTime()) {
                        endUtc = endDate;
                    }

                    const left = DateTimeService.diffMinutes(startUtc, startDate) * pixelInMinute;
                    const width = DateTimeService.diffMinutes(endUtc, startUtc) * pixelInMinute;

                    if (width < 50 && flight) {
                        text = `${flight.flightNumber}${flight.operationalSuffix ?? ''}${flight.legSequenceNumber > 1 ? `/${flight.legSequenceNumber}` : ''}-${flight.destinationRcd}`;
                    }

                    const itemStyle: React.CSSProperties = {
                        width: width,
                        left: left,
                        marginTop: aircraftLineHeight * (lineNumber - 1)
                    };

                    const flightName = <span>{text}</span>;
                    rotations.push(
                        <div key={id} data-flight-id={flight?.id} className={aircraftItemClassName} style={itemStyle} onClick={(e) => this._onItemClick(e, item)} onContextMenu={(e) => this._onCallContextMenu(e, item)}>
                            {!!flight?.id && bufferedIds.includes?.(flight!.id) && (
                                <style>{`\
                                            .buffered:before{\
                                                animation-duration: ${10 * getZoomMultiplier(zoom)}s !important;\
                                            }\
                                        `}</style>
                            )}
                            {flightName}
                        </div>
                    );

                    if (flight) {
                        if (flight.outOfTheGateUtc) {
                            let actualEndTime = flight.intoTheGateUtc;

                            if (!actualEndTime) {
                                const plannedDuration = DateTimeService.diffMinutes(flight.arrivalDateTimeUtc, flight.departureDateTimeUtc);
                                const maxTime = DateTimeService.addTime(flight.outOfTheGateUtc, 0, plannedDuration * 2);

                                if (now <= maxTime) {
                                    actualEndTime = now;
                                }
                            }

                            // fix line display for old database
                            if (!actualEndTime) {
                                actualEndTime = flight.estimatedArrivalDateTimeUtc || flight.arrivalDateTimeUtc;
                            }

                            const left = DateTimeService.diffMinutes(flight.outOfTheGateUtc, startDate) * pixelInMinute;
                            const width = DateTimeService.diffMinutes(actualEndTime, flight.outOfTheGateUtc) * pixelInMinute;

                            const actualFlightStyle: React.CSSProperties = {
                                width: width,
                                left: left,
                                marginTop: aircraftLineHeight * (lineNumber - 1)
                            };

                            rotations.push(<div key={id + '_actual'} id={flight.id} className="flight-actuals" style={actualFlightStyle} />);
                        }

                        if (flight.offTheGroundUtc) {
                            let inAirEndTime = flight.onTheGroundUtc;

                            if (!inAirEndTime && flight.estimatedArrivalDateTimeUtc && flight.estimatedArrivalDateTimeUtc <= now) {
                                inAirEndTime = flight.estimatedArrivalDateTimeUtc;
                            }

                            if (!inAirEndTime) {
                                const plannedDuration = DateTimeService.diffMinutes(flight.arrivalDateTimeUtc, flight.departureDateTimeUtc);
                                const maxTime = DateTimeService.addTime(flight.offTheGroundUtc, 0, plannedDuration * 2);

                                if (now <= maxTime) {
                                    inAirEndTime = now;
                                }
                            }

                            if (!inAirEndTime) {
                                inAirEndTime = flight.arrivalDateTimeUtc;
                            }

                            const left = DateTimeService.diffMinutes(flight.offTheGroundUtc, startDate) * pixelInMinute;
                            const width = DateTimeService.diffMinutes(inAirEndTime, flight.offTheGroundUtc) * pixelInMinute;

                            const inAirflightStyle: React.CSSProperties = {
                                width: width,
                                left: left,
                                marginTop: aircraftLineHeight * (lineNumber - 1)
                            };

                            rotations.push(<div key={id + '_in_air'} className="flight-in-air" style={inAirflightStyle} />);
                        }
                    }
                }
            }
            if (startDate <= now && now <= endDate) {
                const lineWidth = 3;
                const left = DateTimeService.diffMinutes(now, startDate) * pixelInMinute - lineWidth / 2;
                rotations.push(<div key={'now_line_' + lineNumber} className="now-line" style={{ left: left, marginTop: aircraftLineHeight * (lineNumber - 1) }} />);
            }
        }

        let className = 'aircraft-lines';
        if (index % 2 === 1) {
            className += ' even';
        } else {
            className += ' odd';
        }

        if (isActive) {
            className += ' active';
        }

        return (
            <div className={className} style={style} onMouseEnter={() => onEnter()} onMouseLeave={() => onLeave()}>
                {rotations}
            </div>
        );
    }

    private _onCallContextMenu(e: React.MouseEvent, item: AircraftActivityDto<TFlight, TMaintenance>) {
        const isCtrl = e.ctrlKey;
        const multipleSelected = this.props.selectedItemIds.length >= 1 && isCtrl;
        if (!multipleSelected) {
            this._onItemClick(e, item);
        }
    }

    private _onItemClick(e: React.MouseEvent, item: AircraftActivityDto<TFlight, TMaintenance>) {
        const { onSelect } = this.props;
        const isCtrl = e.ctrlKey;
        if (item.flight) {
            onSelect(item.flight.id, isCtrl);
        }

        if (item.maintenance) {
            onSelect(item.maintenance.id, isCtrl);
        }
    }
}

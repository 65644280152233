import { computed, makeObservable } from 'mobx';
import { CompletionType, PromiseCompletion } from '@app/Classes/PromiseCompletion';

export type PromiseHandler<T> = (item: T) => PromiseLike<void>;
type ProgressHandler = (queueLength: number) => void;

export default class PromiseQueue<T>{
    private _queue: {
        item: T;
        onDone: Function;
        handler: PromiseHandler<T>;
    }[] = [];
    private _completion: PromiseCompletion = new PromiseCompletion(CompletionType.Pending);
    private _parallelTasksNumber: number = 0;
    private _maxParallelTasksNumber: number;
    private _waitHandlers: ProgressHandler[] = [];

    constructor(maxParallelTasksNumber: number = 1) {
        makeObservable(this);
        this._maxParallelTasksNumber = maxParallelTasksNumber;
    }

    public add(item: T, handler: PromiseHandler<T>) {
        const promise = new Promise<void>((resolve) => {
            this._queue.push({
                item: item,
                onDone: resolve,
                handler: handler
            });
        });
        this._completion.subscribe(promise);
        void this._processNextItem();
    }

    public async wait(callback?: ProgressHandler) {
        callback && this._waitHandlers.push(callback);
        try {
            await this._completion.wait();
        }
        finally {
            if (callback) {
                const handlerIndex = this._waitHandlers.indexOf(callback);
                if (handlerIndex !== -1) {
                    this._waitHandlers.splice(handlerIndex, 1);
                }
            }
        }
    }

    private _notifyProgress() {
        this._waitHandlers.forEach(h => h(this._queue.length + this._parallelTasksNumber));
    }

    @computed
    public get isPending() {
        return this._completion.isPending;
    }

    private async _processNextItem() {
        if (this._parallelTasksNumber >= this._maxParallelTasksNumber) {
            return;
        }

        const nextItem = this._queue.pop();
        if (!nextItem) return;

        this._parallelTasksNumber++;
        try {
            const handler = nextItem.handler;
            await handler(nextItem.item);
        }
        finally {
            this._parallelTasksNumber--;
            nextItem.onDone();
            this._notifyProgress();
            void this._processNextItem();
        }
    }
}
import { Range } from './Classes/Range';

export type GanttAircraft = {
    id: string;
    aircraftTypeRcd: string;
    registrationNumber: string;
};

export type GanttFlight = {
    id: string;

    airlineRcd: string;
    flightNumber: number;
    legSequenceNumber: number;
    operationalSuffix?: string;

    originRcd: string;
    destinationRcd: string;
    plannedAircraftTypeRcd: string;
    aircraftId?: string;

    status: string;
    statusIdentifier: string;
    statusSupplementaryInfo: string;
    statusChangeReason: string;
    statusActionIdentifier: string;

    outOfTheGateUtc?: Date;
    offTheGroundUtc?: Date;
    onTheGroundUtc?: Date;
    intoTheGateUtc?: Date;

    estimatedDepartureDateTimeUtc?: Date;
    estimatedArrivalDateTimeUtc?: Date;

    date: Date;

    departureDateTimeUtc: Date;
    departureDateTimeLt: Date;

    arrivalDateTimeUtc: Date;
    arrivalDateTimeLt: Date;

    isLongHaul: boolean;
    isTypeRatingExam: boolean;

    cabinCrewEmployer: string;
    cockpitCrewEmployer: string;

    rotationId?: string;
};

export type GanttMaintenance = {
    id: string;
    aircraftId: string;
    aircraftTypeRcd: string;
    dateTimeFromUtc: Date;
    dateTimeToUtc: Date;
    title: string;
    isServiceFlight: boolean;
};

export enum GantZoom {
    x05,
    x1,
    x2,
    x4,
    x8
}

export type RotationConnectionDto = {
    startUtc: Date;
    endUtc: Date;
};

export type AircraftActivityDto<TFlight extends GanttFlight, TMaintenance extends GanttMaintenance> = {
    flight?: TFlight;
    maintenance?: TMaintenance;
    connector?: RotationConnectionDto;
    isNextLine?: boolean;
};

export type ActivityBlockLineDto<TFlight extends GanttFlight, TMaintenance extends GanttMaintenance> = {
    items: AircraftActivityDto<TFlight, TMaintenance>[];
    lastItem: AircraftActivityDto<TFlight, TMaintenance>;
    startTime: Date;
    endTime: Date;
};

export type ActivityBlockDto<TFlight extends GanttFlight, TMaintenance extends GanttMaintenance> = {
    allItems: AircraftActivityDto<TFlight, TMaintenance>[];
    lines: ActivityBlockLineDto<TFlight, TMaintenance>[];
    startTime: Date;
};

export type AircraftLineDto<TFlight extends GanttFlight, TMaintenance extends GanttMaintenance> = {
    activityBlocks: {
        start: Date,
        end: Date,
        items: AircraftActivityDto<TFlight, TMaintenance>[]
    }[];
    filledTill: Date;
};

export type DisplayAircraft<TFlight extends GanttFlight, TMaintenance extends GanttMaintenance> = {
    id: string;
    aircraftTypeRcd: string;
    registrationNumber?: string;
    lines: AircraftLineDto<TFlight, TMaintenance>[];
    aircraftTypeGroupCount?: number;
};

export type FlightRenderArgs<TFlight extends GanttFlight> = {
    flight: TFlight;
    className: string;
    bufferedClassName?: string;
};

export enum NavigateBehavior {
    center,
    topLeft
}

export enum LoadMode {
    Viewport,
    Refresh,
    Clean
}

export type GanttViewState = {
    dateFrom: Date;
    dateTo: Date;
    viewportDateFrom: Date;
    viewportCenterRange?: Range<Date>;
    scrollTop?: number | null;
    scrollLeft?: number | null;
};

export const dayBaseWidth = 720;
export const aircraftHeight = 7; //paddings owned by aircraft, not lines
export const aircraftLineHeight = 23; //height owned by line including own padding/margin

export const getZoomMultiplier = (zoom: GantZoom) => {
    switch (zoom) {
        case GantZoom.x05:
            return 2;
        case GantZoom.x1:
            return 1;
        case GantZoom.x2:
            return 0.5;
        case GantZoom.x4:
            return 0.25;
        case GantZoom.x8:
            return 0.125;
    }

    throw new Error(`Zoom ${zoom} is not supported`);
};

export const getDayWidth = (zoom: GantZoom) => {
    return dayBaseWidth * getZoomMultiplier(zoom);
};

export const getFlightDateFrom = (flight: GanttFlight): Date => {
    const wrongEstimated = flight.estimatedDepartureDateTimeUtc && flight.estimatedArrivalDateTimeUtc && flight.estimatedDepartureDateTimeUtc > flight.estimatedArrivalDateTimeUtc;
    const canUseEstimated = flight.estimatedDepartureDateTimeUtc && !wrongEstimated;
    return (canUseEstimated ? flight.estimatedDepartureDateTimeUtc : flight.departureDateTimeUtc) as Date;
};

export const getFlightDateTo = (flight: GanttFlight): Date => {
    const wrongEstimated = flight.estimatedDepartureDateTimeUtc && flight.estimatedArrivalDateTimeUtc && flight.estimatedDepartureDateTimeUtc > flight.estimatedArrivalDateTimeUtc;
    const canUseEstimated = flight.estimatedArrivalDateTimeUtc && !wrongEstimated;
    const isReturn = flight.originRcd === flight.destinationRcd;
    return (isReturn ? flight.intoTheGateUtc : null) ?? (canUseEstimated ? flight.estimatedArrivalDateTimeUtc : flight.arrivalDateTimeUtc) as Date;
};

import * as signalR from '@microsoft/signalr';

import { ComponentUrls } from '@app/AppConstants';
import { appStore } from '@app/AppStore';
import { globalAjaxLoaderStore } from '@app/Stores/GlobalAjaxLoaderStore';
import ApiService from './ApiService';
import { HttpTransportType } from '@microsoft/signalr';
import { NotificationHandler } from '@components/ToastNotification';
import { SignalRConnectionWrapper } from '@app/Models';

type TPayloadEvent<T> = (eventName: string, payload: T) => void;
type TEvent = () => void;
type SignalREventHandler = (...args: any[]) => void;

interface ISignalRObservable {
    subscribeToGroup<T> (eventName: string, observer: TPayloadEvent<T>, groupName?: string, isPersonRelated?: boolean): void;

    unsubscribeFromGroup<T> (eventName: string, observer: TPayloadEvent<T>): void;
}

type SignalRObserver = {
    group?: SignalRGroup;
    eventName: string;
    observer: TEvent;
    internalObserver: SignalREventHandler;
};

type SignalRGroup = {
    name: string;
    isPersonRelated: boolean;
};

class SignalRService implements ISignalRObservable {
    private _hubConnection: signalR.HubConnection | null;
    private _signalRObservers: SignalRObserver[] = [];
    private _initObservers: TEvent[] = [];
    private _waitForConnectionPromise: Promise<void> | null;
    private _reconnectObservers: TEvent[] = [];
    private _reconnectTimeoutId?: number;
    private _queryStringAuthParams?: string;
    private _connectionWrapper: SignalRConnectionWrapper | null;

    constructor () {
        document.addEventListener('DOMContentLoaded', () => {
            void this.initialize();
        });
    }

    initialize = async () => {
        if (appStore.isAuthorized()) {
            this._queryStringAuthParams = appStore.getQueryStringAuthParams({ single: true, token: false });
            await this.setUpHub();
        }
    };

    async setUpHub (isReconnected?: boolean) {
        const connectionUrl = location.origin + ComponentUrls.HubUrl + appStore.getQueryStringAuthParams({ single: true });

        const transportType = HttpTransportType.LongPolling | HttpTransportType.ServerSentEvents | HttpTransportType.WebSockets;
        const hubConnection = new signalR.HubConnectionBuilder().withUrl(connectionUrl, {
            transport: transportType,
            accessTokenFactory: this._jwtTokenFactory
        }).build();

        const reconnect = () => {
            this._reconnectTimeoutId = window.setTimeout(() => {
                void this.setUpHub(true);
            }, 5000); // Restart connection after 5 seconds.
        };

        const oldConnectionWrapper = this._connectionWrapper;
        this._connectionWrapper = new SignalRConnectionWrapper(hubConnection, reconnect);

        try {
            await hubConnection.start();
            await oldConnectionWrapper?.abortConnection();
            this._hubConnection = hubConnection;
            this._reconnectTimeoutId && clearTimeout(this._reconnectTimeoutId);
            this._reconnectTimeoutId = undefined;
        } catch (e) {
            console.error(e);
            const loaderMessage = document.getElementById('react-app-loader-message');
            const error = e as Error;
            if (error && loaderMessage && !NotificationHandler.suppressError(error.message)) {
                loaderMessage.innerText = 'SignalR connection error\n' + error.message;
            }
            reconnect();
            return;
        }

        await Promise.all(globalAjaxLoaderStore.startupLoaders);
        globalAjaxLoaderStore.hideAppLoader();
        console.log('SingalR connected');

        await this._setupConnection(hubConnection);

        if (isReconnected) {
            this._onReconnect();
        }
    }

    private _jwtTokenFactory () {
        return new Promise<string>((resolve, reject) => {
            if (appStore.currentTokenObj) {
                const timeMsNow = Date.now() / 1000;
                if (appStore.currentTokenObj.exp < timeMsNow) {
                    reject(new Error(`Jwt token has expired at ${appStore.currentTokenObj.exp}, current time is ${timeMsNow}`));
                    return;
                }

                resolve(appStore.currentToken);
                return;
            }

            reject(new Error(`User isn't authorized, Jwt token is not defined`));
        });
    }

    private async _setupConnection (hubConnection: signalR.HubConnection) {
        try {
            this._initObservers.forEach((cb) => cb());

            appStore.signalRConnectionId = await hubConnection.invoke('GetConnectionId');
            // this.setupHeartbeat();

            const groups: SignalRGroup[] = [];
            this._signalRObservers.forEach((item) => {
                hubConnection.on(item.eventName, item.internalObserver);

                if (item.group && groups.findIndex((g) => g.name === item.group?.name && g.isPersonRelated === item.group.isPersonRelated) === -1) {
                    groups.push(item.group);
                }
            });

            let lastError: unknown = null;
            for (let i = 0; i < groups.length; i++) {
                try {
                    await hubConnection.invoke<void>('SubscribeToGroup', groups[i].name, groups[i].isPersonRelated);
                } catch (e) {
                    lastError = e;
                    console.error(e);
                }
            }

            if (lastError) throw lastError;
        } catch (e) {
            //no reason to drop onnection, dropping leads to uncontrolled reconnect in case of any issue in code, throw will cause error to be shown and error in JS logs
            console.error(e);
            throw e;
        }
    }

    onInitialized (cb: TEvent) {
        this._initObservers.push(cb);
        return () => (this._initObservers = this._initObservers.filter((sub) => sub !== cb));
    }

    setupHeartbeat () {
        const timeoutInSec = 90 + 30 * Math.random();
        window.setTimeout(this.updateHeartbeat, timeoutInSec * 1000);
    }

    updateHeartbeat = () => {
        if (this._hubConnection && this._hubConnection.state === signalR.HubConnectionState.Connected) {
            this._hubConnection.invoke('UpdateHeartbeat').catch((err: unknown) => console.error(err));
        }

        this.setupHeartbeat();
    };

    public sendNotification<T> (methodName: string, notification: T) {
        this._waitForConnection().then(() => {
            if (this._hubConnection && this._hubConnection.state === signalR.HubConnectionState.Connected) {
                this._hubConnection.invoke(methodName, notification).catch((err: unknown) => console.error(err));
            }
        }).catch((e) => {
            throw e;
        });
    }

    public subscribeToGroup<T> (eventName: string, observer: TPayloadEvent<T>, groupName?: string, isPersonRelated: boolean = false) {
        const signalRObserver: SignalRObserver = {
            group: groupName ? { name: groupName, isPersonRelated } : undefined,
            eventName: eventName,
            observer: observer as TEvent,
            internalObserver: (payload: T) => {
                observer(eventName, ApiService.typeCheck(payload));
            }
        };

        if (!signalRObserver.group) {
            this._addObserver(signalRObserver);
            return;
        }

        this._waitForConnection().then(() => {
            const hasGroupConnection = this._signalRObservers.findIndex((obs) => obs.group?.name === signalRObserver.group?.name && obs.group?.isPersonRelated === signalRObserver.group?.isPersonRelated) !== -1;
            if (!hasGroupConnection) {
                void this._hubConnection?.invoke('SubscribeToGroup', signalRObserver.group?.name, signalRObserver.group?.isPersonRelated);
            }

            this._addObserver(signalRObserver);
        }).catch((e) => {
            throw e;
        });
    }

    public unsubscribeFromGroup<T> (eventName: string, observer: TPayloadEvent<T>) {
        this._waitForConnection().then(() => {
            const signalRObserver = this._signalRObservers.find((obs) => obs.eventName === eventName && obs.observer === observer);
            if (!signalRObserver) {
                throw new Error(`Registration for ${eventName} is missing`);
            }

            if (!signalRObserver.group) {
                this._removeObserver(signalRObserver);
                return;
            }

            this._removeObserver(signalRObserver);

            const groupHasAnyObserver = this._signalRObservers.findIndex((obs) => obs.group?.name === signalRObserver.group?.name && obs.group?.isPersonRelated === signalRObserver.group?.isPersonRelated) !== -1;
            if (!groupHasAnyObserver) {
                void this._hubConnection?.invoke('UnsubscribeFromGroup', signalRObserver.group?.name, signalRObserver.group?.isPersonRelated);
            }
        }).catch((e) => {
            const signalRObserver = this._signalRObservers.find((obs) => obs.eventName === eventName && obs.observer === observer);
            signalRObserver && this._removeObserver(signalRObserver);
            throw e;
        });
    }

    private _addObserver (event: SignalRObserver) {
        this._hubConnection?.on(event.eventName, event.internalObserver);
        this._signalRObservers.push(event);
    }

    private _removeObserver (event: SignalRObserver) {
        this._hubConnection?.off(event.eventName, event.internalObserver);

        const index = this._signalRObservers.indexOf(event);
        if (index !== -1) {
            this._signalRObservers.splice(index, 1);
        }
    }

    public addReconnectObserver (cb: TEvent) {
        this._reconnectObservers.push(cb);
    }

    public removeReconnectObserver (cb: TEvent) {
        const index = this._reconnectObservers.indexOf(cb);

        if (index !== -1) {
            this._reconnectObservers.splice(index, 1);
        }
    }

    public async reinitOnObservedPersonChanged () {
        const newQueryParams = appStore.getQueryStringAuthParams({ single: true, token: false });
        if (this._queryStringAuthParams === newQueryParams) return;

        this._queryStringAuthParams = newQueryParams;
        if (appStore.isAuthorized()) {
            await this.setUpHub(true);
        }
    }

    private _onReconnect () {
        this._reconnectObservers.forEach((cb) => cb());
    }

    private _waitForConnection (timeout: number = 30000) {
        if (this._waitForConnectionPromise) return this._waitForConnectionPromise;

        this._waitForConnectionPromise = new Promise((resolve, reject) => {
            let checkIntervalId: number | undefined = undefined;

            const timeoutId = window.setTimeout(() => {
                window.clearInterval(checkIntervalId);
                this._waitForConnectionPromise = null;

                reject(new Error('Timeout waiting for signalR connection'));
            }, timeout);

            const checkConnection = () => {
                if (this._hubConnection && this._hubConnection.state === signalR.HubConnectionState.Connected) {
                    window.clearTimeout(timeoutId);
                    checkIntervalId && window.clearInterval(checkIntervalId);
                    this._waitForConnectionPromise = null;

                    resolve();
                    return true;
                }
                return false;
            };

            if (checkConnection()) return;
            checkIntervalId = window.setInterval(checkConnection, 50);
        });

        return this._waitForConnectionPromise;
    }
}

export const signalRService = new SignalRService();

import * as React from 'react';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

import { DateTime, ApiUrls } from '@app/AppConstants';
import { BaseFormModel } from '@services/BaseFormModel';

import { PromiseCompletion } from '@classes/PromiseCompletion';
import { isRequired, displayName, isDate } from '@services/Validation';
import { DateTimeService } from '@services/DateTimeService';
import ApiService from '@app/Services/ApiService';

import { DatePickerValidatedControl } from '@components/DateTimeControls/DateTimeControls';
import { ModalButtonType, IModalDialogContent, ModalWindow, ModalDialogOptions } from '@components/Modal/Modal';
import { getEncodedToken } from '@helpers/JWTHelper';
import { appStore } from '@app/AppStore';

export type DownloadDayLogDialogProps = {};

@observer
export class DownloadDayLogDialog extends React.Component<DownloadDayLogDialogProps> implements IModalDialogContent<void> {
    private _store: DownloadDayLogDialogStore;

    constructor (props: DownloadDayLogDialogProps) {
        super(props);
        makeObservable(this);
        this._store = new DownloadDayLogDialogStore();
    }

    public getModalOptions (window: ModalWindow<void>): ModalDialogOptions<void> {
        return {
            title: 'Select Date',
            buttons: [
                {
                    type: ModalButtonType.Cancel,
                    onClick: () => {
                        window.close();
                    }
                },
                {
                    type: ModalButtonType.Download,
                    onClick: async () => {
                        if (this._onSubmit()) {
                            window.close();
                        }
                    }
                }
            ],
            disableAutoFocus: true,
            loader: this._store.loader
        };
    }

    async componentDidMount () {
        await this._store.initStore();
    }

    render () {
        return (
            <>
                <div className="label-title">Date:</div>
                <DatePickerValidatedControl name="date" formModel={this._store.form} pickerSize="sm"
                                            availableDates={this._store.datesWithLogs}/>
            </>
        );
    }

    private _onSubmit () {
        if (!this._store.form.validate()) return false;
        const date = DateTimeService.parseUiDate(this._store.form.date);
        const encodedToken = getEncodedToken(appStore.currentToken);
        window.location.href = ApiUrls.ServiceDownloadDayLogsUrl + '?date=' + DateTimeService.format(date, DateTime.jsonDateFormat) + '&token=' + encodeURIComponent(encodedToken);

        return true;
    }
}

class FormModel extends BaseFormModel {
    @observable
    @isRequired()
    @isDate()
    @displayName('Date')
    date: string = '';

    constructor () {
        super();
        makeObservable(this);
    }

}

class DownloadDayLogDialogStore {
    @observable datesWithLogs: Date[] = [];
    form: FormModel = new FormModel();
    public loader: PromiseCompletion = new PromiseCompletion();

    constructor () {
        makeObservable(this);
    }

    async initStore () {
        await this.loadDatesWithLogs();
    }

    async loadDatesWithLogs () {
        const { data } = await ApiService.getTypedData<string[]>(ApiUrls.ServiceDatesWithLogsUrl, null, { completion: this.loader });
        this.datesWithLogs = data.map((date) => DateTimeService.fromString(date));
    }
}

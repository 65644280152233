import { createBrowserRouter, RouteObject } from 'react-router-dom';
import { BaseLayout } from '@components/BaseLayout';
import { HomePage } from '@pages/HomePage/HomePage';
import { HealthBoard } from '@pages/HealthBoard/HealthBoard';
import FlightSchedulePage from '@pages/FlightSchedulePage/FlightSchedulePage';
import JobList from '@pages/Job/JobList';

const routes = [
    {
        path: '/',
        element: <BaseLayout/>,
        children: [
            {
                path: '/',
                element: <HomePage/>,
            },
            {
                path: 'health-board',
                element: <HealthBoard/>,
            },
            {
                path: 'flight-schedule',
                element: <FlightSchedulePage />,
            },
            {
                path: 'jobs',
                element: <JobList />,
            },
        ],
    },
] as RouteObject[];

export const router = createBrowserRouter(routes);

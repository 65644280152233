import { useMemo, useRef } from "react";
import { observer } from "mobx-react";
import { FormFeedback } from "reactstrap";
import JoditEditor, { Jodit } from "jodit-react";
import { Config } from "jodit/config";

import { BaseFormModel } from "@app/Services/BaseFormModel";
import "./_text-editor.scss";

type TextEditorProps<T extends BaseFormModel> = {
	formModel: T;
	name: keyof T;
	isDisabled?: boolean;
	onChangeHandler: (html: string) => void;
};

export const TextEditor = observer(<T extends BaseFormModel>(props: TextEditorProps<T>) => {
	const { formModel, name, isDisabled, onChangeHandler, } = props;
	const editor = useRef<Jodit>(null);
	const fieldValue = formModel.getValue(name);

	const config: Partial<Config> = useMemo(
		() => ({
			readonly: isDisabled,
			minHeight: 120,
			maxHeight: 300,
			allowResizeX: false,
			allowResizeY: false,
			showPlaceholder: false,
			showCharsCounter: false,
			showWordsCounter: false,
			showXPathInStatusbar: false,
			toolbarAdaptive: false,
			disablePlugins: ["addNewLine", "enter"],
			enter: "div",
			buttons: [
				"bold",
				"italic",
				"underline",
				"ul",
				"ol",
				"table",
				"link",
			],
			controls: {
				ul: { list: undefined },
				ol: { list: undefined },
			},
		}),
		[isDisabled]
	);

	const onChange = (value: string) => {
		onChangeHandler(value);
	};

	return (
		<>
			<JoditEditor
				ref={editor}
				value={fieldValue as unknown as string}
				config={config}
				onChange={onChange}
			/>
			{formModel.validated &&
				formModel.invalidFields.includes(name as string) && (
					<FormFeedback style={{ display: "block" }}>
						{formModel.errorFor(name).map((error: string) => (
							<div key={(name as string) + error}>{error}</div>
						))}
					</FormFeedback>
				)}
		</>
	);
}
);

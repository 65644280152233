import * as React from 'react';
import { observer } from 'mobx-react';
import { action, computed, makeObservable, observable } from 'mobx';
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { Status, DateTime, ApiUrls } from '@app/AppConstants';

import { Timer } from '@services/Timer';
import ApiService from '@app/Services/ApiService';
import { DateTimeService } from '@services/DateTimeService';
import { DownloadService } from '@services/DownloadService';
import { CompletionType, PromiseCompletion } from '@classes/PromiseCompletion';
import { service } from '@services/_ServicesRegister';

import { Icon } from '@components/Icon';
import { ToolTipItem } from '@components/ToolTipItem';

import { SelfTestBadge } from '../SelfTestBadge/SelfTestBadge';

import './_self-test-queue.scss';

type SelfTestQueueResult = {
    queueName: string;
    messagesCount: number | null;
    deadletterMessagesCount: number | null;
    messagesPerMinuteIn: number;
    status: string;
    statusMessage: string;
};

@observer
export class SelfTestQueue extends React.Component {
    private _downloadService: DownloadService = service.resolve(DownloadService);

    readonly _store: SelfTestQueueStore;

    constructor (props: {}) {
        super(props);
        makeObservable(this);
        this._store = new SelfTestQueueStore();
    }

    componentWillUnmount () {
        this._store.stop();
    }

    downloadDeadletters = () => {
        const dateString = DateTimeService.toCustomUiFormat(DateTimeService.now(), DateTime.jsonDateFormat);
        const fileName = `Deadletters_${dateString}.json`;

        void this._downloadService.downloadFile(ApiUrls.ServiceDownloadDeadlettersUrl, null, fileName);
    };

    renderIcon (id: string) {
        const messagesCount = this._store.data?.messagesCount || 0;
        const deadletterMessagesCount = this._store.data?.deadletterMessagesCount || 0;
        const messagesPerMinuteIn = this._store.data?.messagesPerMinuteIn || 0;
        const queueName = this._store.data?.queueName;
        const statusMessage = this._store.data?.statusMessage;

        const messagesCountText = messagesCount === null ? '?' : messagesCount || 0;
        const deadletterMessagesCountText = deadletterMessagesCount === null ? '?' : deadletterMessagesCount || 0;

        const processingTimeHours = messagesCount === null || messagesPerMinuteIn === null ? '?' : Math.floor(messagesCount / messagesPerMinuteIn / 60) || 0;
        const processingTimeMinutes = messagesCount === null || messagesPerMinuteIn === null ? '?' : Math.floor((messagesCount / messagesPerMinuteIn) % 60) || 0;

        const detailed = messagesPerMinuteIn && messagesCountText
            ? ' / ' + messagesPerMinuteIn + 'mes/min / ' + +processingTimeHours + 'h' + ' ' + processingTimeMinutes + 'm'
            : '';
        const text = messagesCountText + ' / ' + deadletterMessagesCountText + detailed;
        const title = statusMessage ? statusMessage : '';

        return (
            <div className="icon-container">
                <Icon name={'envelope'}/>
                {queueName && <ToolTipItem text={queueName} targetId={id}/>}
                {this._store.dataLoading.isCompleted && (
                    <span className="job-queue-self-test-status" title={title}>
                        {text}
                    </span>
                )}
            </div>
        );
    }

    render () {
        return <SelfTestBadge>{this._store.data?.deadletterMessagesCount ? this._renderDropDown() : this._renderButton()}</SelfTestBadge>;
    }

    private _renderDropDown () {
        const { color, isDropdownOpen, toggleDropdown } = this._store;
        const id = 'self-test-queue';
        return (
            <ButtonDropdown isOpen={isDropdownOpen} toggle={toggleDropdown} size="sm">
                <DropdownToggle caret color={color} id={id} disabled={!this._store.dataLoading.isCompleted}>
                    {this.renderIcon(id)}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={this.downloadDeadletters}>Download deadletters (top 100)</DropdownItem>
                </DropdownMenu>
            </ButtonDropdown>
        );
    }

    private _renderButton () {
        const { color } = this._store;
        const id = 'self-test-queue';
        return (
            <Button id={id} size="sm" className="self-test-btn badge-btn" color={color}
                    disabled={!this._store.dataLoading.isCompleted}>
                {this.renderIcon(id)}
            </Button>
        );
    }
}

class SelfTestQueueStore {
    @observable public data: SelfTestQueueResult | null = null;
    @observable public isDropdownOpen: boolean = false;
    @observable public dataLoading: PromiseCompletion = new PromiseCompletion(CompletionType.Completed);
    private _timer: Timer = new Timer();

    constructor () {
        makeObservable(this);
        this._timer.initRequestInterval(this.loadData);
    }

    @computed
    get color (): string {
        if (!this.dataLoading.isCompleted) return 'secondary';

        let result = 'danger';

        if (!this.data) return result;

        switch (this.data.status) {
            case Status.success:
                result = 'success';
                break;
            case Status.warning:
                result = 'warning';
                break;
        }

        if (this.data.deadletterMessagesCount && this.data.deadletterMessagesCount > 0) return 'danger';

        return result;
    }

    public stop () {
        this._timer.stop();
    }

    @action.bound
    public toggleDropdown () {
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    @action.bound
    public async loadData () {
        const dataRequest = ApiService.postTypedData<SelfTestQueueResult>(ApiUrls.ServiceQueueStatisticsUrl, null);
        this.dataLoading.subscribe(dataRequest);

        this.data = (await dataRequest).data;
    }
}

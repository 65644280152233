import { action, makeObservable, observable } from 'mobx';
import Axios from 'axios';

import { DateTimeService } from '@app/Services/DateTimeService';

import { Range, RangeReadyStatus } from './Range';

type RangeStatus<T> = {
    range: Range<T>;
    status: RangeReadyStatus;
    timestamp: Date;
};

export type RangeLoadHandler<T> = (range: Range<T>, clearView: boolean) => Promise<void>;

abstract class RangeStore<T> {
    @observable protected _rangeStatuses: RangeStatus<T>[] = [];
    protected _rangeLoadHandler: RangeLoadHandler<T>;
    protected _onChange?: Function;
    protected _keepDataUntilReadyPromiseResolve: ((value: unknown) => void) | null = null;

    constructor(rangeLoadHandler: RangeLoadHandler<T>, onChange?: Function) {
        makeObservable(this);
        this._rangeLoadHandler = rangeLoadHandler;
        this._onChange = onChange;
    }

    public abstract loadRange(range: Range<T>, fullRange: Range<T>, viewportDatesCount: number): Promise<void>;

    public abstract reloadRange(range: Range<T>): Promise<void>;

    public abstract getStatus(at: T): RangeReadyStatus;

    public clear(keepDataUntilReady?: boolean) {
        this._rangeStatuses.length = 0;
        this._keepDataUntilReadyPromiseResolve = null;
        if (!keepDataUntilReady) {
            return Promise.resolve();
        }

        return new Promise((resolve) => {
            this._keepDataUntilReadyPromiseResolve = resolve;
        });
    }
}

export class DateRangeStore extends RangeStore<Date> {
    public async loadRange(range: Range<Date>, fullRange: Range<Date>, viewportDatesCount: number = 1): Promise<void> {
        this._validateRange(range);

        let minRequiredDate: Date | null = null;
        let maxRequiredDate: Date | null = null;

        let daysBack = 0;
        let daysForward = 0;
        const requiredDaysBack: Date[] = [];
        const requiredDaysForward: Date[] = [];
        const prefetchDates = 2 * viewportDatesCount;

        let dateFrom = DateTimeService.addDays(range.from, -prefetchDates / 2);
        let dateTo = DateTimeService.addDays(range.to, prefetchDates / 2);

        if (dateFrom < fullRange.from) {
            dateFrom = fullRange.from;
        }
        if (dateTo > fullRange.to) {
            dateTo = fullRange.to;
        }

        const dateFromWithFullPrefetch = DateTimeService.addDays(dateFrom, -prefetchDates / 2);
        const dateToWithFullPrefetch = DateTimeService.addDays(dateTo, prefetchDates / 2);

        for (let date = dateFromWithFullPrefetch; date <= dateToWithFullPrefetch; date = DateTimeService.addDays(date, 1)) {
            if (date < dateFrom) {
                if (this._isLoadingRequired(date)) {
                    daysBack++;
                    requiredDaysBack.push(date);
                }
                continue;
            }

            if (date > dateTo) {
                if (this._isLoadingRequired(date)) {
                    daysForward++;
                    requiredDaysForward.push(date);
                }
                continue;
            }

            if (this._isLoadingRequired(date)) {
                if (!minRequiredDate) {
                    minRequiredDate = date;
                }
                maxRequiredDate = date;
            }
        }

        if (minRequiredDate && maxRequiredDate) {
            if (daysBack === prefetchDates / 2) {
                minRequiredDate = dateFrom;
            } else if (daysBack > 0 && !this._isLoadingRequired(dateFromWithFullPrefetch)) {
                requiredDaysBack.forEach(async (date) => {
                    await this.reloadRange({ from: date, to: date });
                });
            }

            if (daysForward === prefetchDates / 2) {
                maxRequiredDate = dateTo;
            } else if (daysForward > 0 && !this._isLoadingRequired(dateToWithFullPrefetch)) {
                requiredDaysForward.forEach(async (date) => {
                    await this.reloadRange({ from: date, to: date });
                });
            }

            await this.reloadRange({ from: minRequiredDate, to: maxRequiredDate });
        }
    }

    public async reloadRange(range: Range<Date>): Promise<void> {
        this._validateRange(range);
        const statusRange = this._setLoadingStatus(range);
        try {
            const resolvePromise = this._keepDataUntilReadyPromiseResolve;
            this._keepDataUntilReadyPromiseResolve = null;
            await this._rangeLoadHandler(range, !!resolvePromise);
            this._setReadyStatus(statusRange, RangeReadyStatus.Ready);
            this._onChange?.();
            resolvePromise?.(void 0);
        } catch (ex) {
            if (Axios.isCancel(ex)) {
                this._setReadyStatus(statusRange, RangeReadyStatus.None);
                return;
            }

            this._setReadyStatus(statusRange, RangeReadyStatus.Error);
            throw ex;
        }
    }

    public getStatus(at: Date): RangeReadyStatus {
        let maxStatus = RangeReadyStatus.None;
        const rangeStatuses = this._rangeStatuses;
        for (let i = 0; i < rangeStatuses.length; i++) {
            const rangeStatus = rangeStatuses[i];
            if (rangeStatus.range.from <= at && at <= rangeStatus.range.to) {
                maxStatus = maxStatus < rangeStatus.status ? rangeStatus.status : maxStatus;
            }
        }

        return maxStatus;
    }

    @action.bound
    private _setLoadingStatus(range: Range<Date>) {
        const result = {
            range: range,
            status: RangeReadyStatus.Loading,
            timestamp: DateTimeService.now()
        };
        this._rangeStatuses.push(result);
        return this._rangeStatuses[this._rangeStatuses.length - 1];
    }

    @action.bound
    private _setReadyStatus(rangeStatus: RangeStatus<Date>, status: RangeReadyStatus) {
        if (this._rangeStatuses.indexOf(rangeStatus) === -1) {
            return;
        }
        rangeStatus.status = status;
        for (let i = this._rangeStatuses.length - 1; i >= 0; i--) {
            const range = this._rangeStatuses[i];
            const isThisRange = rangeStatus === range;
            if (isThisRange || (range.status !== RangeReadyStatus.Ready && range.status !== RangeReadyStatus.Error)) {
                continue;
            }
            if (rangeStatus.range.to >= range.range.from && rangeStatus.range.from <= range.range.to) {
                if (range.status === rangeStatus.status) {
                    rangeStatus.range.from = DateTimeService.min(range.range.from, rangeStatus.range.from);
                    rangeStatus.range.to = DateTimeService.max(range.range.to, rangeStatus.range.to);
                    rangeStatus.timestamp = DateTimeService.max(range.timestamp, rangeStatus.timestamp);
                    this._rangeStatuses.splice(i, 1);
                    continue;
                }

                //cut existing range left
                if (range.range.to > rangeStatus.range.from) {
                    range.range.to = DateTimeService.addDays(rangeStatus.range.from, -1);
                    if (range.range.to <= range.range.from) {
                        this._rangeStatuses.splice(i, 1);
                        continue;
                    }
                }

                //cut existing range right
                if (range.range.from < rangeStatus.range.to) {
                    range.range.from = DateTimeService.addDays(rangeStatus.range.to, 1);
                    if (range.range.to <= range.range.from) {
                        this._rangeStatuses.splice(i, 1);
                        continue;
                    }
                }
            }
        }
    }

    private _isLoadingRequired(date: Date) {
        let isLoadingNeeded = true;
        const ranges = this._rangeStatuses;

        for (let i = 0; i < ranges.length; i++) {
            const statusRange = ranges[i];
            if (statusRange.range.from <= date && date <= statusRange.range.to) {
                if (statusRange.status === RangeReadyStatus.Loading) {
                    isLoadingNeeded = false;
                    break;
                }
                if (statusRange.status === RangeReadyStatus.Ready) {
                    isLoadingNeeded = false;
                    break;
                }
            }
        }

        return isLoadingNeeded;
    }

    private _validateRange(range: Range<Date>) {
        if (range.from > range.to) {
            throw new Error(`Range is not valid, from ${range.from} must be less or equal to to ${range.to}`);
        }
    }
}

export class Status {
    public static readonly success: string = 'Success';
    public static readonly warning: string = 'Warning';
    public static readonly fail: string = 'Fail';
}

export class MessageQueueItemDirectionType {
    public static readonly In: string = 'In';
    public static readonly Out: string = 'Out';
    public static readonly DeadletterToInbox: string = 'DeadletterToInbox';
}

export class KeyboardCodes {
    public static readonly ENTER: string = 'Enter';
    public static readonly ESCAPE: string = 'Escape';
    public static readonly BACKSPACE: string = 'Backspace';
}

export class PrincipalConfig {
    public static readonly FlightsGantGroup: string = 'flightsGantGroup';
    public static readonly ReportGridStateKey: string = 'reportGridState';
}

export class SignalREvents {
    public static readonly jobStatusChanged: string = 'jobStatusChanged';
    public static readonly jobProgressMaxValueChanged: string = 'jobProgressMaxValueChanged';
    public static readonly jobProgressValueChanged: string = 'jobProgressValueChanged';
    public static readonly sendJobTimeline: string = 'sendJobTimeline';
}

export class ParameterType {
    public static readonly DateTime: string = 'DateTime';
    public static readonly Date: string = 'Date';
    public static readonly Boolean: string = 'Boolean';
    public static readonly MultiLine: string = 'Multiline';
    public static readonly SingleLine: string = 'String';
    public static readonly Integer: string = 'Integer';
    public static readonly Enum: string = 'Enum';
}

export class Aircraft {
    public static readonly Types: string[] = ['A320P', 'A320S', 'A340']
}
// tslint:disable:max-file-line-count
import React from 'react';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import ReactResizeDetector from 'react-resize-detector';

import ApiService from '@app/Services/ApiService';
import { DateTimeService } from '@services/DateTimeService';
import { PromiseCompletion } from '@classes/PromiseCompletion';
import { extensions } from '@services/Extensions';
import ArrayHelper from '@helpers/ArrayHelper';
import { KeyboardCodes } from '@app/AppConstants';
import { DateRangeStore } from './Classes/RangeStore';
import FlightStore from './Stores/FlightsStore';
import MaintenanceStore from './Stores/MaintenanceStore';

import { Range } from './Classes/Range';
import { TimeoutTimer } from './Controls/TimeoutTimer';
import { Loading } from '@components/Loading/Loading';
import { ToolTipItem } from '@components/ToolTipItem';
import { VirtualHorizontalExpander } from './VirtualHorizontalExpander';
import { VirtualVerticalExpander } from './VirtualVerticalExpander';
import { ActivityBlockDto, ActivityBlockLineDto, AircraftActivityDto, aircraftHeight, AircraftLineDto, aircraftLineHeight, DisplayAircraft, FlightRenderArgs, GanttAircraft, GanttFlight, GanttMaintenance, GanttViewState, GantZoom, getDayWidth, getFlightDateFrom, getFlightDateTo, LoadMode, NavigateBehavior } from './FlightsGanttTypes';
import { VirtualDaysList } from './VirtualDaysList';
import { AircraftListItem } from './AircraftListItem';
import { AircraftLine } from './AircraftLine';
import "./gantt-view.scss";

export type GantViewOnSelectedEvent<TFlight extends GanttFlight, TAircraft extends GanttAircraft, TMaintenance extends GanttMaintenance> = {
    activeItemId: string | null;
    selectedItemIds: string[];
    selectedFlights: TFlight[];
    selectedMaintenances: TMaintenance[];
    selectedAircrafts: TAircraft[];
};

type ScrollHandler = (scrollTop: number, scrollLeft: number) => void;
export type RenderExtendedControlsArgs = {
    container: React.RefObject<HTMLElement>;
    loader: PromiseCompletion;
};

export type RenderCaptionControlsArgs = {
    refreshButton: () => JSX.Element;
};

enum UpdateStatuses {
    None,
    Pending,
    Ready,
    NeedToUpdate
}

type FlightsGanttViewProps<TFlight extends GanttFlight, TAircraft extends GanttAircraft, TMaintenance extends GanttMaintenance> = {
    className?: string;
    viewState: GanttViewState;
    filterText?: string;
    showInactive?: boolean;
    initialZoom: GantZoom;
    allowMultiSelect?: boolean;
    aircraftsUrl: string;
    flightsUrl: string;
    bufferedIds: string[];
    maintenancesUrl: string;
    onRenderFilter?: () => JSX.Element;
    onSelected: (e: GantViewOnSelectedEvent<TFlight, TAircraft, TMaintenance>) => void;
    onZoom?: (e: GantZoom) => void;
    onScroll?: ScrollHandler;
    onFlightRender: (e: FlightRenderArgs<TFlight>) => void;
    onRenderExtendedControls?: (args: RenderExtendedControlsArgs) => JSX.Element | null;
    onRenderCaptionControls?: (args: RenderCaptionControlsArgs) => JSX.Element;
    onFlightMatch: (flight: TFlight, filter: string) => boolean;
};

@observer
export default class FlightsGanttView<TFlight extends GanttFlight = GanttFlight, TAircraft extends GanttAircraft = GanttAircraft, TMaintenance extends GanttMaintenance = GanttMaintenance> extends React.Component<FlightsGanttViewProps<TFlight, TAircraft, TMaintenance>> {
    private _loader: PromiseCompletion = new PromiseCompletion();
    private _flightRangeStore: DateRangeStore;
    private _maintenanceRangeStore: DateRangeStore;
    private _flightStore: FlightStore<TFlight>;
    private _maintenanceStore: MaintenanceStore<TMaintenance>;

    @observable.ref _aircrafts: TAircraft[] = [];
    @observable.ref _displayAircrafts: DisplayAircraft<TFlight, TMaintenance>[] = [];
    @observable.ref _viewportAircrafts: DisplayAircraft<TFlight, TMaintenance>[] = [];

    @observable private _zoom: GantZoom;
    @observable private _dateFrom: Date;
    @observable private _dateTo: Date;
    @observable private _viewportDateFrom: Date | null = null;
    @observable private _viewportDateTo: Date | null = null;
    @observable.shallow private _viewportDays: Array<Date> = [];
    @observable private _viewportAircraftIndexFrom: number = -1;
    @observable private _viewportAircraftIndexTo: number = -1;
    private _scrollTop: number = 0;
    private _scrollLeft: number = 0;
    private _updateStatus: UpdateStatuses = UpdateStatuses.None;
    @observable private _autoExpandTimeout: number | null = null;
    @observable private _autoExpandCssClass: string = '';
    @observable private _scheduledRestoreScroll: { top: number | null; left: number | null } | null = null;
    @observable private _selectedItemIds: string[] = [];
    @observable private _selectedAircraftIds: string[] = [];
    @observable private _scheduleEnsureRange: { range: Range<Date>; alwaysCenter?: boolean | null } | null = null;
    private _refreshInterval: number | null = null;
    private _timeout: number | null = null;
    @observable private _activeAircraftId: string | null = null;

    //browser hover might be lost in case of shift of view before DOM is finally updated.
    //We first scroll and then update DOM that generates a "bad" times fot hit test, so this is to avoid loosing "hovered" state on line
    private _blockLineMouseLeave: boolean = false;

    private readonly _datesList: React.RefObject<HTMLDivElement> = React.createRef();
    @observable private _aircraftsList: React.RefObject<HTMLDivElement> = React.createRef();
    @observable private _scrollWrapper: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: FlightsGanttViewProps<TFlight, TAircraft, TMaintenance>) {
        super(props);
        makeObservable(this);
        const { viewState, flightsUrl, initialZoom } = props;

        this._zoom = initialZoom;
        this._restoreViewState(
            viewState ?? {
                dateFrom: DateTimeService.addDays(DateTimeService.today(), -5),
                dateTo: DateTimeService.addDays(DateTimeService.today(), 5),
                viewportDateFrom: DateTimeService.today()
            }
        );

        this._flightStore = new FlightStore(this._loader, flightsUrl, (flightId) => {
            if (this._selectedItemIds.indexOf(flightId) !== -1) {
                this._notifySelectionChanged();
            }
        });
        this._flightRangeStore = new DateRangeStore((range: Range<Date>, clearView: boolean) => {
            return this._flightStore.update(range, clearView);
        }, this._rebuildRotationBlocks);

        this._maintenanceStore = new MaintenanceStore(this.props.maintenancesUrl, this._loader);
        this._maintenanceRangeStore = new DateRangeStore((range: Range<Date>, clearView: boolean) => {
            return this._maintenanceStore.update(range, clearView);
        }, this._rebuildRotationBlocks);
    }

    componentDidMount() {
        document.addEventListener('keydown', this._onKeyDown);
        void this._loadAircrafts();
        const time = DateTimeService.now();
        const secondsRemaining = (60 - time.getSeconds()) * 1000;

        this._timeout = window.setTimeout(() => {
            this._refreshToday();
            this._refreshInterval = window.setInterval(() => {
                this._refreshToday();
            }, 1000 * 60);
        }, secondsRemaining);
    }

    private _refreshToday() {
        const today = DateTimeService.today();
        if (this._flightStore.hasDate(today)) {
            this._rebuildRotationBlocks();
            this._flightStore.update(
                {
                    from: today,
                    to: today
                },
                false
            );
        }
    }

    componentDidUpdate(oldProps: FlightsGanttViewProps<TFlight, TAircraft, TMaintenance>) {
        this._updateStatus = UpdateStatuses.Pending;
        if (oldProps.showInactive !== this.props.showInactive || oldProps.filterText !== this.props.filterText) {
            this._rebuildRotationBlocks();
        }
        if (oldProps.flightsUrl !== this.props.flightsUrl) {
            this._flightStore.updateUrl(this.props.flightsUrl);
            this._flightRangeStore.clear(true);
            this._rebuildRotationBlocks();
        }
        if (oldProps.initialZoom !== this.props.initialZoom) {
            this._restoreViewState(this.props.viewState!);
        }

        this._updateStatus = UpdateStatuses.Ready;

        const scrollWrapper = this._scrollWrapper.current;

        if (scrollWrapper && this._scheduleEnsureRange) {
            this.ensureRangeVisible(this._scheduleEnsureRange.range, this._scheduleEnsureRange.alwaysCenter);
            this._scheduleEnsureRange = null;
        }

        if (scrollWrapper && this._scheduledRestoreScroll) {
            const { top, left } = this._scheduledRestoreScroll;
            this._scrollGrid(top, left);
            this._scheduledRestoreScroll = null;
        }
        this._positionVirtualScrolls();

        //we have to wait a little bit for browser to calculate hit's, otherwise we may loose "hover" state on line
        setTimeout(() => {
            this._blockLineMouseLeave = false;
        }, 0);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._onKeyDown);

        if (this._refreshInterval) {
            window.clearInterval(this._refreshInterval);
            this._refreshInterval = null;
        }
        if (this._timeout) {
            window.clearTimeout(this._timeout);
            this._timeout = null;
        }
    }

    render() {
        const { className, filterText, onRenderCaptionControls } = this.props;

        let wrapperClassName = 'virtualized-flight-schedule';
        if (className) {
            wrapperClassName += ' ' + className;
        }

        let zoom: string = '1';
        let canZoomIn = true;
        let canZoomOut = true;
        switch (this._zoom) {
            case GantZoom.x05:
                wrapperClassName += ' zoom-x05';
                zoom = '0.5x';
                canZoomIn = false;
                break;
            case GantZoom.x1:
                wrapperClassName += ' zoom-x1';
                zoom = '1x';
                break;
            case GantZoom.x2:
                wrapperClassName += ' zoom-x2';
                zoom = '2x';
                break;
            case GantZoom.x4:
                wrapperClassName += ' zoom-x4';
                zoom = '4x';
                break;
            case GantZoom.x8:
                wrapperClassName += ' zoom-x8';
                zoom = '8x';
                canZoomOut = false;
                break;
        }

        if (filterText) {
            wrapperClassName += ' filtered';
        }

        const renderRefreshButton = () => {
            const id = 'refreshButton';

            return (
                <>
                    <ToolTipItem targetId={id} text="Refresh" placement="top" />

                    <div id={id} className="button button-refresh" onClick={this.refresh}>
                        <svg fill="#888" baseProfile="tiny" height="24px" id="Layer_1" version="1.2" viewBox="0 0 24 24" width="24px">
                            <g>
                                <path
                                    d="M12.872,13.191H18V8.064c-0.008-1.135-0.671-1.408-1.473-0.605l-1.154,1.158c-1.015-0.795-2.257-1.23-3.566-1.23
                            c-1.55,0-3.009,0.604-4.104,1.701C6.604,10.18,6,11.641,6,13.191c0,1.553,0.604,3.012,1.701,4.107
                            C8.798,18.395,10.256,19,11.807,19c1.55,0,3.009-0.605,4.106-1.703c0.296-0.297,0.558-0.621,0.78-0.965
                            c0.347-0.541,0.19-1.26-0.35-1.605c-0.539-0.346-1.258-0.189-1.604,0.35c-0.133,0.207-0.292,0.4-0.468,0.58
                            c-0.659,0.658-1.534,1.02-2.464,1.02c-0.93,0-1.805-0.361-2.464-1.02c-0.657-0.658-1.02-1.533-1.02-2.465
                            c0-0.93,0.362-1.805,1.02-2.461c0.659-0.658,1.534-1.021,2.464-1.021c0.688,0,1.346,0.201,1.909,0.572l-1.448,1.451
                            C11.465,12.535,11.738,13.191,12.872,13.191z"
                                />
                            </g>
                        </svg>
                    </div>
                </>
            );
        };

        return (
            <div className={wrapperClassName}>
                <div className="top-left-caption">{onRenderCaptionControls ? onRenderCaptionControls({ refreshButton: renderRefreshButton }) : renderRefreshButton()}</div>
                <div className="days-list" ref={this._datesList}>
                    {this._renderDays()}
                </div>
                {
                    <div className="aircraft-list" ref={this._aircraftsList}>
                        {this._renderAircrafts()}
                    </div>
                }
                {this._renderGrid()}
                {this._autoExpandTimeout && <TimeoutTimer className={this._autoExpandCssClass} />}
                {<Loading className="gant-loader" small loading={this._loader.isPending} />}
                {this._renderControls(canZoomIn, canZoomOut, zoom)}
            </div>
        );
    }

    private _renderControls(canZoomIn: boolean, canZoomOut: boolean, zoom: string) {
        const { onRenderFilter, filterText } = this.props;
        return (
            <>
                {!!onRenderFilter && (
                    <>
                        <div className={'filter-wrapper' + (filterText ? ' active' : '')}>{onRenderFilter()}</div>
                    </>
                )}
                <div className="zoom-wrapper">
                    <div className={'zoom-out' + (canZoomOut ? '' : ' disabled')} onClick={() => this._onZoomChange(false)}>
                        <svg baseProfile="tiny" height="24px" version="1.2" viewBox="0 0 24 24" width="24px">
                            <path fill="#888" d="M18,11H6c-1.104,0-2,0.896-2,2s0.896,2,2,2h12c1.104,0,2-0.896,2-2S19.104,11,18,11z" />
                        </svg>
                    </div>
                    <div className="zoom-actual">
                        <span>{zoom}</span>
                    </div>
                    <div className={'zoom-in' + (canZoomIn ? '' : ' disabled')} onClick={() => this._onZoomChange(true)}>
                        <svg baseProfile="tiny" height="24px" version="1.2" viewBox="0 0 24 24" width="24px">
                            <path fill="#888" d="M18,10h-4V6c0-1.104-0.896-2-2-2s-2,0.896-2,2l0.071,4H6c-1.104,0-2,0.896-2,2s0.896,2,2,2l4.071-0.071L10,18  c0,1.104,0.896,2,2,2s2-0.896,2-2v-4.071L18,14c1.104,0,2-0.896,2-2S19.104,10,18,10z" />
                        </svg>
                    </div>
                </div>
            </>
        );
    }

    private _renderDays() {
        const daysStyle: React.CSSProperties = {
            transform: `${this._translateLeft}`
        };
        return <VirtualDaysList style={daysStyle} days={this._viewportDays} virtualWidth={this._virtualWidth} zoom={this._zoom} scrollLeft={this._scrollLeft} />;
    }

    private _renderAircrafts(): JSX.Element | null {
        if (!this._viewportAircrafts.length) {
            return null;
        }

        const personItemStyle: React.CSSProperties = {
            transform: `${this._translateTop}`
        };
        const aircrafts: JSX.Element[] = [];
        const aircraftsTypes: JSX.Element[] = [];

        let aircraftTypeGroup = '';
        let groupHeight = 0;

        for (let i = this._viewportAircraftIndexFrom; i <= this._viewportAircraftIndexTo; i++) {
            if (i < 0) break;

            const aircraft = this._viewportAircrafts[i];

            let className = '';

            if (this._selectedAircraftIds.includes(aircraft.id)) {
                className += ' selected';
            }

            aircrafts.push(<AircraftListItem key={aircraft.id} className={className} aircraft={aircraft} index={i} isActive={aircraft.id === this._activeAircraftId} />);

            const addGroup = () => {
                aircraftsTypes.push(
                    <div key={aircraftTypeGroup} className={'type-group type-group-' + aircraftTypeGroup} style={{ height: groupHeight }}>
                        <span>{aircraftTypeGroup}</span>
                    </div>
                );
            };

            if (aircraftTypeGroup !== aircraft.aircraftTypeRcd) {
                if (aircraftTypeGroup) {
                    addGroup();
                }
                aircraftTypeGroup = aircraft.aircraftTypeRcd;
                groupHeight = 0;
            }
            groupHeight += aircraftHeight + aircraft.lines.length * aircraftLineHeight;
            if (i === this._viewportAircraftIndexTo) {
                addGroup();
            }
        }

        return (
            <div className="virtual-list">
                <VirtualVerticalExpander virtualHeight={this._virtualHeight} />
                <div className="virtual-list-items" style={personItemStyle}>
                    <div className="types">{aircraftsTypes}</div>
                    <div className="aircrafts">{aircrafts}</div>
                </div>
            </div>
        );
    }

    private _renderGrid() {
        const lines: JSX.Element[] = [];

        for (let i = this._viewportAircraftIndexFrom; i <= this._viewportAircraftIndexTo && i >= 0; i++) {
            const aircraft = this._viewportAircrafts[i];

            lines.push(
                <AircraftLine<TFlight, TMaintenance>
                    key={aircraft.id}
                    index={i}
                    aircraft={aircraft}
                    viewportDays={this._viewportDays}
                    selectedItemIds={this._selectedItemIds}
                    zoom={this._zoom}
                    onSelect={this._onItemSelected}
                    onEnter={() => (this._activeAircraftId = aircraft.id)}
                    onLeave={() => {
                        if (!this._blockLineMouseLeave) {
                            this._activeAircraftId = null;
                        }
                    }}
                    isActive={aircraft.id === this._activeAircraftId}
                    onFlightRender={this.props.onFlightRender}
                    bufferedIds={this.props.bufferedIds}
                />
            );
        }

        const dutyItemStyle: React.CSSProperties = {
            transform: `${this._translateTop}${this._translateLeft}`,
            width: `${this._viewportDays.length * this._dayWidth}px`,
            height: '100%'
        };

        return (
            <div ref={this._scrollWrapper} className="flight-view" tabIndex={0} onMouseDown={this._onContainerMouseDown} onScroll={this._onViewScroll}>
                <div className="virtual-list">
                    <VirtualVerticalExpander virtualHeight={this._virtualHeight} />
                    <VirtualHorizontalExpander virtualWidth={this._virtualWidth} />
                    <div className="virtual-list-items" style={dutyItemStyle}>
                        {lines}
                    </div>
                </div>
                <ReactResizeDetector handleWidth handleHeight onResize={this._onViewResize} />
                {this.props.onRenderExtendedControls?.({ container: this._scrollWrapper, loader: this._loader })}
            </div>
        );
    }

    private pos: { top: number; left: number; x: number; y: number } = { top: 0, left: 0, x: 0, y: 0 };

    private _containerOnMouseMove = (e: MouseEvent) => {
        const _containerRef = this._scrollWrapper.current;

        // How far the mouse has been moved
        const dx = e.clientX - this.pos.x;
        const dy = e.clientY - this.pos.y;

        // Scroll the element
        if (_containerRef) {
            _containerRef.scrollTop = this.pos.top - dy;
            const newScrollLeft = this.pos.left - dx;
            this.pos.x = e.clientX;

            if (newScrollLeft <= 0) {
                this._blockLineMouseLeave = true;
                this.toPrevDay(true, dx);
                this.pos.left = _containerRef.scrollLeft;
                return;
            }

            if (_containerRef.scrollWidth <= Math.ceil(newScrollLeft + _containerRef.offsetWidth)) {
                this._blockLineMouseLeave = true;
                this.toNextDay(true, dx);
                this.pos.left = _containerRef.scrollLeft;
                return;
            }

            this.pos.left = newScrollLeft;
            _containerRef.scrollLeft = newScrollLeft;
        }
    };

    private _onContainerMouseDown = (e: React.MouseEvent) => {
        // Change the cursor and prevent user from selecting the text
        if (e.button !== 0) return;
        const _containerRef = this._scrollWrapper.current;

        if (_containerRef) {
            this.pos = {
                // The current scroll
                left: _containerRef.scrollLeft ?? 0,
                top: _containerRef.scrollTop ?? 0,
                // Get the current mouse position
                x: e.clientX,
                y: e.clientY
            };

            _containerRef.style.userSelect = 'none';
            document.addEventListener('mousemove', this._containerOnMouseMove);
            document.addEventListener('mouseup', this._onContainerMouseUp);
        }
    };

    private _onContainerMouseUp = () => {
        const _containerRef = this._scrollWrapper.current;

        document.removeEventListener('mousemove', this._containerOnMouseMove);
        document.removeEventListener('mouseup', this._onContainerMouseUp);
        if (_containerRef) {
            _containerRef.style.removeProperty('user-select');
        }
    };

    @action.bound
    private _onKeyDown(event: KeyboardEvent) {
        if (event.code === KeyboardCodes.ESCAPE && !!this._selectedItemIds.length) {
            this._selectedItemIds = [];
            this._notifySelectionChanged();
        }
    }

    private get _dayWidth(): number {
        return getDayWidth(this._zoom);
    }

    private get _virtualWidth(): number {
        const total = DateTimeService.diffDays(this._dateTo, this._dateFrom) + 1;
        return total * this._dayWidth;
    }

    private get _virtualHeight(): number {
        const aircrafts = this._viewportAircrafts;

        let height = aircraftHeight * aircrafts.length;
        aircrafts.forEach((a) => {
            height += aircraftLineHeight * a.lines.length;
        });

        return height;
    }

    private get _virtualDrawOptions() {
        let topHeightUsed = this._scrollTop;

        const aircrafts = this._viewportAircrafts;
        for (const a of aircrafts) {
            const heightOfLine = aircraftHeight + aircraftLineHeight * a.lines.length;
            if (topHeightUsed > heightOfLine) {
                topHeightUsed -= heightOfLine;
            } else {
                break;
            }
        }

        return {
            viewportDays: this._viewportDays,
            virtualWidth: this._virtualWidth,
            virtualHeight: this._virtualHeight,
            viewportLeft: this._scrollLeft - this._dayWidth * extensions.getDecimal(this._scrollLeft / this._dayWidth),
            viewportTop: this._scrollTop - topHeightUsed
        };
    }

    private get _translateLeft(): string {
        return ` translateX(${this._virtualDrawOptions.viewportLeft}px)`;
    }

    private get _translateTop(): string {
        return ` translateY(${this._virtualDrawOptions.viewportTop}px)`;
    }

    @action.bound
    private _onViewResize() {
        this._updateViewport();
    }

    private _onZoomChange(isZoomIn: boolean) {
        if (isZoomIn) {
            switch (this._zoom) {
                case GantZoom.x1:
                    this._zoom = GantZoom.x05;
                    break;
                case GantZoom.x2:
                    this._zoom = GantZoom.x1;
                    break;
                case GantZoom.x4:
                    this._zoom = GantZoom.x2;
                    break;
                case GantZoom.x8:
                    this._zoom = GantZoom.x4;
                    break;
            }
        } else {
            switch (this._zoom) {
                case GantZoom.x05:
                    this._zoom = GantZoom.x1;
                    break;
                case GantZoom.x1:
                    this._zoom = GantZoom.x2;
                    break;
                case GantZoom.x2:
                    this._zoom = GantZoom.x4;
                    break;
                case GantZoom.x4:
                    this._zoom = GantZoom.x8;
                    break;
            }
        }

        const selectedFlightId = !!this._selectedItemIds?.length && this._selectedItemIds?.[0];
        let date = this._viewportDateFrom;

        if (selectedFlightId) {
            const flight = this._flightStore.flights.get(selectedFlightId);
            if (flight) {
                date = DateTimeService.trimTime(getFlightDateFrom(flight));
            }
        }
        this._updateStatus = UpdateStatuses.NeedToUpdate;
        this._scrollWrapper.current && this._navigateInternal(this._scrollWrapper.current, null, date);
        this._updateViewport();
        this.props.onZoom?.(this._zoom);
    }

    @action.bound
    private _updateViewport() {
        if (this._updateStatus === UpdateStatuses.NeedToUpdate) return;

        this._positionVirtualScrolls();
        const scrollTop = this._scrollTop;
        const scrollLeft = this._scrollLeft;

        const grid = this._scrollWrapper.current;
        if (!grid) {
            setTimeout(() => this._updateViewport(), 0);
            return;
        }

        const offsetHeight = grid.offsetHeight;

        const width = grid.offsetWidth;
        const viewportDaysCount = Math.floor(width / this._dayWidth || 0) + 1;
        const viewportDutyStartIndex = Math.floor(scrollLeft / this._dayWidth);

        const viewportDateFrom = DateTimeService.addDays(this._dateFrom, viewportDutyStartIndex);
        let viewportDateTo = DateTimeService.addDays(viewportDateFrom, viewportDaysCount);
        if (viewportDateTo > this._dateTo) {
            viewportDateTo = this._dateTo;
        }

        const viewportDatesRange = {
            from: viewportDateFrom,
            to: viewportDateTo
        };

        if (viewportDatesRange.from !== this._viewportDateFrom) {
            this._viewportDateFrom = viewportDatesRange.from;
        }
        if (viewportDatesRange.to !== this._viewportDateTo) {
            this._viewportDateTo = viewportDatesRange.to;
        }

        let height = 0;
        let viewportPersonIndexFrom = 0;
        let viewportPersonIndexTo = 0;

        const viewportAircrafts: DisplayAircraft<TFlight, TMaintenance>[] = [];

        if (this._viewportDateFrom && this._viewportDateTo) {

            for (const aircraft of this._displayAircrafts) {
                let ac: DisplayAircraft<TFlight, TMaintenance> | null = null;
                for (const line of aircraft.lines) {

                    let hasBlock = false;
                    for (const block of line.activityBlocks) {
                        const isInView = this._viewportDateFrom <= block.end && this._viewportDateTo >= block.start;
                        if (isInView) {
                            hasBlock = true;
                            break;
                        }
                    }

                    if (hasBlock) {
                        if (!ac) {
                            ac = {
                                id: aircraft.id,
                                aircraftTypeRcd: aircraft.aircraftTypeRcd,
                                lines: [],
                                aircraftTypeGroupCount: aircraft.aircraftTypeGroupCount,
                                registrationNumber: aircraft.registrationNumber
                            };
                        }
                        ac.lines.push(line);
                    }
                }
                ac && viewportAircrafts.push(ac);
            }
        }
        this._viewportAircrafts = viewportAircrafts;

        for (const a of this._viewportAircrafts) {
            height += aircraftHeight + a.lines.length * aircraftLineHeight;

            if (height < scrollTop) {
                viewportPersonIndexFrom++;
            }

            if (height < scrollTop + offsetHeight) {
                viewportPersonIndexTo++;
            } else {
                break;
            }
        }

        this._viewportAircraftIndexFrom = viewportPersonIndexFrom;
        this._viewportAircraftIndexTo = viewportPersonIndexTo;
        if (this._viewportAircraftIndexTo >= this._viewportAircrafts.length) {
            this._viewportAircraftIndexTo = this._viewportAircrafts.length - 1;
        }

        const viewportDates: Date[] = [];
        let currentDate = viewportDateFrom;
        while (currentDate <= viewportDateTo) {
            viewportDates.push(currentDate);
            currentDate = DateTimeService.addDays(currentDate, 1);
        }

        if (!ArrayHelper.isSameDateArray(this._viewportDays, viewportDates)) {
            this._viewportDays = viewportDates;
        }

        const virtualRange = {
            from: this._dateFrom,
            to: this._dateTo
        };

        this._loadViewport(viewportDatesRange, virtualRange, LoadMode.Viewport);
    }

    @action.bound
    private _onViewScroll(event: React.UIEvent<Element>) {
        const target = event.target as HTMLElement;

        if (target !== this._scrollWrapper.current) return;
        this._scrollTop = target.scrollTop;
        this._scrollLeft = target.scrollLeft;

        this.props.onScroll?.(this._scrollTop, this._scrollLeft);
        this._checkAutoScroll();

        this._updateViewport();
    }

    private _positionVirtualScrolls() {
        const datesList = this._datesList.current;
        if (datesList) {
            datesList.scrollLeft = this._scrollLeft;
        }

        const aircraftsList = this._aircraftsList.current;
        if (aircraftsList) {
            aircraftsList.scrollTop = this._scrollTop;
        }
    }

    @action
    private _restoreViewState(viewState: GanttViewState) {
        this._dateFrom = viewState.dateFrom;
        this._dateTo = viewState.dateTo;

        if (viewState.viewportDateFrom) {
            this._scrollLeft = this._dayWidth * DateTimeService.diffDays(viewState.viewportDateFrom, viewState.dateFrom);
        }

        this._updateViewport();
        if (viewState.viewportCenterRange) {
            this.ensureRangeVisible(viewState.viewportCenterRange, true);
        } else {
            this._scheduleScrollGrid(viewState.scrollTop ?? this._scrollTop, viewState.scrollLeft ?? this._scrollLeft);
        }
    }

    @action
    private _checkAutoScroll() {
        let grid = this._scrollWrapper.current;
        if (!grid) return;
        if (!grid.scrollLeft) {
            this._waitTimeout('left');
        } else if (grid.scrollWidth <= Math.ceil(grid.scrollLeft + grid.offsetWidth)) {
            this._waitTimeout('right');
        } else {
            this._waitTimeout('clear');
        }
    }

    @action
    private _waitTimeout(direction: 'left' | 'right' | 'clear') {
        if (this._autoExpandTimeout) {
            clearTimeout(this._autoExpandTimeout);
            this._autoExpandTimeout = null;
        }
        if (direction === 'clear') return;

        this._autoExpandCssClass = direction;

        this._autoExpandTimeout = window.setTimeout(() => {
            if (direction === 'left') this.toPrevDay(true);

            if (direction === 'right') this.toNextDay(true);
            this._autoExpandTimeout = null;
        },
            700);
    }

    //keepScroll is true for auto-scroll
    @action
    public toNextDay(keepScroll?: boolean, deltaX?: number) {
        const grid = this._scrollWrapper.current;
        if (!grid) return;

        const dateTo = this._dateTo;
        const dateFrom = this._dateFrom;
        const viewportDateTo = this._viewportDateTo;
        if (!viewportDateTo) return;

        const viewportDaysCount = this._viewportDays?.length;

        const navigateStrategy = {
            getNextPeriodDateTo: (date: Date) => {
                return DateTimeService.addDays(date, viewportDaysCount);
            },
            getNextDateFrom: (newEndDate: Date, oldDateTo: Date, oldDateFrom: Date) => {
                const daysInView = DateTimeService.diffDays(oldDateTo, oldDateFrom);

                return DateTimeService.addDays(newEndDate, -daysInView);
            },
            getNextViewportStart: (newEndDate: Date) => {
                return DateTimeService.addDays(newEndDate, -viewportDaysCount);
            }
        };

        const newDateTo = navigateStrategy.getNextPeriodDateTo(viewportDateTo);

        const waitTillUpdate = newDateTo <= dateTo;

        this._dateTo = DateTimeService.max(dateTo, newDateTo);

        if (keepScroll) {
            this._dateFrom = navigateStrategy.getNextDateFrom(newDateTo, dateTo, dateFrom);

            const newViewportStartDate = navigateStrategy.getNextViewportStart(this._dateTo);
            const totalDaysTo = DateTimeService.diffDays(newViewportStartDate, this._dateFrom) + 1;
            const lengthToPx = totalDaysTo * this._dayWidth;

            this._scheduleScrollGrid(null, lengthToPx - grid.clientWidth + (deltaX ?? 0), waitTillUpdate);
        } else {
            const endOfViewportMonth = navigateStrategy.getNextPeriodDateTo(viewportDateTo);

            let newViewportDateTo: Date;
            if (DateTimeService.isSameDate(endOfViewportMonth, viewportDateTo)) {
                newViewportDateTo = navigateStrategy.getNextPeriodDateTo(endOfViewportMonth);
            } else {
                newViewportDateTo = endOfViewportMonth;
            }
            const totalDaysTo = DateTimeService.diffDays(newViewportDateTo, this._dateFrom) + 1;
            const lengthToPx = totalDaysTo * this._dayWidth;

            this._scheduleScrollGrid(null, lengthToPx - grid.clientWidth + (deltaX ?? 0), waitTillUpdate);
        }
    }

    //keepScroll is true for move viewPort, false for extending viewPort
    @action
    public toPrevDay(keepScroll?: boolean, deltaX?: number) {
        let dateFrom = this._dateFrom;
        let dateTo = this._dateTo;
        let viewportDateFrom = this._viewportDateFrom;
        if (!viewportDateFrom) return;
        const viewportDaysCount = this._viewportDays?.length;

        const strategy = {
            getPreviousViewportDateFrom: (date: Date) => {
                return DateTimeService.addDays(date, -viewportDaysCount);
            },
            getNextDateTo: (newEndDate: Date, oldDateTo: Date, oldDateFrom: Date) => {
                const daysInView = DateTimeService.diffDays(oldDateTo, oldDateFrom);
                return DateTimeService.addDays(newEndDate, daysInView);
            }
        };

        const newDateFrom = strategy.getPreviousViewportDateFrom(viewportDateFrom);

        const waitTillUpdate = newDateFrom <= dateFrom;
        this._dateFrom = DateTimeService.min(dateFrom, newDateFrom);

        if (keepScroll) {
            this._dateTo = strategy.getNextDateTo(this._dateFrom, dateTo, dateFrom);

            this._updateViewport();
            this._scheduleScrollGrid(null, viewportDaysCount * this._dayWidth + (deltaX ?? 0), waitTillUpdate);
        } else {
            const offset = DateTimeService.diffDays(viewportDateFrom, this._dateFrom) + 1;

            this._updateViewport();
            this._scheduleScrollGrid(null, offset * this._dayWidth + (deltaX ?? 0), waitTillUpdate);
        }
    }

    @action
    public ensureRangeVisible(range: Range<Date>, alwaysCenter?: boolean | null) {
        const scrollWrapper = this._scrollWrapper.current;
        if (!scrollWrapper) {
            this._scheduleEnsureRange = { range: range, alwaysCenter: alwaysCenter };
            return true;
        }

        if (!alwaysCenter && this._viewportDateFrom && this._viewportDateTo) {
            if (this._viewportDateFrom < range.from && range.to < this._viewportDateTo) return true;
        }

        const days = DateTimeService.diffDays(range.to, range.from) + 1;
        const daysInView = scrollWrapper.offsetWidth / this._dayWidth;

        if (days > daysInView - 2) {
            return this.navigateTo(null, DateTimeService.addDays(range.from, -1), NavigateBehavior.topLeft);
        } else {
            const middleDate = DateTimeService.startOfDate(new Date((range.from.getTime() + range.to.getTime()) / 2));
            return this.navigateTo(null, middleDate, NavigateBehavior.center);
        }
    }

    private _scheduleScrollGrid(top: number | null, left: number | null, waitTillUpdate?: boolean | null) {
        const scrollWrapper = this._scrollWrapper.current;
        let behavior: 'scroll' | 'timeout' | 'update' = 'timeout';

        if (waitTillUpdate === true) {
            behavior = 'update';
        }
        if (waitTillUpdate === false) {
            behavior = 'scroll';
        }
        if (behavior === 'timeout') {
            if (scrollWrapper && this._updateStatus !== UpdateStatuses.Pending) {
                behavior = 'scroll';
            } else {
                behavior = 'update';
            }
        }

        if (behavior === 'scroll') {
            // just scroll, no new range is expected
            this._scrollGrid(top, left);
        } else if (behavior === 'update') {
            // in case recieved update via props, have to wait till new DOM is ready to be able to scroll new range
            // also loading of component, will scroll once DOM is ready
            this._scheduledRestoreScroll = { top: top, left: left };
        }
    }

    private _scrollGrid(top: number | null, left: number | null) {
        const gridDutyList = this._scrollWrapper.current;
        if (gridDutyList) {
            let updated = false;
            if (left !== null) {
                const isScrollVisible = gridDutyList.scrollWidth > gridDutyList.clientWidth;
                if (isScrollVisible) {
                    if (gridDutyList.scrollLeft !== left) {
                        gridDutyList.scrollLeft = left;
                        updated = true;
                    }
                } else {
                    this._onViewResize();
                }
            }
            if (top !== null) {
                if (gridDutyList.scrollTop !== top) {
                    gridDutyList.scrollTop = top;
                    updated = true;
                }
            }

            if (!updated) {
                if (top) this._scrollTop = top;
                if (left) this._scrollLeft = left;
                this._updateViewport();
            }
        }
    }

    @action
    public navigateTo(flightId: string | null, date: Date | null, behavior: NavigateBehavior = NavigateBehavior.center, selectFlight?: boolean) {
        const scrollWrapper = this._scrollWrapper.current;
        if (!scrollWrapper) {
            return false;
        }

        this._navigateInternal(scrollWrapper, flightId, date, behavior);

        if (selectFlight && flightId) {
            this._selectedItemIds = [flightId];
            this._notifySelectionChanged();
        }

        return true;
    }

    private _navigateInternal(scrollWrapper: HTMLDivElement, flightId: string | null, date: Date | null, behavior: NavigateBehavior = NavigateBehavior.center) {
        const height = scrollWrapper.offsetHeight;
        const width = scrollWrapper.offsetWidth;

        let lineHeight = 0;
        let linesHeight = 0;
        const aircraftIndex = this._displayAircrafts.findIndex((aircraft) => {
            lineHeight = aircraftHeight + aircraftLineHeight * aircraft.lines.length;
            linesHeight += lineHeight;
            return aircraft.lines.find((line) => line.activityBlocks.find((block) => block.items.find((b) => b.flight?.id === flightId)));
        });

        let scrollTop: number = this._scrollTop;
        if (flightId && aircraftIndex > -1) {
            scrollTop = Math.max(0, Math.round(linesHeight - (behavior === NavigateBehavior.center ? height / 2 + lineHeight / 2 : 0)));
        }

        let scrollLeft: number = this._scrollLeft;
        let waitTilUpdate: boolean | null = null;
        if (date) {
            const daysInView = DateTimeService.diffDays(this._dateTo, this._dateFrom);
            const dayStart = DateTimeService.addDays(date, -Math.round(daysInView / 2));
            const dayEnd = DateTimeService.addDays(date, Math.round(daysInView / 2));
            this._dateFrom = dayStart;
            this._dateTo = dayEnd;
            waitTilUpdate = true;
            scrollLeft = Math.round(DateTimeService.getDateIndexInRange(date, this._dateFrom) * this._dayWidth - (behavior === NavigateBehavior.center ? width / 2 - this._dayWidth / 2 : 0));
            this._updateViewport();
        }

        this._scheduleScrollGrid(scrollTop, scrollLeft, waitTilUpdate);
    }

    private async _loadAircrafts() {
        const { data: aircrafts } = await this._loader.add(async () => {
            return await ApiService.get<TAircraft[]>(this.props.aircraftsUrl, null, { completion: this._loader });
        });

        this._aircrafts = aircrafts;

        this._rebuildRotationBlocks();
        this._updateViewport();
    }

    private _loadViewport(viewportRange: Range<Date>, fullRange: Range<Date>, loadMode: LoadMode) {
        if (viewportRange && fullRange && loadMode === LoadMode.Viewport) {
            const viewportDaysCount = this._viewportDays.length;
            this._flightRangeStore.loadRange(viewportRange, fullRange, viewportDaysCount);
            this._maintenanceRangeStore.loadRange(viewportRange, fullRange, viewportDaysCount);
        }
        if (loadMode === LoadMode.Refresh && viewportRange) {
            this._flightRangeStore.reloadRange(viewportRange);
            this._maintenanceRangeStore.reloadRange(viewportRange);
        }
        if (loadMode === LoadMode.Clean) {
            this._flightRangeStore.clear();
            this._maintenanceRangeStore.clear();
        }
    }

    @action.bound
    public async refresh() {
        const promise = Promise.all([this._flightRangeStore.clear(true), this._maintenanceRangeStore.clear(true)]);
        this._rebuildRotationBlocks();
        await promise;
    }

    @action.bound
    public async refreshFlights(flightIds: string[]) {
        await this._flightStore.update(flightIds, false);

        this._rebuildRotationBlocks();
    }

    @action
    public async refreshFlightsRange(date: Date) {
        if (!this._flightStore.hasDate(date)) {
            await this._flightStore.update(
                {
                    from: date,
                    to: date
                },
                false
            );
            this._rebuildRotationBlocks();
        }
    }

    @action
    public async waitLoader() {
        await this._loader.wait();
    }

    private _filterText(filterText: string | undefined, matchCallback: (filterPart: string) => boolean) {
        if (filterText) {
            const filters = filterText.toLowerCase().split(',');
            let result = false;

            for (let i = 0; i < filters.length; i++) {
                const filter = filters[i];

                let matchByFilters = true;
                const filterParts = filter.split(' ');
                for (let j = 0; j < filterParts.length; j++) {
                    let filterPart = filterParts[j].trim();

                    let isNegative = filterPart.startsWith('-');
                    if (isNegative) {
                        filterPart = filterPart.substring(1);

                        if (matchCallback(filterPart)) {
                            matchByFilters = false;
                        }
                    } else {
                        if (matchCallback(filterPart)) {
                            continue;
                        }

                        matchByFilters = false;
                    }
                }

                if (matchByFilters) {
                    result = true;
                }
            }

            return result;
        }

        return true;
    }

    private _filterFlight(flight: TFlight, aircraft?: TAircraft) {
        const { showInactive, filterText } = this.props;

        if (!showInactive && (flight.status !== 'Actual' || flight.plannedAircraftTypeRcd.endsWith('C') || flight.statusActionIdentifier?.indexOf('EQT') > -1)) {
            return false;
        }

        return this._filterText(filterText, (filterPart) => {
            return this._matchFlight(filterPart, flight, aircraft);
        });
    }

    private _filterMaintenance(maintenance: TMaintenance, aircraft?: TAircraft) {
        const { filterText } = this.props;

        return this._filterText(filterText, (filterPart) => {
            return this._matchMaintenance(filterPart, maintenance, aircraft);
        });
    }

    private _matchFlight(filterText: string, flight: TFlight, aircraft?: TAircraft) {
        const { onFlightMatch } = this.props;

        let filter = filterText;
        if (!filter) {
            return true;
        }

        if (flight.id.toLowerCase() === filter) {
            return true;
        }

        if (onFlightMatch?.(flight, filter)) {
            return true;
        }

        const flightNumber = flight.flightNumber.toString();

        const shouldStartWith = filter.startsWith('^');
        if (shouldStartWith) {
            filter = filter.substring(1);
        }
        const shouldEndWith = filter.endsWith('$');
        if (shouldEndWith) {
            filter = filter.substring(0, filter.length - 1);
        }
        const flightNumberMatchIndex = flightNumber.indexOf(filter);

        if (flightNumberMatchIndex !== -1) {
            const okStart = !shouldStartWith || (shouldStartWith && flightNumberMatchIndex === 0);
            const okEnd = !shouldEndWith || (shouldEndWith && flightNumberMatchIndex === flightNumber.length - filter.length);

            if (okStart && okEnd) {
                return true;
            }
        }

        if (flight.destinationRcd.toLowerCase().includes(filter)) {
            return true;
        }

        if (flight.originRcd.toLowerCase().includes(filter)) {
            return true;
        }

        if (flight.legSequenceNumber.toString().includes(filter)) {
            return true;
        }

        if (aircraft) {
            if (filter && aircraft.registrationNumber.toLowerCase().includes(filter)) {
                return true;
            }
        }

        return false;
    }

    private _matchMaintenance(filterText: string, maintenance: TMaintenance, aircraft?: TAircraft) {
        let filter = filterText;
        if (!filter) {
            return true;
        }

        const text = maintenance.title.toLowerCase();

        const shouldStartWith = filter.startsWith('^');
        if (shouldStartWith) {
            filter = filter.substring(1);
        }
        const shouldEndWith = filter.endsWith('$');
        if (shouldEndWith) {
            filter = filter.substring(0, filter.length - 1);
        }
        const matchIndex = text.indexOf(filter);

        if (matchIndex !== -1) {
            const okStart = !shouldStartWith || (shouldStartWith && matchIndex === 0);
            const okEnd = !shouldEndWith || (shouldEndWith && matchIndex === text.length - filter.length);

            if (okStart && okEnd) {
                return true;
            }
        }

        if (aircraft) {
            if (filter && aircraft.registrationNumber.toLowerCase().includes(filter)) {
                return true;
            }
        }

        return false;
    }

    @action.bound
    private _onItemSelected(itemId: string, isCtrl: boolean) {
        const { allowMultiSelect } = this.props;
        if (!isCtrl || !allowMultiSelect) {
            this._selectedItemIds = [itemId];
        } else {
            const index = this._selectedItemIds.indexOf(itemId);
            if (index === -1) {
                this._selectedItemIds.push(itemId);
            } else {
                this._selectedItemIds.splice(index, 1);
            }
        }

        this._notifySelectionChanged();
    }

    private _notifySelectionChanged() {
        const { onSelected } = this.props;

        const flights: TFlight[] = [];
        const maintenances: TMaintenance[] = [];
        const aircrafts: TAircraft[] = [];
        for (const id of this._selectedItemIds) {
            const flight = this._flightStore.flights.get(id);
            const maintenance = this._maintenanceStore.maintenances.get(id);
            const aircraft = this._aircrafts.find((x) => x.id === flight?.aircraftId || x.id === maintenance?.aircraftId);

            flight && flights.push(flight);
            maintenance && maintenances.push(maintenance);
            aircraft && aircrafts.push(aircraft);
        }

        const e: GantViewOnSelectedEvent<TFlight, TAircraft, TMaintenance> = {
            activeItemId: this._selectedItemIds[0] || null,
            selectedItemIds: this._selectedItemIds,
            selectedFlights: flights,
            selectedMaintenances: maintenances,
            selectedAircrafts: aircrafts
        };
        onSelected(e);
    }

    @action.bound
    public async loadFlightDetails(flightId: string | null) {
        if (!flightId) return;

        this._selectedItemIds = [flightId];

        await this._loader.wait();
        this._notifySelectionChanged();
    }

    @action.bound
    private _rebuildRotationBlocks() {
        const baseAirportRcd = 'ZRH';

        const result: DisplayAircraft<TFlight, TMaintenance>[] = [];

        const aircraftMap = new Map<string | undefined, TAircraft>();
        for (const a of this._aircrafts) {
            aircraftMap.set(a.id, a);
        }

        //group by aircraft
        const flightsByAircrafts = new Map<string, TFlight[]>();
        for (const f of this._flightStore.flights.values()) {
            const aircraftKey = f.aircraftId ?? f.plannedAircraftTypeRcd;

            let arcraftFlights = flightsByAircrafts.get(aircraftKey);
            if (!arcraftFlights) {
                arcraftFlights = [];
                flightsByAircrafts.set(aircraftKey, arcraftFlights);
            }

            arcraftFlights.push(f);
        }

        const maintenancesByAircrafts = new Map<string, TMaintenance[]>();
        for (const m of this._maintenanceStore.maintenances.values()) {
            const aircraftKey = m.aircraftId ?? m.aircraftTypeRcd;

            let arcraftMaintenances = maintenancesByAircrafts.get(aircraftKey);
            if (!arcraftMaintenances) {
                arcraftMaintenances = [];
                maintenancesByAircrafts.set(aircraftKey, arcraftMaintenances);
            }
            arcraftMaintenances.push(m);

            //ensure we have flights block, at least empty if no flights but maintenance
            let arcraftFlights = flightsByAircrafts.get(aircraftKey);
            if (!arcraftFlights) {
                arcraftFlights = [];
                flightsByAircrafts.set(aircraftKey, arcraftFlights);
            }
        }

        const filterBlock = (aircraft: TAircraft | undefined, block: ActivityBlockDto<TFlight, TMaintenance>) => {
            for (const item of block.allItems) {
                if (item.flight) {
                    if (this._filterFlight(item.flight, aircraft)) {
                        return true;
                    }
                }

                if (item.maintenance) {
                    if (this._filterMaintenance(item.maintenance, aircraft)) {
                        return true;
                    }
                }

                if (item.connector) {
                    continue;
                }
            }

            return false;
        };

        //find rotations
        for (const [key, flights] of flightsByAircrafts) {
            //order by date
            flights.sort((a, b) => {
                return getFlightDateFrom(a).getTime() - getFlightDateFrom(b).getTime();
            });

            //create flight for UI to hold rotation blocks
            const aircraftInfo = aircraftMap.get(key);
            const aircraft: DisplayAircraft<TFlight, TMaintenance> = {
                id: aircraftInfo?.id ?? key,
                aircraftTypeRcd: aircraftInfo?.aircraftTypeRcd ?? key,
                registrationNumber: aircraftInfo?.registrationNumber,
                lines: []
            };

            const aircraftBlocks: ActivityBlockDto<TFlight, TMaintenance>[] = [];

            const processedFlights = new Set<string>();

            const maxTimeToConnectFlightsHours = 24;

            const findNextMatch = (rotation: ActivityBlockDto<TFlight, TMaintenance>, lastFlight: TFlight, startIndex: number): boolean => {
                const candidates: { flight: TFlight; startUtc: Date; endUtc: Date; index: number }[] = [];

                if (lastFlight.destinationRcd === baseAirportRcd && lastFlight.originRcd !== baseAirportRcd) {
                    return false;
                }

                const lastArrivalUtc = getFlightDateTo(lastFlight);

                for (let i = startIndex + 1; i < flights.length; i++) {
                    const flight = flights[i];

                    if (processedFlights.has(flight.id)) continue;

                    const startUtc = getFlightDateFrom(flight);
                    const endUtc = getFlightDateTo(flight);

                    if (startUtc.getTime() - lastArrivalUtc.getTime() > maxTimeToConnectFlightsHours * 60 * 60 * 1000) {
                        //max search intervar
                        break;
                    }

                    if (lastFlight.destinationRcd === flight.originRcd) {
                        candidates.push({ flight, startUtc, endUtc, index: i });
                    }
                }

                if (!candidates.length) return false;

                const isLastFlightActual = lastFlight.status === 'Actual';

                if (candidates.length > 1) {
                    candidates.sort((a, b) => {
                        const flightA = a.flight;
                        const flightB = b.flight;
                        const activeFlightA = flightA.status === 'Actual';
                        const activeFlightB = flightB.status === 'Actual';
                        if (activeFlightA !== activeFlightB) {
                            if (isLastFlightActual) {
                                return activeFlightA ? -1 : 1;
                            }

                            return activeFlightA ? 1 : -1;
                        }

                        const timeA = flightA.departureDateTimeUtc.getTime();
                        const timeB = flightB.departureDateTimeUtc.getTime();
                        if (timeA !== timeB) {
                            return timeA - timeB;
                        }

                        const exactMatchByRotationA = flightA.rotationId === lastFlight.rotationId;
                        const exactMatchByRotationB = flightB.rotationId === lastFlight.rotationId;

                        if (exactMatchByRotationA !== exactMatchByRotationB) {
                            return exactMatchByRotationA ? -1 : 1;
                        }

                        const exactMatchByFlightA = flightA.flightNumber === lastFlight.flightNumber;
                        const exactMatchByFlightB = flightB.flightNumber === lastFlight.flightNumber;

                        if (exactMatchByFlightA !== exactMatchByFlightB) {
                            return exactMatchByFlightA ? 1 : -1;
                        }

                        const matchByFlightA = flightA.flightNumber + 1 === lastFlight.flightNumber;
                        const matchByFlightB = flightB.flightNumber + 1 === lastFlight.flightNumber;

                        if (matchByFlightA !== matchByFlightB) {
                            return matchByFlightA ? 1 : -1;
                        }

                        return 0;
                    });
                }

                const bestCandidate = candidates[0];
                const { flight, startUtc, endUtc, index } = bestCandidate;

                processedFlights.add(flight.id);

                const newConnectorItem: AircraftActivityDto<TFlight, TMaintenance> = {
                    connector: {
                        startUtc: lastArrivalUtc,
                        endUtc: startUtc
                    }
                };

                const isNextLine = startUtc < lastArrivalUtc;

                const newFlightItem: AircraftActivityDto<TFlight, TMaintenance> = {
                    flight: flight
                };
                rotation.allItems.push(newFlightItem);

                if (isNextLine) {
                    const line: ActivityBlockLineDto<TFlight, TMaintenance> = {
                        items: [newFlightItem],
                        lastItem: newFlightItem,
                        startTime: startUtc,
                        endTime: endUtc
                    };
                    rotation.lines.push(line);
                } else {
                    const line = rotation.lines[rotation.lines.length - 1];
                    line.lastItem = newFlightItem;
                    line.endTime = endUtc;
                    line.items.push(newConnectorItem, newFlightItem);
                }

                findNextMatch(rotation, flight, index);

                return true;
            };

            const mapFlights = (mode: 'active' | 'inactive' | 'rest') => {
                const stepProcessedFlights = new Set<string>();
                for (let i = 0; i < flights.length; i++) {
                    const flight = flights[i];
                    if (mode === 'active') {
                        if (flight.status === 'Actual' && flight.originRcd !== 'ZRH') continue;
                        if (flight.status !== 'Actual') continue;
                    }
                    if (mode === 'inactive') {
                        if (flight.status !== 'Actual' && flight.originRcd !== 'ZRH') continue;
                    }

                    if (processedFlights.has(flight.id)) continue;
                    if (stepProcessedFlights.has(flight.id)) continue;
                    stepProcessedFlights.add(flight.id);

                    const startUtc = getFlightDateFrom(flight);
                    const endUtc = getFlightDateTo(flight);

                    const item: AircraftActivityDto<TFlight, TMaintenance> = {
                        flight: flight
                    };

                    const rotationItem: ActivityBlockDto<TFlight, TMaintenance> = {
                        allItems: [item],
                        lines: [
                            {
                                items: [item],
                                lastItem: item,
                                startTime: startUtc,
                                endTime: endUtc
                            }
                        ],
                        startTime: startUtc
                    };

                    if (findNextMatch(rotationItem, flight, i) || mode === 'rest') {
                        processedFlights.add(flight.id);
                        aircraftBlocks.push(rotationItem);
                    }
                }
            };
            mapFlights('active');
            mapFlights('inactive');
            mapFlights('rest');

            const maintenances = maintenancesByAircrafts.get(key);
            if (maintenances) {
                for (const maintenance of maintenances) {
                    const item: AircraftActivityDto<TFlight, TMaintenance> = {
                        maintenance: maintenance
                    };

                    const rotationItem: ActivityBlockDto<TFlight, TMaintenance> = {
                        allItems: [item],
                        lines: [
                            {
                                items: [item],
                                lastItem: item,
                                startTime: maintenance.dateTimeFromUtc,
                                endTime: maintenance.dateTimeToUtc
                            }
                        ],
                        startTime: maintenance.dateTimeFromUtc
                    };
                    aircraftBlocks.push(rotationItem);
                }
            }

            const filteredBlocks = aircraftBlocks.filter((b) => filterBlock(aircraftInfo, b));

            if (!filteredBlocks.length) {
                continue;
            }

            filteredBlocks.sort((a, b) => {
                return a.startTime.getTime() - b.startTime.getTime();
            });

            result.push(aircraft);

            const lines: AircraftLineDto<TFlight, TMaintenance>[] = [];
            for (const block of filteredBlocks) {
                for (const bl of block.lines) {
                    let found = false;
                    for (const line of lines) {
                        if (line.filledTill <= block.startTime) {
                            line.activityBlocks.push({
                                start: bl.startTime,
                                end: bl.endTime,
                                items: bl.items
                            });
                            line.filledTill = bl.endTime;
                            found = true;
                            break;
                        }
                    }
                    if (found) continue;

                    lines.push({
                        activityBlocks: [{
                            start: bl.startTime,
                            end: bl.endTime,
                            items: bl.items
                        }],
                        filledTill: bl.endTime
                    });
                }
            }

            aircraft.lines = lines;
        }

        result.sort((a, b) => {
            const isDefinedAircraftA = !!a.registrationNumber;
            const isDefinedAircraftB = !!b.registrationNumber;

            if (a.aircraftTypeRcd !== b.aircraftTypeRcd) {
                return a.aircraftTypeRcd > b.aircraftTypeRcd ? -1 : 1;
            }

            if (isDefinedAircraftA !== isDefinedAircraftB) {
                return isDefinedAircraftA ? -1 : 1;
            }

            if (a.registrationNumber && b.registrationNumber) {
                return a.registrationNumber > b.registrationNumber ? 1 : -1;
            }

            return 0;
        });

        let aircraftTypeRcd = '';
        let aircraftTypeGroupNumber = 0;
        let aircraftGroupFirstItem: DisplayAircraft<TFlight, TMaintenance> | null = null;
        for (let i = 0; i < result.length; i++) {
            const aircraft = result[i];
            aircraftTypeGroupNumber++;
            if (aircraft.aircraftTypeRcd !== aircraftTypeRcd || i === result.length - 1) {
                if (aircraftGroupFirstItem) {
                    aircraftGroupFirstItem.aircraftTypeGroupCount = aircraftTypeGroupNumber;
                }

                aircraftTypeGroupNumber = 0;
                aircraftGroupFirstItem = aircraft;
                aircraftTypeRcd = aircraft.aircraftTypeRcd;
            }
        }

        this._displayAircrafts = result;
        this._updateViewport();
    }
}

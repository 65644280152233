import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { action, makeObservable, observable } from 'mobx';
import { Button, Col, FormFeedback, Row } from 'reactstrap';

import { ApiUrls } from '@app/AppConstants';
import { FlightDto } from '@app/Models/FlightScheduleModels';
import { PromiseCompletion } from '@classes/PromiseCompletion.ts';
import { AzureAuth, UserPermissions } from '@classes/AzureAuth';
import { BaseFormModel } from '@services/BaseFormModel.ts';
import { DateTimeService } from '@services/DateTimeService.ts';
import { displayName, isDate, isDateAfter, isDateBefore, isRegEx, isRequired } from '@services/Validation';
import { service } from '@services/_ServicesRegister';
import { DownloadService } from '@services/DownloadService';
import ApiService from '@app/Services/ApiService';
import { AircraftDto, AirportModel, Application, CategoryDto, CountryItem, RegionDto, ToiAssignmentModel, ToiAttachmentInfo, ToiContentDetailsModel, ToiContentPostModel } from '@app/Models/WebApiModels';

import { Icon } from '@components/Icon.tsx';
import { CategoryPicker } from '@components/CategoryPicker.tsx';
import { FormInput, FormSwitcher } from '@components/FormControls/FormControls.tsx';
import { TextEditor } from '@components/TextEditor/TextEditor';
import { DatePickerValidatedControl } from '@components/DateTimeControls/DateTimeControls.tsx';
import { IcaoRegionPicker } from '@components/IcaoRegionPicker.tsx';
import { CountryPicker } from '@components/CountryPicker.tsx';
import { AirportPicker } from '@components/AirportPicker.tsx';
import { AircraftTypePicker } from '@components/AircraftTypePicker.tsx';
import { AircraftPicker } from '@components/AircraftPicker.tsx';
import { ApplicationPicker } from '@components/ApplicationPicker.tsx';
import { LongHaulShortHaulPicker } from '@components/LongHaulShortHaulPicker';
import { NotificationHandler } from '@components/ToastNotification';
import { modalService } from '@components/Modal/Modal';
import { FlightViewDialog } from '@components/Dialogs/FlightViewDialog';

import './_toi-form.scss';
import React from 'react';

class FormModel extends BaseFormModel {
    @observable @displayName('Category') @isRequired() category?: CategoryDto;
    @observable @displayName('Subject') @isRequired() subject: string = '';
    @observable @displayName('Text') @isRequired() textHtml: string = '';
    @observable @displayName('Start') @isDate() @isDateBefore('endUtc') @isRequired() startUtc: string = '';
    @observable @displayName('End') @isDate() @isDateAfter('startUtc') @isRequired() endUtc: string = '';
    @observable isActive: boolean = true;
    @observable isGlobal: boolean = false;
    @observable forLxFlight: boolean = false;
    @observable attachments: ToiAttachmentInfo[] = [];
    @observable @displayName('Applicable for') @isRequired() applicableFor?: string;

    constructor() {
        super();
        makeObservable(this);
    }

    @action
    clearForm() {
        this.category = undefined;
        this.subject = '';
        this.textHtml = '';
        this.startUtc = '';
        this.endUtc = '';
        this.attachments = [];
        this.applicableFor = '';
        this.forLxFlight = false;
        this.isGlobal = false;
        this.isActive = true;
    }

    @action
    setForm({ categoryId, subject, content, startUtc, endUtc, isActive, isGlobal, attachments, applicableFor, forLxFlight }: ToiContentDetailsModel) {
        this.category = { id: categoryId };
        this.subject = subject ?? '';
        this.textHtml = content ?? '';
        this.startUtc = startUtc ? DateTimeService.toUiDate(startUtc) : '';
        this.endUtc = endUtc ? DateTimeService.toUiDate(endUtc) : '';
        this.isActive = isActive ?? true;
        this.isGlobal = isGlobal ?? false;
        this.forLxFlight = forLxFlight ?? false;
        this.attachments = attachments || [];
        this.applicableFor = applicableFor;
    }
}

class AssignmentFormModel extends BaseFormModel {
    @observable regions?: RegionDto[] = [];
    @observable countries?: CountryItem[] = [];
    @observable airports?: AirportModel[] = [];
    @observable aircrafts?: AircraftDto[] = [];
    @observable aircraftTypes?: string[] = [];
    @observable isLongHaul?: boolean;
    @observable @isRegEx(/^([a-zA-Z]{2}(-)?\d{1,4})/, '"Flight" field should have format "wk84"') flightNumRcd?: string = '';
    @observable airlineRcd?: string;
    @observable flightNumber?: number;
    @observable flightLegNumber?: string = '';

    constructor(data?: ToiAssignmentModel) {
        super();
        makeObservable(this);

        if (data) {
            this.countries = data.countryThreeLetterCodes?.map((x) => ({
                threeLetterCode: x
            }));
            this.regions = data.regionIds?.map((x) => ({
                id: x
            }));
            this.airports = data.airportThreeLetterCodes?.map((x, i) => ({ fourLetterCode: data.airportFourLetterCodes![i], isActive: true, name: x, threeLetterCode: x }));
            this.aircraftTypes = data.aircraftTypes;
            this.aircrafts = data.aircrafts?.map((x) => ({ id: x, registrationNumber: x, inServiceDateFrom: new Date() }));
            this.isLongHaul = data.isLongHaul;
            this.airlineRcd = data.airlineRcd;
            this.flightNumber = data.flightNumber;
            this.flightNumRcd = data.airlineRcd && data.flightNumber ? [data.airlineRcd, data.flightNumber].join('-') : '';
            this.flightLegNumber = data.leg ? String(data.leg) : undefined;
        }
    }
}

type ToiFormProps = {
    onDeleteCallback?: () => void;
    onResetCallback?: () => void;
    onCancelCallback: () => void;
    onSaveCallback?: () => void;
    id?: string;
    isDublicate?: boolean;
    loader: PromiseCompletion;
};

export const ToiForm: FC<ToiFormProps> = observer(({ onDeleteCallback, onResetCallback, onSaveCallback, onCancelCallback, id, isDublicate, loader }) => {
    const form: FormModel = useMemo(() => new FormModel(), []);
    const _downloadService: DownloadService = useMemo(() => service.resolve(DownloadService), []);
    const uploadInputRef = useRef<HTMLInputElement>(null);
    const [selected, setSelected] = useState<ToiContentDetailsModel | null>(null);
    const [formHasChanges, setFormHasChanges] = useState<boolean>(false);
    const [assignment, setAssignment] = useState(new AssignmentFormModel());
    const [isAssignmentValid, setIsAssignmentValid] = useState(true);

    const hasWriteAccess = AzureAuth.hasAccess(UserPermissions.ToiWrite);

    const checkIsAssignmentValid = useCallback(() => {
        return (
            form.isGlobal ||
            form.forLxFlight ||
            Object.entries(assignment).some(([key, value]) => {
                if (key === 'validated') return false;

                if (Array.isArray(value)) return value.length !== 0;

                return (value ?? '') !== '';
            })
        );
    }, [assignment, form.isGlobal]);

    const getToi = useCallback(async () => {
        if (id) {
            const { data } = await ApiService.getTypedData<ToiContentDetailsModel>(`/api/toi/${id}`, null, { completion: loader });
            if (!isDublicate) setSelected(data);
            form.setForm(data);
            const assignment = new AssignmentFormModel(data.assignment);
            setAssignment(assignment);
        }
    }, [id, form, loader, isDublicate]);

    const onDelete = useCallback(async () => {
        if (!(await modalService.showConfirmation('Do you really want to delete this note?'))) {
            return;
        }

        await ApiService.deleteData(`/api/toi/${id}`, { completion: loader });
        onDeleteCallback && onDeleteCallback();
    }, [onDeleteCallback, id, loader]);

    const onReset = useCallback(async () => {
        if (!(await modalService.showConfirmation('Do you really want to reset form?'))) {
            return;
        }
        form.clearForm();
        setAssignment(new AssignmentFormModel());
        onResetCallback && onResetCallback();
    }, [form, onResetCallback]);

    const onCancel = useCallback(async () => {
        if (formHasChanges) {
            if (!(await modalService.showConfirmation('You have unsaved changes. Do you really want to close the modal?'))) {
                return;
            }
        }
        form.clearForm();
        onCancelCallback && onCancelCallback();
    }, [formHasChanges, form, onCancelCallback]);

    const onChangeAnyField = useCallback(() => {
        setFormHasChanges(true);
        setIsAssignmentValid(checkIsAssignmentValid());
    }, [checkIsAssignmentValid]);

    const onSave = useCallback(async () => {
        const isAssignmentValid = checkIsAssignmentValid();
        setIsAssignmentValid(isAssignmentValid);
        if (!form.validate() || !assignment.validate() || !isAssignmentValid) return;

        const airlineRcdMatch = assignment.flightNumRcd?.match(/^([a-zA-Z]{2})/);
        const flightNumberMatch = assignment.flightNumRcd?.match(/\d{1,4}/);
        assignment.airlineRcd = airlineRcdMatch?.length ? airlineRcdMatch[0] : undefined;
        assignment.flightNumber = flightNumberMatch?.length ? +flightNumberMatch[0] : undefined;

        const toiAssignment = {
            aircrafts: assignment.aircrafts?.map((x) => x.registrationNumber) || undefined,
            aircraftTypes: assignment.aircraftTypes || undefined,
            airportFourLetterCodes: assignment.airports?.map((x) => x.fourLetterCode) || undefined,
            countryThreeLetterCodes: assignment.countries?.map((x) => x.threeLetterCode) || undefined,
            airlineRcd: assignment.airlineRcd?.toLocaleUpperCase(),
            flightNumber: assignment.flightNumber ? +assignment.flightNumber : undefined,
            leg: assignment.flightNumRcd && assignment.flightLegNumber ? +assignment.flightLegNumber : undefined,
            regionIds: assignment.regions?.map((x) => x.id) || undefined,
            isLongHaul: assignment.isLongHaul
        } as ToiAssignmentModel;

        const toi: ToiContentPostModel = {
            categoryId: +form.category!.id,
            startUtc: DateTimeService.parseUiDate(form.startUtc),
            endUtc: DateTimeService.parseUiDate(form.endUtc),
            content: form.textHtml,
            subject: form.subject,
            isActive: form.isActive,
            isGlobal: form.isGlobal,
            forLxFlight: form.forLxFlight,
            applicableFor: form.applicableFor as Application,
            assignment: toiAssignment,
            attachmentIds: form.attachments.map((a) => a.id)
        };

        if (selected) {
            toi.id = selected.id;
            await ApiService.putData('/api/toi', toi, { completion: loader });
            NotificationHandler.showSuccess('ToI was updated!');
        } else {
            await ApiService.postData('/api/toi', toi, { completion: loader });
            NotificationHandler.showSuccess('New ToI was addded!');
        }
        onSaveCallback && onSaveCallback();
    }, [onSaveCallback, selected, assignment, loader, form]);

    const onUploadAttachmentClick = () => {
        uploadInputRef.current?.click();
    };

    const onChangeFileHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files || null;
        if (!files) return;

        for (const file of files) {
            await _uploadFile(file);
        }
    };

    const _uploadFile = async (selectedFile: File) => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('name', selectedFile.name);
        formData.append('contentType', selectedFile.type);

        const { data: id } = await ApiService.postTypedData<string>(`${ApiUrls.TempFileUrl}`, formData);
        form.attachments.push({ id, name: selectedFile.name, size: selectedFile.size });
        NotificationHandler.showSuccess('File uploaded');
    };

    const onDownloadAttachment = async (attachment: ToiAttachmentInfo) => {
        const parameters = { id: attachment.id };
        const url = ApiUrls.ToiAttachmentUrl + `/${attachment.id}`;
        void _downloadService.downloadFile(url, parameters, attachment.name);
    };

    const onRemoveAttachment = async (id: string) => {
        if (!(await modalService.showConfirmation('Do you really want to remove this attachment?'))) {
            return;
        }
        form.attachments = form.attachments.filter((x) => x.id !== id);

        if (uploadInputRef.current?.value) uploadInputRef.current.value = '';
    };

    useEffect(() => {
        void getToi();
    }, [getToi]);

    const _renderValidationError = (form: FormModel | AssignmentFormModel, name: keyof FormModel | keyof AssignmentFormModel) => {
        return (
            form.validated &&
            !!form.validationErrorsName(name).length && (
                <FormFeedback style={{ display: 'block' }}>
                    {form.validationErrorsName(name).map((error: string) => (
                        <div key={error}>{error}</div>
                    ))}
                </FormFeedback>
            )
        );
    };

    const showFlightViewDialog = () => {
        void modalService.show(FlightViewDialog, {
            baseDate: form.startUtc ? DateTimeService.parseUiDate(form.startUtc) : undefined,
            onSelectFlight: (flight: FlightDto | null) => {
                if (!flight) return;

                assignment.flightNumRcd = `${flight.airlineRcd}-${flight.flightNumber}`.toLocaleUpperCase();
                assignment.flightLegNumber = flight.legSequenceNumber ? String(flight.legSequenceNumber) : undefined;
                assignment.isLongHaul = flight.isLongHaul;
                assignment.airlineRcd = flight.airlineRcd;

                form.startUtc = DateTimeService.toUiDate(flight.departureDateTimeUtc);
                form.endUtc = DateTimeService.toUiDate(flight.arrivalDateTimeUtc);

                onChangeAnyField();
            }
        });
    };

    const clearFlightSelection = () => {
        assignment.flightNumRcd = '';
        assignment.flightLegNumber = undefined;
        assignment.airlineRcd = undefined;
        assignment.flightNumber = undefined;
        assignment.isLongHaul = undefined;

        onChangeAnyField();
    };

    const _renderAssignmentSection = () => {
        return (
            <div className="assignment-section">
                <span className="assignment-section-title">Assignment section</span>

                <div className="assignment-section-global">
                    <FormSwitcher label="All Flights" formModel={form} name="isGlobal" checked={form.isGlobal} disabled={!hasWriteAccess} />
                </div>

                <div className={'assignment-grid' + (form.isGlobal ? ' readonly-overlay' : '')}>
                    <div className="assignment-grid-row">
                        <div className="assignment-grid-item">
                            <div className="label">Region</div>
                            <IcaoRegionPicker<true>
                                url="/api/region"
                                isDisabled={!hasWriteAccess}
                                value={assignment.regions}
                                onChange={(val) => {
                                    assignment.regions = val ? (Array.isArray(val) ? val : [val]) : undefined;
                                    onChangeAnyField();
                                }}
                                multi
                            />
                        </div>
                        <div className="assignment-grid-item">
                            <div className="label">Type</div>
                            <AircraftTypePicker<true>
                                value={assignment.aircraftTypes}
                                onChange={(val) => {
                                    assignment.aircraftTypes = val ? (Array.isArray(val) ? val : [val]) : undefined;
                                    onChangeAnyField();
                                }}
                                multi
                                isDisabled={!hasWriteAccess}
                            />
                        </div>
                        <div className="assignment-grid-item">
                            <div className="label">LH/SH</div>
                            <LongHaulShortHaulPicker
                                isLongHaul={assignment.isLongHaul}
                                isDisabled={!hasWriteAccess}
                                onChange={(val?: boolean) => {
                                    assignment.isLongHaul = val;
                                    onChangeAnyField();
                                }}
                            />
                        </div>
                    </div>
                    <div className="assignment-grid-row">
                        <div className="assignment-grid-item">
                            <div className="label">Country</div>
                            <CountryPicker<true>
                                url="/api/country"
                                isDisabled={!hasWriteAccess}
                                value={assignment.countries}
                                onChange={(val) => {
                                    assignment.countries = val ? (Array.isArray(val) ? val : [val]) : undefined;
                                    onChangeAnyField();
                                }}
                                multi
                            />
                        </div>
                        <div className="assignment-grid-item">
                            <div className="label">Registration</div>
                            <AircraftPicker<true>
                                url="/api/aircraft?onlyWk=true&onlyInService=true"
                                isDisabled={!hasWriteAccess}
                                value={assignment.aircrafts}
                                onChange={(val) => {
                                    assignment.aircrafts = val ? (Array.isArray(val) ? val : [val]) : undefined;
                                    onChangeAnyField();
                                }}
                                multi
                            />
                        </div>
                        <div className="assignment-grid-item">
                            <FormSwitcher label="LX Flight" formModel={form} name="forLxFlight" checked={form.forLxFlight} disabled={!hasWriteAccess} />
                        </div>
                    </div>
                    <div className="assignment-grid-row">
                        <div className="assignment-grid-item">
                            <div className="label">Airport</div>
                            <AirportPicker
                                url="/api/airport"
                                value={assignment.airports}
                                onSelect={(val) => {
                                    assignment.airports = val as AirportModel[];
                                    onChangeAnyField();
                                }}
                                isDisabled={!hasWriteAccess}
                                filter={{
                                    includeDisabled: false,
                                    onlyInUse: false
                                }}
                                multi
                            />
                        </div>
                        <div className="assignment-grid-item">
                            <div className="label">Leg</div>
                            <div className="w-100">
                                <div className="d-flex gap-2">
                                    <FormInput formModel={assignment} name="flightNumRcd" hideValidationErrors changeHandler={onChangeAnyField} disabled />
                                    {assignment.flightNumRcd && <Icon name="close" title="Clear flight" className="select-flight-icon" hidden={!hasWriteAccess} onClick={() => clearFlightSelection()} />}
                                    <Icon name="pen-to-square" title="Select leg from schedule" className="select-flight-icon" hidden={!hasWriteAccess} onClick={() => showFlightViewDialog()} />
                                </div>
                                {_renderValidationError(assignment, 'flightNumRcd')}
                            </div>
                        </div>
                    </div>
                </div>
                {!isAssignmentValid && form.validated && <FormFeedback style={{ display: 'block' }}>Assignment shouldn't be empty</FormFeedback>}
            </div>
        );
    };

    return (
        <div className="toi-note-form">
            <Row>
                <Col sm={2}>
                    <div className="label">Category</div>
                </Col>
                <Col sm={6}>
                    <div className="w-100">
                        <CategoryPicker
                            url="/api/category"
                            isDisabled={!hasWriteAccess}
                            value={form.category}
                            onChange={(val) => {
                                form.category = val ? (Array.isArray(val) ? val[0] : val) : undefined;
                                onChangeAnyField();
                            }}
                        />
                        {_renderValidationError(form, 'category')}
                    </div>
                </Col>
                <Col sm={4} className="d-flex justify-content-end">
                    <FormSwitcher label="Active" formModel={form} name="isActive" checked={form.isActive} disabled={!hasWriteAccess} />
                </Col>
            </Row>
            <Row>
                <Col sm={2}>
                    <div className="label">Subject</div>
                </Col>
                <Col sm={6}>
                    <div className="w-100">
                        <FormInput formModel={form} name="subject" changeHandler={onChangeAnyField} disabled={!hasWriteAccess} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm={2}>
                    <div className="label">Text</div>
                </Col>
                <Col sm={10}>
                    <TextEditor
                        formModel={form}
                        name="textHtml"
                        isDisabled={!hasWriteAccess}
                        onChangeHandler={(val) => {
                            form.textHtml = val;
                            onChangeAnyField();
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={2}>
                    <div className="label">Start/End (UTC)</div>
                </Col>
                <Col sm={4} className="d-flex">
                    <DatePickerValidatedControl formModel={form} name="startUtc" pickerMode="date" onChange={onChangeAnyField} disabled={!hasWriteAccess} />
                    <div className="d-flex align-items-center" style={{ margin: '0 5px', height: '31px' }}>
                        /
                    </div>
                    <DatePickerValidatedControl formModel={form} name="endUtc" pickerMode="date" onChange={onChangeAnyField} disabled={!hasWriteAccess} />
                </Col>
            </Row>
            <Row>
                <Col sm={2}>
                    <div className="label">Applicable for</div>
                </Col>
                <Col sm={4}>
                    <ApplicationPicker<false>
                        value={form.applicableFor}
                        isDisabled={!hasWriteAccess}
                        onChange={(application) => {
                            form.applicableFor = application ?? undefined;
                            onChangeAnyField();
                        }}
                    />
                    {_renderValidationError(form, 'applicableFor')}
                </Col>
            </Row>
            <Row>
                <Col sm={2}>
                    <div className="label">Attachments</div>
                </Col>
                <Col sm={10} className="d-flex column-gap-3">
                    <Button color="light" size="sm" className="attachment-upload-btn" disabled={!hasWriteAccess} onClick={onUploadAttachmentClick}>
                        <Icon name="arrow-up-from-bracket" />
                        Upload
                        <input ref={uploadInputRef} type="file" multiple className="attachment-upload-input" onChange={onChangeFileHandler} />
                    </Button>
                    <div className="d-flex flex-wrap gap-2">
                        {form.attachments.map((a) => {
                            const isSavedAttachment = selected?.attachments?.find((s) => s.id === a.id);

                            return (
                                <div key={a.id} className="attachment-item">
                                    <span className="attachment-name" title={a.name}>
                                        {a.name}
                                    </span>

                                    <div className="attachment-actions" hidden={!hasWriteAccess}>
                                        {isSavedAttachment && <Icon name="download" title="Download" onClick={() => onDownloadAttachment(a)} />}
                                        <Icon name="times" title="Delete" onClick={() => onRemoveAttachment(a.id)} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Col>
            </Row>

            {_renderAssignmentSection()}

            <hr />
            <Row>
                <Col sm={3} className="d-flex gap-2">
                    <Button onClick={onCancel}>Cancel</Button>
                    {selected ? (
                        <Button color="danger" disabled={!hasWriteAccess} onClick={onDelete}>
                            Delete
                        </Button>
                    ) : (
                        <Button color="primary" disabled={!hasWriteAccess} onClick={onReset}>
                            Reset
                        </Button>
                    )}
                </Col>
                <Col sm={9} className="d-flex justify-content-end">
                    <Button color="success" disabled={!hasWriteAccess} onClick={onSave}>
                        {selected ? 'Save' : 'Add'}
                    </Button>
                </Col>
            </Row>
        </div>
    );
});

import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, action, computed, makeObservable } from 'mobx';
import { Button } from 'reactstrap';

import { Status, ApiUrls } from '@app/AppConstants';
import { SelfTestResult } from '@app/Models';

import { Timer } from '@services/Timer';
import ApiService from '@app/Services/ApiService';
import { loaderStore } from '@app/Stores/LoaderStore';
import { CompletionType, PromiseCompletion } from '@classes/PromiseCompletion';

import { Icon } from '@components/Icon';
import { modalService } from '@components/Modal/Modal';
import { SelfTestBadge } from './SelfTestBadge/SelfTestBadge';

@observer
export class Monitoring extends React.Component {
    private _store: MonitoringStore;

    constructor (props: {}) {
        super(props);
        makeObservable(this);
        this._store = new MonitoringStore();
    }

    componentDidMount () {
        this._store.initStore();
    }

    componentWillUnmount () {
        this._store.stopRefresh();
    }

    @action.bound
    private async _showMonitoringJson () {
        const { data } = await ApiService.getTypedData<SelfTestResult[]>(ApiUrls.MonitoringUrl, null, { completion: loaderStore.globalLoader });

        const messages = data.map((x) => `${x.status.toUpperCase()} [${x.name}] ${x.title || ''} ${x.message || ''}`);
        void modalService.showInformation(messages, 'Monitoring JSON');
    }

    render () {
        const { color } = this._store;

        return (
            <SelfTestBadge>
                <Button size="sm" className="self-test-btn" title="Show monitoring JSON" color={color}
                        onClick={this._showMonitoringJson} disabled={!this._store.dataLoading.isCompleted}>
                    <Icon name="file"/>
                </Button>
            </SelfTestBadge>
        );
    }
}

class MonitoringStore {
    @observable public data: SelfTestResult[];
    @observable public dataLoading: PromiseCompletion = new PromiseCompletion(CompletionType.Completed);
    private _timer: Timer = new Timer(60 * 1000);

    constructor () {
        makeObservable(this);
    }

    initStore () {
        this._timer.initRequestInterval(this.loadData);
    }

    stopRefresh () {
        this._timer.stop();
    }

    @action.bound
    public async loadData () {
        const dataRequest = ApiService.getTypedData<SelfTestResult[]>(ApiUrls.MonitoringUrl, null);
        this.dataLoading.subscribe(dataRequest);

        this.data = (await dataRequest).data;
    }

    @computed
    public get color () {
        if (!this.dataLoading.isCompleted) return 'secondary';
        if (!this.data) return 'danger';

        if (this.data.some((x) => x.status === Status.fail)) return 'danger';
        if (this.data.some((x) => x.status === Status.warning)) return 'warning';

        return 'success';
    }
}

import * as React from 'react';
import { action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Button, FormFeedback } from 'reactstrap';
import DateTimeField from '@1stquad/react-bootstrap-datetimepicker';

import { DateTime } from '@classes/DateTime';
import { DateTimeService } from '@services/DateTimeService';

import { Icon } from '../Icon';
import { BaseFormModel } from '@services/BaseFormModel';
import './_date-time-controls.scss';

type ITimePickerControlProps = {
    value: Date | string;
    disabled?: boolean;
    defaultValue?: Date;
    showReset?: boolean;
    onControlClick?: Function;
    onChange?: (time: Date, timeString: string) => void;
};

@observer
export class TimePickerControl extends React.Component<ITimePickerControlProps> {

    constructor (props: ITimePickerControlProps) {
        super(props);
        makeObservable(this);
    }

    render () {
        const { value, disabled, defaultValue, onControlClick, onChange, showReset } = this.props;
        const step = 30;
        return (
            <div className="time-picker">
                <div className="time-picker-node">
                    <DateTimeField
                        dateTime={value}
                        mode="time"
                        size="sm"
                        disabled={disabled}
                        inputFormat={DateTime.timeFormat}
                        format={DateTime.timeFormat}
                        onChange={onChange}
                    />
                </div>
                <div className="time-picker-controls" hidden={disabled}>
                    <Button size="xs" onClick={() => onControlClick && onControlClick(value, -step)} color="primary">
                        <Icon name="minus"/>
                    </Button>
                    <Button size="xs" onClick={() => onControlClick && onControlClick(value, step)} color="primary">
                        <Icon name="plus"/>
                    </Button>
                    <Button size="xs" hidden={!showReset}
                            onClick={() => onControlClick && onControlClick(defaultValue, 0)} color="danger">
                        <Icon name="times"/>
                    </Button>
                </div>
            </div>
        );
    }
}

type DatePickerControlProps = {
    value: Date | string;
    disabled?: boolean;
    minDate?: Date;
    maxDate?: Date;
    pickerMode?: string;
    pickerSize?: string;
    daysOfWeekDisabled?: number[];
    onChange?: (time: Date, timeString: string) => void;
    onBlur?: Function;
    onEnter?: Function;
    showInputIcon?: boolean;
};

@observer
export class DatePickerControl extends React.Component<DatePickerControlProps> {
    private _isManualEditing = false;
    private _currentDate: Date | null = null;

    constructor (props: DatePickerControlProps) {
        super(props);
        makeObservable(this);
    }

    componentDidUpdate() {
        this._updateProps();
    }

    private _updateProps() {
        const { pickerMode, value } = this.props;
        const dateTimeValue = typeof value === 'string'
            ? DateTimeService.parse(value, this._getPickerFormat(pickerMode))
            : value;

        if (DateTimeService.isValidDate(dateTimeValue)) {
            this._currentDate = dateTimeValue;
        }
    }

    render () {
        const {
            value,
            disabled,
            minDate,
            maxDate,
            onBlur,
            onEnter,
            pickerMode,
            pickerSize,
            daysOfWeekDisabled,
            showInputIcon
        } = this.props;
        const minimalDate = minDate && DateTimeService.isValidDate(minDate) ? minDate : DateTimeService.parse(DateTime.minDate, DateTime.viewDateFormat);
        const maximalDate = maxDate && DateTimeService.isValidDate(maxDate) ? maxDate : undefined;
        return (
            <DateTimeField
                dateTime={value}
                inputFormat={this._getPickerFormat(pickerMode)}
                format={this._getPickerFormat(pickerMode)}
                onChange={this._onDateChanged}
                onBlur={onBlur ? onBlur : undefined}
                onEnterKeyDown={onEnter ? onEnter : undefined}
                mode={pickerMode ? pickerMode : 'date'}
                size={pickerSize ? pickerSize : 'sm'}
                minDate={minimalDate}
                maxDate={maximalDate}
                disabled={disabled}
                daysOfWeekDisabled={daysOfWeekDisabled ? daysOfWeekDisabled : []}
                showInputIcon={showInputIcon}
                inputProps={{
                    onFocus: this._onFocus,
                    onBlur: this._onBlur,
                }}
            />
        );
    }

    @action.bound
    private _onFocus() {
        this._isManualEditing = true;
    }

    @action.bound
    private _onBlur() {
        const { pickerMode, value } = this.props;

        if (this._isManualEditing && pickerMode === 'datetime' && this._currentDate) {
            const dateTime = this._currentDate;
            const dateFormat = this._getPickerFormat(pickerMode);
            const dateTimeValue = typeof value === 'string' ? value : DateTimeService.toCustomUiFormat(value, dateFormat);

            const timeIndex = (dateFormat as string).indexOf(DateTime.timeFormat);
            let timeValue = '';
            if (timeIndex > -1) {
                timeValue = dateTimeValue.slice(timeIndex, timeIndex + 4);
            }

            DateTimeHelper.changeTimeByTemplate(dateTime, timeValue);
            this._setDateValue(dateTime, timeValue);
        }

        this._isManualEditing = false;
    }

    @action.bound
    private _onDateChanged(dateTime: Date, value: string) {
        const { pickerMode } = this.props;

        if (this._isManualEditing && pickerMode === 'datetime' && this._currentDate) {
            if (DateTimeService.isValidDate(dateTime)) {
                dateTime.setUTCFullYear(this._currentDate.getUTCFullYear());
                dateTime.setUTCMonth(this._currentDate.getUTCMonth());
                dateTime.setUTCDate(this._currentDate.getUTCDate());
            }
        }

        this._setDateValue(dateTime, value);
    }

    @action
    private _setDateValue(dateTime: Date, val: string) {
        const { onChange } = this.props;

        const isValidDate = DateTimeService.isValidDate(dateTime);
        if (onChange && (isValidDate || !val)) {
            this._currentDate = isValidDate ? dateTime : null;
            onChange(dateTime, val);
        }
    }

    private _getPickerFormat (mode?: string): string {
        if (mode === 'datetime') {
            return DateTime.viewFullFormat;
        }
        if (mode === 'time') {
            return DateTime.timeFormat;
        }
        return DateTime.viewDateFormat;
    }
}

type TimePickerValidatedControlProps<T extends BaseFormModel> = {
    disabled?: boolean;
    defaultValue: string;
    showReset?: boolean;
    formModel: T;
    name: keyof T;
    onChange?: Function;
};

@observer
export class TimePickerValidatedControl<T extends BaseFormModel> extends React.Component<TimePickerValidatedControlProps<T>> {

    constructor (props: TimePickerValidatedControlProps<T>) {
        super(props);
        makeObservable(this);
    }

    render () {
        const { disabled, defaultValue, showReset, formModel, name } = this.props;
        const { [name]: fieldValueRaw } = formModel;
        const step = 30;
        let pickerClass = 'time-picker' + (!formModel.validated ? ' not-validated' : ' validated');
        if (!formModel.isValid(name)) {
            pickerClass += ' is-invalid';
        }

        if (typeof fieldValueRaw !== 'string') throw new Error(`Field ${name as string} is not string but ${typeof fieldValueRaw}`);
        const fieldValue = fieldValueRaw;

        return (
            <>
                <div className={pickerClass}>
                    <div className="time-picker-node">
                        <DateTimeField
                            dateTime={DateTimeService.parse(fieldValue, DateTime.timeFormat)}
                            hideInputIcon
                            inputFormat={DateTime.timeFormat}
                            format={DateTime.timeFormat}
                            onChange={this._onTimeChanged}
                            mode="time"
                            size="sm"
                            disabled={disabled}
                            inputProps={{
                                onBlur: this._onBlur,
                            }}
                        />
                    </div>
                    <div className="time-picker-controls" hidden={disabled}>
                        <Button size="xs" onClick={() => this._onTimeControlsClick(fieldValue, -step)} color="primary">
                            <Icon name="minus"/>
                        </Button>
                        <Button size="xs" onClick={() => this._onTimeControlsClick(fieldValue, step)} color="primary">
                            <Icon name="plus"/>
                        </Button>
                        <Button size="xs" hidden={!showReset} onClick={() => this._onTimeControlsClick(defaultValue, 0)}
                                color="danger">
                            <Icon name="times"/>
                        </Button>
                    </div>
                </div>
                <FormFeedback>
                    {formModel.errorFor(name).map((error: string) => (
                        <div key={name as string + error}>{error}</div>
                    ))}
                </FormFeedback>
            </>
        );
    }

    @action.bound
    private _onBlur () {
        const { formModel, name } = this.props;
        const dateTime = DateTimeService.today();
        const value = formModel.getValue(name) as unknown as string;
        const currentDateTime = DateTimeService.parse(value, DateTime.timeFormat);

        if (!DateTimeService.isValidDate(currentDateTime)) {
            DateTimeHelper.changeTimeByTemplate(dateTime, value as string);
            this._onTimeChanged(dateTime, value as string);
        }
    }

    @action.bound
    private _onTimeChanged (time: Date, value: string) {
        const { formModel, name, onChange } = this.props;
        formModel.setValue(name, DateTimeService.isValidDate(time) ? DateTimeService.toUiTime(time) : value);
        if (onChange && formModel.isValid(name)) {
            onChange(time);
        }
    }

    @action.bound
    private _onTimeControlsClick (time: string, minutes: number) {
        const { formModel, name, onChange } = this.props;
        const newTimeValue = DateTimeService.parseUiTime(DateTimeService.today(), time);
        const newTime = DateTimeService.isValidDate(newTimeValue) ? DateTimeService.addTime(newTimeValue, 0, minutes) : '';
        formModel.setValue(name, newTime ? DateTimeService.toUiTime(newTime) : '');
        if (onChange && formModel.isValid(name)) {
            onChange(newTime);
        }
    }
}

type DatePickerValidatedControlProps<T extends BaseFormModel> = {
    disabled?: boolean;
    minDate?: Date;
    maxDate?: Date;
    pickerMode?: string;
    pickerSize?: string;
    daysOfWeekDisabled?: number[];
    formModel: T;
    name: keyof T;
    viewMode?: string;
    availableDates?: Date[];
    onChange?: Function;
    onBlur?: Function;
    onEnter?: Function;
    autoFocus?: boolean;
    className?: string;
    clearable?: boolean;
    format?: DateTime;
    showInputIcon?: boolean;
};

@observer
export class DatePickerValidatedControl<T extends BaseFormModel> extends React.Component<DatePickerValidatedControlProps<T>, {}> {
    private _isManualEditing = false;
    private _currentDate: Date | null = null;

    constructor (props: DatePickerValidatedControlProps<T>) {
        super(props);
        makeObservable(this);
        this._updateProps();
    }

    componentDidUpdate () {
        this._updateProps();
    }

    private _updateProps () {
        const { pickerMode, formModel, name } = this.props;

        const { [name]: fieldValueRaw } = formModel;
        if (typeof fieldValueRaw !== 'string') throw new Error(`Field ${name as string} is not string but ${typeof fieldValueRaw}`);

        const dateTimeValue = DateTimeService.parse(fieldValueRaw, this._getPickerFormat(pickerMode));

        if (DateTimeService.isValidDate(dateTimeValue)) {
            this._currentDate = dateTimeValue;
        }
    }

    render () {
        const {
            disabled,
            minDate,
            maxDate,
            onBlur,
            onEnter,
            pickerMode,
            pickerSize,
            daysOfWeekDisabled,
            formModel,
            name,
            viewMode,
            availableDates,
            className,
            clearable,
            format,
            showInputIcon,
            autoFocus
        } = this.props;
        const minimalDate = minDate && DateTimeService.isValidDate(minDate) ? minDate : DateTimeService.parse(DateTime.minDate, DateTime.viewDateFormat);
        const maximalDate = maxDate && DateTimeService.isValidDate(maxDate) ? maxDate : undefined;
        const { [name]: fieldValueRaw } = formModel;
        if (typeof fieldValueRaw !== 'string') throw new Error(`Field ${name as string} is not string but ${typeof fieldValueRaw}`);
        const fieldValue = fieldValueRaw;
        const dateTimeValue = DateTimeService.parse(fieldValue, this._getPickerFormat(pickerMode));
        const dateTime = DateTimeService.isValidDate(dateTimeValue) ? dateTimeValue : fieldValue;
        const validationClass = formModel.invalidFields.includes(name as string) || (!DateTimeService.isValidDate(dateTimeValue) && !!fieldValue) ? 'is-invalid' : '';
        const cls = className ? [formModel.formValidatedClass, className] : [formModel.formValidatedClass];
        return (
            <div className={cls.join(' ')} style={{ position: 'relative' }}>
                <DateTimeField
                    dateTime={dateTime}
                    showInputIcon={showInputIcon}
                    inputFormat={format ?? this._getPickerFormat(pickerMode)}
                    format={format ?? this._getPickerFormat(pickerMode)}
                    onChange={this._onDateChanged}
                    onBlur={onBlur ? onBlur : undefined}
                    onEnterKeyDown={onEnter ? onEnter : undefined}
                    mode={pickerMode ? pickerMode : 'date'}
                    viewMode={viewMode ? viewMode : 'date'}
                    size={pickerSize ? pickerSize : 'sm'}
                    minDate={minimalDate}
                    maxDate={maximalDate}
                    disabled={disabled}
                    daysOfWeekDisabled={daysOfWeekDisabled ? daysOfWeekDisabled : []}
                    validationClass={validationClass}
                    availableDates={availableDates}
                    inputProps={{
                        autoFocus: autoFocus,
                        onFocus: this._onFocus,
                        onBlur: this._onBlur,
                    }}
                />
                {clearable && fieldValue && (
                    <svg onClick={this._onClearDate} height="13" width="13" viewBox="0 0 20 20" aria-hidden="true"
                         focusable="false" className="date-time-picker-clear-box">
                        <path
                            d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"/>
                    </svg>
                )}
                {formModel.validated && (
                    <FormFeedback>
                        {formModel.errorFor(name).map((error: string) => (
                            <div key={name as string + error}>{error}</div>
                        ))}
                    </FormFeedback>
                )}
            </div>
        );
    }

    @action.bound
    private _onFocus () {
        this._isManualEditing = true;
    }

    @action.bound
    private _onBlur () {
        const { pickerMode, formModel, format, name } = this.props;

        if (this._isManualEditing && pickerMode === 'datetime' && format === DateTime.timeFormat && this._currentDate) {
            const dateTime = this._currentDate;
            const value = formModel.getValue(name) as unknown as string;

            const dateFormat = format ?? this._getPickerFormat(pickerMode);
            const timeIndex = (dateFormat as string).indexOf(DateTime.timeFormat);
            let timeValue = '';
            if (timeIndex > -1) {
                timeValue = value.slice(timeIndex, timeIndex + 4);
            }

            DateTimeHelper.changeTimeByTemplate(dateTime, timeValue);
            this._setDateValue(dateTime, timeValue);
        }

        this._isManualEditing = false;
    }

    @action.bound
    private _onClearDate () {
        const { formModel, name, onChange } = this.props;
        formModel.setValue(name, '');
        if (onChange) {
            onChange();
        }
    }

    @action.bound
    private _onDateChanged (dateTime: Date, value: string) {
        const { format, pickerMode } = this.props;

        if (this._isManualEditing && pickerMode === 'datetime' && format === DateTime.timeFormat && this._currentDate) {
            if (DateTimeService.isValidDate(dateTime)) {
                dateTime.setUTCFullYear(this._currentDate.getUTCFullYear());
                dateTime.setUTCMonth(this._currentDate.getUTCMonth());
                dateTime.setUTCDate(this._currentDate.getUTCDate());
            }
        }

        this._setDateValue(dateTime, value);
    }

    @action
    private _setDateValue (dateTime: Date, value: string) {
        const { pickerMode, formModel, name, onChange } = this.props;

        formModel.setValue(name, DateTimeService.isValidDate(dateTime) ? DateTimeService.toCustomUiFormat(dateTime, this._getPickerFormat(pickerMode)) : value);
        const isValidField = formModel.isValid(name);
        const isValidDate = DateTimeService.isValidDate(dateTime);
        if (onChange && (isValidField || isValidDate)) {
            if (DateTimeService.isValidDate(dateTime)) {
                this._currentDate = dateTime;
            }
            onChange(dateTime, this._isManualEditing);
        }
    }

    private _getPickerFormat (mode?: string): string {
        if (mode === 'datetime') {
            return DateTime.viewFullFormat;
        }
        if (mode === 'time') {
            return DateTime.timeFormat;
        }
        return DateTime.viewDateFormat;
    }
}

class DateTimeHelper {
    public static changeTimeByTemplate (date: Date, value: string) {
        if (value.length === 4 && new RegExp('[0-9]{4}').test(value)) { // 0600 => 06:00
            const hours = +value.substring(0, 2);
            const min = +value.substring(2, 4);
            if (hours >= 0 && hours < 24 && min >= 0 && min < 60) {
                date.setUTCHours(hours, min, 0, 0);
            }
        } else if (value.length === 4 && new RegExp('[0-9]{1}:[0-9]{2}').test(value)) { // 6:00 => 06:00
            const hours = +value.substring(0, 1);
            const min = +value.substring(2, 4);
            if (hours >= 0 && hours <= 9 && min >= 0 && min < 60) {
                date.setUTCHours(hours, min, 0, 0);
            }
        }
    }
}
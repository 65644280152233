import * as React from "react";
import {observer} from "mobx-react";
import {action, computed, makeObservable, observable} from "mobx";

import axios, {CancelTokenSource} from "axios";
import {
    AutoSizer,
    CellMeasurer,
    CellMeasurerCache,
    Column,
    Index,
    InfiniteLoader,
    Table,
    TableCellProps,
} from "react-virtualized";

import {PromiseCompletion} from "@classes/PromiseCompletion";
import {FilterState} from "@classes/FilterState";
import {DateTimeService} from "@services/DateTimeService";
import ApiService from "@app/Services/ApiService";
import {Application, ToiSearchModel, ToiSearchResult, ToiShortModel} from "@app/Models/WebApiModels";
import {UrlService} from "@services/UrlService";
import {ModalButtonType, modalService} from "@components/Modal/Modal";

import {Loading} from "@components/Loading/Loading";
import {NoteItem} from "./NoteItem/NoteItem";
import './_virtualized-toi-list.scss'
import {CreateEditToiDialog} from "@components/CreateEditToiDialog/CreateEditToiDialog";

export type VirtualizedToiListProps = {
    filters: FilterState;
};

@observer
export class VirtualizedToiList extends React.Component<VirtualizedToiListProps> {
    private _store: VirtualizedToiListStore = new VirtualizedToiListStore();
    private readonly _cellMeasurerCache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 50,
        minHeight: 20,
    });

    constructor(props: VirtualizedToiListProps) {
        super(props);
        makeObservable(this);
        this._store.updateProps(props);
    }

    componentDidMount(): void {
        void this._getData();
    }

    private async _getData() {
        const id =UrlService.getParam('id');
        await this._store.loadMoreToi();
        if (id && !this._store.toi.find(t => t.id === id)) {
            const res = await modalService.show(CreateEditToiDialog, {id: id});
            switch (res.button) {
                case ModalButtonType.Delete:
                case ModalButtonType.Save:
                case ModalButtonType.Cancel: {
                    UrlService.clearParams()
                    break;
                }
            }
        }

    }

    componentDidUpdate(prevProps: VirtualizedToiListProps) {
        if (JSON.stringify(prevProps.filters) !== JSON.stringify(this.props.filters)) {
            this._store.updateProps(this.props);
            void this._store.reloadToi();
        }
    }

    render() {
        const {rowCount, loader, loadMoreToi} = this._store;

        return (
            <>
                <InfiniteLoader
                    isRowLoaded={this._isRowLoaded}
                    loadMoreRows={loadMoreToi}
                    rowCount={rowCount}
                >
                    {({onRowsRendered, registerChild}) => (
                        <AutoSizer>
                            {({width, height}) => {
                                this._cellMeasurerCache.clearAll();
                                return (
                                    <div style={{width: width}}>
                                        <Table
                                            ref={registerChild}
                                            id={"id"}
                                            deferredMeasurementCache={this._cellMeasurerCache}
                                            headerHeight={0}
                                            height={height}
                                            gridClassName="toi-list"
                                            onRowsRendered={onRowsRendered}
                                            noRowsRenderer={this._noRowsRenderer}
                                            overscanRowCount={3}
                                            rowHeight={this._cellMeasurerCache.rowHeight}
                                            rowGetter={this._rowGetter}
                                            rowCount={rowCount}
                                            width={width}
                                        >
                                            <Column
                                                dataKey="toi"
                                                label={""}
                                                cellRenderer={this._cellRender}
                                                width={width}
                                            />
                                        </Table>
                                    </div>
                                );
                            }}
                        </AutoSizer>
                    )}
                </InfiniteLoader>
                {loader.isPending && <Loading loading={loader.isPending}/>}
            </>
        );
    }

    private _rowGetter = (info: Index) => ({toi: this._store.toi[info.index]});

    private _isRowLoaded = ({index}: Index) => {
        return !!this._store.toi[index];
    };

    private _noRowsRenderer = () => {
        return <span className="no-data">No data</span>;
    };

    private _cellRender = (data: TableCellProps) => {
        const {filterState, reloadToi} = this._store;
        const value = data.cellData ? (
            <div className="toi-item-wrapper">
                <NoteItem item={data.cellData} search={filterState.search} reloadToi={reloadToi}/>
            </div>
        ) : (
            <></>
        );
        return this._getCellMeasurerCell(data, value);
    };

    private _getCellMeasurerCell(
        data: TableCellProps,
        value: JSX.Element | JSX.Element[]
    ) {
        return (
            <CellMeasurer
                cache={this._cellMeasurerCache}
                columnIndex={data.columnIndex}
                key={data.dataKey}
                parent={data.parent}
                rowIndex={data.rowIndex}
            >
                <div>{value}</div>
            </CellMeasurer>
        );
    }
}

class VirtualizedToiListStore {
    public loader: PromiseCompletion = new PromiseCompletion();
    private _cancellationToken: CancelTokenSource = axios.CancelToken.source();

    @observable pages: { toi: ToiShortModel[], page: number }[] = [];
    @observable filterState: FilterState;
    @observable isMoreToiAvailable = true;

    page: number = 0;
    pageSize: number = 25;

    constructor() {
        makeObservable(this);
    }

    @action
    public updateProps(props: VirtualizedToiListProps) {
        this.filterState = props.filters;
        this.isMoreToiAvailable = true;
        this.page = 0;
        this.pages = [];
    }

    @action.bound
    public cancel() {
        this._cancellationToken.cancel();
        this._cancellationToken = axios.CancelToken.source();
    }

    @action.bound
    public async loadMoreToi() {
        if (!this.isMoreToiAvailable) return;
        this.page += 1;
        await this._loadMoreToi();
    }

    @action.bound
    public async reloadToi() {
        this.cancel();
        this.isMoreToiAvailable = true;
        this.page = 1;
        this.pages = [];
        await this._loadMoreToi();
    }

    @action.bound
    private async _loadMoreToi() {
        const searchModel: ToiSearchModel = {
            includeInactive: !this.filterState.isActive,
            airportFourLetterCodes: this.filterState.airports?.filter(x => !!x.fourLetterCode).map(x => x.fourLetterCode!) ?? undefined,
            countryThreeLetterCodes: this.filterState.countries?.map(x => x.threeLetterCode ?? '')?.filter(x => !!x) ?? undefined,
            fromUtc: this.filterState.dateFrom ? DateTimeService.parseUiDate(this.filterState.dateFrom) : undefined,
            toUtc: this.filterState.dateTo ? DateTimeService.parseUiDate(this.filterState.dateTo) : undefined,
            keyWord: this.filterState.clearedSearch ? this.filterState.clearedSearch : undefined,
            categoryIds: this.filterState.categories?.map(x => x.id).filter(x => !!x),
            regionIds: this.filterState.regions?.map(x => x.id).filter(x => !!x) ?? undefined,
            page: this.page,
            pageSize: this.pageSize,
            flightIds: this.filterState.flightIds?.filter(x => !!x),
            flights: this.filterState.flights?.filter(x => !!x),
            flightsDepartureDate: this.filterState.flightsDepartureDate,
            aircraftIds: this.filterState.aircrafts?.map(x => x.id).filter(x => !!x) ?? undefined,
            aircraftTypes: this.filterState.aircraftTypes?.filter(x => !!x) ?? undefined,
            departments: this.filterState.departments?.filter(x => !!x),
            person: this.filterState.person?.id,
            applicableFor: this.filterState.applicableFor as Application[],
            includeGlobal: true,
            isForCabin: false,
            isForCockpit: false,
            forLxFlight: this.filterState.forLxFlight
        };

        const {data} = await ApiService.postTypedData<ToiSearchResult>('/api/toi/search', searchModel, {
            completion: this.loader,
            cancellationToken: this._cancellationToken.token
        });
        this.toi.push(...data.items ?? []);
        this.pages.push({toi: data.items ?? [], page: searchModel.page!});
        this.isMoreToiAvailable = data.items?.length === this.pageSize;
        this.filterState.uncoveredTerms = data.uncoveredTerms ?? [];
    }

    @computed
    get toi() {
        return ([] as ToiShortModel[]).concat(...this.pages.slice().sort((a, b) => a.page - b.page).map(x => x.toi));
    }

    @computed
    get rowCount() {
        return this.toi.length ? this.toi.length + 1 : 0;
    }
}
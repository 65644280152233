import React from "react";
import { observer } from "mobx-react";
import { makeObservable } from "mobx";
import { CardBody } from "reactstrap";

import { Loading } from "./Loading";

type CardBodyLoadingProps = {
    className?: string;
    loading: boolean;
};

@observer
export class CardBodyLoading extends React.Component<CardBodyLoadingProps, {}> {

    constructor(props: CardBodyLoadingProps) {
        super(props);
        makeObservable(this);
    }

    render() {
        const { loading, className } = this.props;
        return (
            <CardBody className={className}>
                <Loading loading={loading} fullpage={false} small />
            </CardBody>
        );
    }
}
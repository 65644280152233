import React from 'react';
import { observer } from 'mobx-react';
import { DateTimeService } from '@app/Services/DateTimeService';
import { VirtualHorizontalExpander } from './VirtualHorizontalExpander';
import { GantZoom, getDayWidth, getZoomMultiplier } from './FlightsGanttTypes';

export type DrawDayCounterHandler = (date: Date) => number | null;
export type DrawDayExtensionsHandler = (date: Date) => JSX.Element | null;
export type DrawMonthExtensionsHandler = (date: Date) => JSX.Element | null;
export type DrawDayCssClassNameHandler = (date: Date) => string;

type VirtualDaysListProps = {
    style: React.CSSProperties;
    days: Date[];
    virtualWidth: number;
    scrollLeft: number;
    zoom: GantZoom;
};

const nowLabelWidth = 30;

@observer
export class VirtualDaysList extends React.Component<VirtualDaysListProps, {}> {
    render() {
        const { style, days, virtualWidth, zoom } = this.props;
        const dates: Array<JSX.Element> = [];
        const today = DateTimeService.today();
        const now = DateTimeService.now();

        const dayWidth = getDayWidth(zoom);
        const pixelInMinute = dayWidth / (24 * 60);

        days.forEach(day => {
            let className = 'flight-day';

            const isToday = DateTimeService.isSameDate(day, today);
            if (isToday) {
                className += ' current';
            }

            const time = day.getTime() / (1000 * 24 * 60 * 60);
            const isEven = time % 2 === 1;

            if (isEven) {
                className += ' even';
            }

            dates.push(
                <div key={day.getTime()} className={className} style={{ width: dayWidth }}>
                    <div className="day-title">{DateTimeService.format(day, 'DD.MM.YYYY')}</div>
                    <div className="day-hours">
                        {this._renderCaptionHours(isToday)}
                        {isToday && (
                            <div className="now-label" style={{ left: DateTimeService.diffMinutes(now, today) * pixelInMinute - nowLabelWidth / 2 }}>
                                {DateTimeService.format(now, 'HH:mm')}
                            </div>
                        )}
                    </div>
                </div>
            );
        });

        return (
            <div className="virtual-list flights-days">
                <VirtualHorizontalExpander virtualWidth={virtualWidth} />
                <div className="virtual-list-items" style={style}>
                    {dates}
                </div>
            </div>
        );
    }

    private _renderCaptionHours(isToday: boolean) {
        const nowHours = DateTimeService.now().getUTCHours();
        let hours: Array<JSX.Element> = [];
        for (let i = 1; i <= 24; i++) {
            const showHour = (i < 10 ? '0' : '') + i;
            let className = 'caption-hour';

            if (isToday && i === nowHours) {
                className += ' current';
            }

            hours.push(
                <div className={className} key={i} style={{ width: 30 * getZoomMultiplier(this.props.zoom) }}>
                    <span>{showHour}</span>
                </div>
            );
        }
        return hours;
    }
}
import { forwardRef, Ref } from 'react';
import { RegionDto } from '@app/Models/WebApiModels';
import { ServerSelect } from './ServerSelect';
import { OnChangeValue } from 'react-select';

type IcaoRegionPickerProps<IsMulti extends boolean> = {
    url: string;
    value?: RegionDto | RegionDto[];
    onChange?: (value?: OnChangeValue<RegionDto, IsMulti> | null) => void;
    isDisabled?: boolean;
    multi?: IsMulti;
};

function IcaoRegionPickerInternal<IsMulti extends boolean>(props: IcaoRegionPickerProps<IsMulti>, ref?: React.ForwardedRef<ServerSelect<RegionDto, IsMulti>>) {
    return <ServerSelect<RegionDto, IsMulti> {...props} ref={ref} keyField="id" labelField="displayName" />;
}

export const IcaoRegionPicker = forwardRef(IcaoRegionPickerInternal) as <IsMulti extends boolean>(
    props: IcaoRegionPickerProps<IsMulti> & {
        ref?: React.ForwardedRef<ServerSelect<RegionDto, IsMulti>>;
    }
) => ReturnType<typeof IcaoRegionPickerInternal>;

import { forwardRef, Ref } from 'react';
import { AircraftDto } from '@app/Models/WebApiModels';
import { ServerSelect } from './ServerSelect';
import { OnChangeValue } from 'react-select';

type AircraftPickerProps<IsMulti extends boolean> = {
    url: string;
    value?: AircraftDto | AircraftDto[];
    onChange?: (value?: OnChangeValue<AircraftDto, IsMulti> | null) => void;
    isDisabled?: boolean;
    multi?: IsMulti;
};

function AircraftPickerInternal<IsMulti extends boolean>(props: AircraftPickerProps<IsMulti>, ref?: React.ForwardedRef<ServerSelect<AircraftDto, IsMulti>>) {
    return <ServerSelect<AircraftDto, IsMulti> {...props} ref={ref} keyField="registrationNumber" labelField="registrationNumber" />;
}

export const AircraftPicker = forwardRef(AircraftPickerInternal) as <IsMulti extends boolean>(
    props: AircraftPickerProps<IsMulti> & {
        ref?: React.ForwardedRef<ServerSelect<AircraftDto, IsMulti>>;
    }
) => ReturnType<typeof AircraftPickerInternal>;

import * as React from 'react';
import { observer } from 'mobx-react';
import { action, makeObservable } from 'mobx';

import { PromiseCompletion } from '@classes/PromiseCompletion';
import { IModalDialogContent, ModalButtonType, ModalDialogOptions, ModalWindow } from '@components/Modal/Modal';
import { ToiForm } from '@app/Components/ToiForm/ToiForm';

export type CreateEditToiDialogProps = {
    id?: string;
    isDuplicate?: boolean;
    onSaveCallback?: () => void;
};

@observer
export class CreateEditToiDialog extends React.Component<CreateEditToiDialogProps> implements IModalDialogContent<void> {
    private _loader: PromiseCompletion = new PromiseCompletion();
    private _window: ModalWindow<void>;

    constructor(props: CreateEditToiDialogProps) {
        super(props);
        makeObservable(this);
    }

    @action
    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        this._window = window;
        const { id, isDuplicate } = this.props;
        return {
            title: id && !isDuplicate ? 'Edit TOI' : 'New TOI',
            buttons: [],
            disableAutoFocus: true,
            loader: this._loader,
            width: '950px'
        };
    }

    _onSaveCallback = () => {
        this.props.onSaveCallback?.();
        this._window.props.onClose(ModalButtonType.Save, undefined);
    }

    _onDeleteCallback = () => {
        this._window.props.onClose(ModalButtonType.Delete, undefined);
    }

    _onCancelCallback = () => {
        this._window.props.onClose(ModalButtonType.Cancel, undefined);
    }

    render() {
        return <ToiForm
            id={this.props.id}
            isDublicate={this.props.isDuplicate}
            loader={this._loader}
            onSaveCallback={this._onSaveCallback}
            onDeleteCallback={this._onDeleteCallback}
            onCancelCallback={this._onCancelCallback}
        />;
    }
}

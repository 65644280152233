import { AirportFilter, AirportModel } from '@app/Models/WebApiModels';
import { AsyncServerSelect } from './AsyncServerSelect';

type AirportPickerProps = {
    url: string;
    value?: AirportModel[] | null;
    onSelect?: (value?: AirportModel | AirportModel[]) => void;
    isDisabled?: boolean;
    multi?: boolean;
    filter?: AirportFilter;
    canClear?: boolean;
    size?: string;
};

export function AirportPicker(props: AirportPickerProps) {
    return <AsyncServerSelect<AirportModel, AirportFilter> {...props} getOptionValue={(o) => o.threeLetterCode ?? ''} getOptionLabel={(o) => `${o.threeLetterCode} (${o.name})`} />;
}

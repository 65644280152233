import { Application } from '@app/Models/WebApiModels';
import { ReactSelect } from './ReactSelect/ReactSelect';
import { OnChangeValue } from 'react-select';

type ApplicationPickerProps<IsMulti extends boolean> = {
    multi?: IsMulti;
    value?: string | string[];
    onChange: (value?: OnChangeValue<string, IsMulti> | null) => void;
    isDisabled?: boolean;
};

export function ApplicationPicker<IsMulti extends boolean>(props: ApplicationPickerProps<IsMulti>) {
    const { value, multi, onChange, ...rest } = props;

    const options = (Object.keys(Application) as Array<keyof typeof Application>).map((key) => key.toString());

    return <ReactSelect<string, IsMulti> value={value} options={options} multi={multi} placeholder="" isClearable onSelect={onChange} getOptionLabel={(x) => x} getOptionValue={(x) => x} {...rest} />;
}

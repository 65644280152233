import React from 'react';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

import { ApiUrls } from '@app/AppConstants';
import { FlightIdInfo } from '@app/Models/FlightScheduleModels';
import { BaseFormModel } from '@services/BaseFormModel';
import { DateTimeService } from '@services/DateTimeService';
import { displayName, isDate } from '@services/Validation';

import { DatePickerValidatedControl } from '@components/DateTimeControls/DateTimeControls';
import FlightDutyFilter from './FlightDutyFilter';

export class GantFilter {
    date: Date;
    filterText: string;
    showInactive: boolean;
    flightsUrl: string;
}

export class GantFilterForm extends BaseFormModel {
    @observable
    @isDate()
    date: string;

    @observable
    @displayName('Show Inactive')
    showInactive: boolean;

    @observable
    @displayName('Filter')
    filterText: string;

    @observable
    @displayName('Source Url')
    flightsUrl: string;

    constructor() {
        super();
        makeObservable(this);
        this.showInactive = false;
        this.filterText = '';
        this.date = DateTimeService.toUiDate(DateTimeService.today());
        this.flightsUrl = ApiUrls.FlightsFullUrl;
    }
}

type GantViewFilterProps = {
    defaultFilter: GantFilter;
    onChange: (filter: GantFilter) => void;
    onFlightFound: (id: string, flight: FlightIdInfo) => void;
    onDateChange: (date: Date) => void;
    onRefresh: () => Promise<void>;
};

@observer
export default class GantViewFilter extends React.Component<GantViewFilterProps>{
    private _filter: GantFilterForm;

    constructor(props: GantViewFilterProps) {
        super(props);
        makeObservable(this);
        const filter = props.defaultFilter;
        this._filter = new GantFilterForm();
        this._filter.date = DateTimeService.toUiDate(filter.date);
        this._filter.filterText = filter.filterText;
        this._filter.showInactive = filter.showInactive;
        this._filter.flightsUrl = filter.flightsUrl;
    }

    private _fireOnChange() {
        const { onChange } = this.props;

        const parsedDate = DateTimeService.parseUiDate(this._filter.date);
        const isValidDate = DateTimeService.isValidDate(parsedDate);
        onChange({
            date: isValidDate ? parsedDate : this.props.defaultFilter.date,
            filterText: this._filter.filterText,
            showInactive: this._filter.showInactive,
            flightsUrl: this._filter.flightsUrl
        });
    }

    render() {
        const { onFlightFound, onDateChange } = this.props;

        return (
            <div className="flights-filter">
                <DatePickerValidatedControl
                    pickerMode="date"
                    formModel={this._filter}
                    name="date"
                    onChange={(date: Date) => {
                        this._fireOnChange();
                        const isValidDate = date && DateTimeService.isValidDate(date);
                        isValidDate && onDateChange(DateTimeService.trimTime(date));
                    }}
                />

                <FlightDutyFilter
                    className="form-control form-control-sm flight-search"
                    value={this._filter.filterText}
                    onChange={value => {
                        this._filter.filterText = value;
                        this._fireOnChange();
                    }}
                    onFlightFound={onFlightFound}
                />
            </div>
        );
    }
}
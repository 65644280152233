import { DateTimeService } from '@app/Services/DateTimeService';

export type Range<T> = {
    from: T;
    to: T;
};

export enum RangeReadyStatus {
    None = 0,
    Loading = 1,
    Ready = 2,
    Error = 4
}

export class RangeComparer {
    static isEqualDate(r1: Range<Date> | null, r2: Range<Date> | null) {
        if (r1 === r2) return true;
        if (!r1 || !r2) return false;
        return DateTimeService.isSameDate(r1.from, r2.from) && DateTimeService.isSameDate(r1.to, r2.to);
    }

    static isInDateRange(r: Range<Date>, d: Date) {
        return r.from <= d && d <= r.to;
    }
}
import React from 'react';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

import ApiService from '@app/Services/ApiService';
import { ReactSelect } from './ReactSelect/ReactSelect';
import { GroupBase, OnChangeValue } from 'react-select';

type ServerSelectProps<T, IsMulti extends boolean> = {
    url: string;
    value?: T | T[];
    isDisabled?: boolean;
    multi?: IsMulti;
    onChange?: (value?: OnChangeValue<T, IsMulti> | null) => void;
    keyField?: keyof T;
    labelField?: keyof T;
    groupField?: keyof T;
    getOptionLabel?: (option: T) => string;
    getOptionValue?: (option: T) => string;
};

@observer
export class ServerSelect<T, IsMulti extends boolean = false> extends React.Component<ServerSelectProps<T, IsMulti>> {
    @observable private _store: ServerSelectStore<T>;

    constructor(props: ServerSelectProps<T, IsMulti>) {
        super(props);
        makeObservable(this);

        this._store = new ServerSelectStore<T>(props.url, this.props.keyField, this.props.labelField, this.props.groupField);
    }

    render() {
        const { value, isDisabled, onChange, multi, getOptionLabel, getOptionValue, keyField, labelField } = this.props;

        return <ReactSelect<T, IsMulti> value={value} options={this._store.options} placeholder="" isSearchable isClearable isDisabled={isDisabled} multi={multi} onSelect={onChange} keyField={keyField} labelField={labelField} getOptionLabel={getOptionLabel} getOptionValue={getOptionValue} />;
    }

    public reload() {
        this._store.reload();
    }
}

export class ServerSelectStore<TOption> {
    private _url: string;

    @observable private _keyField?: keyof TOption;
    @observable private _labelField?: keyof TOption;
    @observable private _groupField?: keyof TOption;

    @observable public options: (TOption | GroupBase<TOption>)[] = [];

    constructor(
        url: string,
        keyField: keyof TOption | undefined = undefined,
        labelField: keyof TOption | undefined = undefined,
        groupField: keyof TOption | undefined = undefined
    ) {
        makeObservable(this);

        this._url = url;
        this._keyField = keyField;
        this._labelField = labelField;
        this._groupField = groupField;

       
        void this.reload();
    }

    @action
    public async reload() {
        const { data } = await ApiService.getTypedData<TOption[]>(this._url, null);
        this.options = data;

        if (this._groupField) {
            this.options = ReactSelect.GenerateGroupedOptionsFrom(this.options as TOption[], this._keyField, this._labelField, this._groupField);
        }
    }

}

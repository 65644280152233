import React from 'react';

type TimeoutTimerProps = {
    className?: string;
    style?: React.CSSProperties;
};

export class TimeoutTimer extends React.PureComponent<TimeoutTimerProps, {}> {
    render() {
        const { className, style } = this.props;
        return (
            <div className={`timeout-wrapper ${className}`} style={style}>
                <div className="timer-slot">
                    <div className="timer-lt" />
                </div>
                <div className="timer-slot">
                    <div className="timer-rt" />
                </div>
            </div>
        );
    }
}
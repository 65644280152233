import { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { ApiUrls } from '@app/AppConstants';
import { FilterState } from '@classes/FilterState';
import { PromiseCompletion } from '@classes/PromiseCompletion';

import ApiService from '@app/Services/ApiService';
import { DownloadService } from '@services/DownloadService';
import { service } from '@services/_ServicesRegister';

import { Icon } from '@components/Icon';
import { VirtualizedToiList } from '@components/VirtualizedToiList/VirtualizedToiList';

type ToiListProps = {
    selectedFlightIds: string[];
};

export const ToiList = observer(({ selectedFlightIds }: ToiListProps) => {
    const filterState = useMemo(() => new FilterState({ flightIds: selectedFlightIds }), [selectedFlightIds]);
    const printLoader: PromiseCompletion = new PromiseCompletion();

    const downloadService: DownloadService = service.resolve(DownloadService);
    const [isDropdownOpen, setIsDropdowmOpen] = useState<boolean>(false);

    const onToggleDropdown = () => {
        setIsDropdowmOpen(!isDropdownOpen);
    };

    const onClickDownloadPdf = () => {
        const url = ApiUrls.ToiPdfUrl + `?flightIds=${selectedFlightIds.join(',')}`;
        void downloadService.downloadFile(url);
    };

    const onClickPrintPdf = async () => {
        const url = ApiUrls.ToiPdfUrl + `?flightIds=${selectedFlightIds.join(',')}`;
        const { data } = await ApiService.getTypedData<Buffer>(url, null, { responseType: 'blob' });
        const pdfData = await (data as unknown as { arrayBuffer: () => Promise<ArrayBuffer> }).arrayBuffer();

        const blob = new Blob([pdfData], { type: 'application/pdf' });

        printLoader.subscribe(new Promise<void>((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => {
                    const iframe = document.createElement('iframe');
                    iframe.className = 'pdfIframe';
                    document.body.appendChild(iframe);
                    iframe.style.display = 'none';
                    iframe.onload = () => {
                        setTimeout(() => {
                            iframe.focus();
                            iframe.contentWindow?.print()
                            URL.revokeObjectURL(reader.result!.toString());
                        }, 1);
                        resolve();
                    };
                    iframe.onerror = reject;
                    
                    const blobUrl = URL.createObjectURL(blob);
                    iframe.src = blobUrl;
                },
                false
            )
            reader.readAsDataURL(blob);
        }));
    };

    const printingDisabled = printLoader.isPending;

    return (
        <div className="h-100">
            <ButtonDropdown className="print-btn" isOpen={isDropdownOpen} toggle={onToggleDropdown}>
                <Button id="caret" color="success" onClick={onClickPrintPdf} disabled={printingDisabled}>
                    <Icon name="print" /> Print
                </Button>
                <DropdownToggle color="success" caret disabled={printingDisabled} />
                <DropdownMenu>
                    <DropdownItem onClick={onClickDownloadPdf} color="secondary">
                        <Icon name="file-pdf" /> Download
                    </DropdownItem>
                </DropdownMenu>
            </ButtonDropdown>
            <VirtualizedToiList filters={filterState} />
        </div>
    );
});
export default class ArrayHelper{
    
    public static isSameDateArray(value1: Date[], value2: Date[]) {
        if (!value1 && value2 || value1 && !value2)
            return false;

        if (!value1 && !value2)
            return true;

        if (value1.length !== value2.length)
            return false;

        for (let i = 0; i < value1.length; i++) {
            if (value1[i].getTime() !== value2[i].getTime())
                return false;
        }
        return true;
    }

    public static distinctBy<T, TKey>(array: T[], mapFn: (el: T) => TKey) {
        const itemsMap = new Map<TKey, T>();
        array.forEach(el => {
            const key = mapFn(el);
            const hasItem = itemsMap.has(key);
            if(!hasItem){
                itemsMap.set(key, el);
            }
        });

        return [...itemsMap.values()];
    }
}
import React from 'react';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

import { AircraftDto, FlightDto, MaintenanceDto } from '@app/Models/FlightScheduleModels';
import { appStore } from '@app/AppStore';

import { GantViewOnSelectedEvent } from '@components/FlightsGanttView/FlightsGanttView';
import { FlightSchedule } from '@components/FlightSchedule/FlightSchedule';
import { ToiList } from './ToiList';
import "./_flight-schedule-page.scss";

@observer
export default class FlightSchedulePage extends React.Component<{}> {
    @observable private _selectedFlightIds: string[] = [];

    private _splitterActive: number | null;
    private readonly _wrapperNode: React.RefObject<HTMLDivElement> = React.createRef();
    private readonly _scheduleNode: React.RefObject<HTMLDivElement> = React.createRef();
    private readonly _flightEditNode: React.RefObject<HTMLDivElement> = React.createRef();
    private readonly _splitterNode: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: {}) {
        super(props);
        makeObservable(this);
    }

    render() {
        const splitterPosition = appStore.getValue<number>('splitter') || 60;

        return (
            <div className="flights-wrapper"
                onMouseUp={this._mouseUpHandler}
                onMouseMove={this._splitterMouseMoveHandler}
                onMouseLeave={this._inActivateSplitter}
                ref={this._wrapperNode}
            >
                <div className="flights-tab-content" ref={this._scheduleNode} style={(splitterPosition) ? { height: splitterPosition + '%' } : {}}>
                    <FlightSchedule
                        allowMultiSelect
                        allowContextMenu
                        onGantItemSelected={this._onGantItemSelected}
                    />
                </div>
                <div className="flights-splitter"
                    style={(splitterPosition) ? { top: splitterPosition + '%' } : {}}
                    onMouseDown={this._splitterMouseDownHandler}
                    ref={this._splitterNode}
                />

                <div className="flights-edit"
                    style={(splitterPosition) ? { height: (100 - splitterPosition) + '%' } : {}}
                    ref={this._flightEditNode}
                >
                    <div className="collapse-expand-edit" />
                    <div className="flight-notes">
                        {!!this._selectedFlightIds.length && <ToiList selectedFlightIds={this._selectedFlightIds} />}
                    </div>
                </div>
            </div>
        );
    }

    @action.bound
    private _onGantItemSelected(e: GantViewOnSelectedEvent<FlightDto, AircraftDto, MaintenanceDto>) {
        this._selectedFlightIds = e.selectedItemIds;
    }

    @action.bound
    private _splitterMouseDownHandler(event: React.MouseEvent<Element>) {
        const splitterNode = this._splitterNode.current;
        if (splitterNode) {
            this._splitterActive = event.clientY - splitterNode.getBoundingClientRect().top;
            splitterNode.classList.add('active');
            event.stopPropagation();
            event.preventDefault();
        }
    }

    @action.bound
    private _splitterMouseMoveHandler(event: React.MouseEvent<Element>) {
        const wrapperNode = this._wrapperNode.current;
        const scheduleNode = this._scheduleNode.current;
        const splitterNode = this._splitterNode.current;
        const flightEditNode = this._flightEditNode.current;

        if (this._splitterActive !== null && wrapperNode && scheduleNode && splitterNode && flightEditNode) {
            let newPos = (event.clientY - wrapperNode.getBoundingClientRect().top - this._splitterActive) / wrapperNode.offsetHeight * 100;
            if (newPos < 30) newPos = 30;
            if (newPos > 80) newPos = 80;
            appStore.setValue('splitter', newPos);
            scheduleNode.style.height = newPos + '%';
            splitterNode.style.top = newPos + '%';
            flightEditNode.style.height = (100 - newPos) + '%';
        }
    }

    @action.bound
    private _inActivateSplitter() {
        if (this._splitterActive !== null) {
            this._splitterActive = null;
            const splitterNode = this._splitterNode.current;
            if (splitterNode) {
                splitterNode.classList.remove('active');
                window.dispatchEvent(new Event('resize'));
            }
        }
    }

    @action.bound
    private _mouseUpHandler() {
        this._inActivateSplitter();
    }
}

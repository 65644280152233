import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'reactstrap';

import { DateTimeService } from '@services/DateTimeService';
import { extensions } from '@services/Extensions';
import { FilterState } from '@classes/FilterState';

import { Icon } from '@components/Icon.tsx';
import { DatePickerControl } from '@components/DateTimeControls/DateTimeControls.tsx';
import { FormCheckbox, FormInput } from '@components/FormControls/FormControls';
import { CountryPicker } from '@components/CountryPicker';
import { AirportPicker } from '@components/AirportPicker';
import { AircraftPicker } from '@components/AircraftPicker';
import { AircraftTypePicker } from '@components/AircraftTypePicker';
import { IcaoRegionPicker } from '@components/IcaoRegionPicker';
import { DepartmentPicker } from '@components/DepartmentPicker';
import { modalService } from '@components/Modal/Modal';
import { CreateEditToiDialog } from '@components/CreateEditToiDialog/CreateEditToiDialog';
import { QueryInput, QueryInputTermsMatches } from './QueryInput';
import { PersonPicker } from '@app/Components/PersonPicker';
import { CategoryPicker } from '@app/Components/CategoryPicker';
import { FlightViewDialog } from '@app/Components/Dialogs/FlightViewDialog';
import { FlightDto } from '@app/Models/FlightScheduleModels';
import { AircraftDto, AirportModel, CategoryDto, CountryItem, PrincipalModel, RegionDto } from '@app/Models/WebApiModels';
import { ApplicationPicker } from '@app/Components/ApplicationPicker';
import { DateTime } from '@app/Classes/DateTime';
import { ServerSelect } from '@app/Components/ServerSelect';

import './_filter-section.scss';

type FilterSectionProps = {
    filters: FilterState;
    onChangeFilters: (newFilterState: FilterState) => void;
};

export const FilterSection = observer(({ filters, onChangeFilters }: FilterSectionProps) => {
    const filterState = useMemo(() => new FilterState(filters), [filters]);
    const hasActiveFilters = JSON.stringify(filterState) !== JSON.stringify(new FilterState());

    const categoryPickerRef = React.createRef<ServerSelect<CategoryDto>>();
    const regionPickerRef = React.createRef<ServerSelect<RegionDto, true>>();
    const personPickerRef = React.createRef<ServerSelect<PrincipalModel>>();
    const countryPickerRef = React.createRef<ServerSelect<CountryItem, true>>();
    const aircraftPickerRef = React.createRef<ServerSelect<AircraftDto, true>>();
    const departmentPickerRef = React.createRef<ServerSelect<string, true>>();

    useEffect(() => {
        return () => {
            if (filterState.dateFrom && window.location.pathname === '/flight-schedule') {
                window.history.pushState('FlightSchedule', 'FlightSchedule', window.location.pathname + `?date=${DateTimeService.format(DateTimeService.parseUiDate(filterState.dateFrom), DateTime.jsonDateFormat)}`);
            }
        };
    }, []);

    const onAnyFieldChanged = useCallback(() => {
        onChangeFilters(filterState);
    }, [filterState, onChangeFilters]);

    const onClearFilters = () => {
        const emptyFilters = new FilterState();
        onChangeFilters(emptyFilters);
    };

    const handleQueryInputChange = (val: QueryInputTermsMatches | null | undefined) => {
        filterState.search = val ? val.source : '';
        filterState.clearedSearch = val?.clearedSource;
        filterState.flights = val?.matches['flight']?.map((x) => x.value);
        filterState.flightIds = val?.matches['flightId']?.map((x) => x.value);

        const departure = val?.matches['flightDeparture']?.[0];
        if (departure) {
            let departureDate: Date;
            const referenceDate = new Date();
            const referenceUtcDate = new Date(Date.UTC(referenceDate.getFullYear(), referenceDate.getMonth(), referenceDate.getDate(), 0, 0, 0));
            if (departure.value === 'today') {
                departureDate = referenceUtcDate;
            } else if (departure.value === 'tomorrow') {
                departureDate = DateTimeService.addDays(referenceUtcDate, 1);
            } else {
                departureDate = DateTimeService.addDays(referenceDate, -1);
            }

            filterState.flightsDepartureDate = departureDate;
        } else {
            filterState.flightsDepartureDate = undefined;
        }

        extensions.executeTimeout(onAnyFieldChanged, 500);
    };

    const showCreateToiDialog = async () => {
        void modalService.show(CreateEditToiDialog, {
            onSaveCallback: () => {
                [categoryPickerRef, regionPickerRef, countryPickerRef, aircraftPickerRef, departmentPickerRef, personPickerRef].forEach((x) => x.current?.reload());
            }
        });
    };

    const showFlightViewDialog = () => {
        void modalService.show(FlightViewDialog, {
            baseDate: filterState.dateFrom ? DateTimeService.parseUiDate(filterState.dateFrom) : undefined,
            onSelectFlight: (flight: FlightDto | null) => {
                if (!flight) return;

                filterState.flightNumRcd = `${flight.airlineRcd}-${flight.flightNumber} (${DateTimeService.toUiDate(flight.departureDateTimeUtc)})`.toLocaleUpperCase();
                filterState.flightIds = [flight.id];
                filterState.airlineRcd = flight.airlineRcd;
                filterState.flightNumber = flight.flightNumber;
                onAnyFieldChanged();
            }
        });
    };

    const clearFlightFilter = () => {
        filterState.flightNumRcd = '';
        filterState.flightIds = undefined;
        filterState.airlineRcd = undefined;
        filterState.flightNumber = undefined;
        onAnyFieldChanged();
    };

    const isFlightSelected = !!filterState.flightNumRcd || !!filterState.airlineRcd || !!filterState.flightNumber || (filterState.flightIds?.length ?? 0) > 0;

    return (
        <div className="filter-section">
            <div className="d-flex gap-3">
                <QueryInput value={filterState.search} onMatchedChange={handleQueryInputChange} uncoveredTerms={filters.uncoveredTerms} />
                <Button color="primary" className="add-new-btn" onClick={showCreateToiDialog}>
                    <Icon name="plus" className="mr-1" />
                    New
                </Button>
            </div>

            <div className="filter-wrap">
                <div>
                    <div className="label">From</div>
                    <DatePickerControl
                        disabled={isFlightSelected}
                        value={filterState.dateFrom}
                        onChange={(val) => {
                            filterState.dateFrom = DateTimeService.isValidDate(val) ? DateTimeService.toUiDate(val) : '';
                            console.log(JSON.stringify(val));
                            onAnyFieldChanged();
                        }}
                    />
                </div>
                <div>
                    <div className="label">To</div>
                    <DatePickerControl
                        disabled={isFlightSelected}
                        value={filterState.dateTo}
                        onChange={(val) => {
                            filterState.dateTo = DateTimeService.isValidDate(val) ? DateTimeService.toUiDate(val) : '';
                            onAnyFieldChanged();
                        }}
                    />
                </div>
            </div>
            <div>
                <div className="label">Category</div>
                <CategoryPicker
                    url="/api/category"
                    ref={categoryPickerRef}
                    isDisabled={isFlightSelected}
                    value={filterState.categories}
                    onChange={(val) => {
                        filterState.categories = val ? [val] : undefined;
                        onAnyFieldChanged();
                    }}
                />
            </div>
            <div>
                <div className="label">Region</div>
                <IcaoRegionPicker<true>
                    url="/api/region"
                    ref={regionPickerRef}
                    isDisabled={isFlightSelected}
                    value={filterState.regions}
                    onChange={(val) => {
                        const value = val as RegionDto[];
                        filterState.regions = value.length === 0 ? undefined : value;
                        onAnyFieldChanged();
                    }}
                    multi={true}
                />
            </div>
            <div>
                <div className="label">Country</div>
                <CountryPicker<true>
                    url="/api/country"
                    ref={countryPickerRef}
                    isDisabled={isFlightSelected}
                    value={filterState.countries}
                    onChange={(val) => {
                        filterState.countries = !val || val.length === 0 ? undefined : val as CountryItem[];
                        onAnyFieldChanged();
                    }}
                    multi
                />
            </div>
            <div>
                <div className="label">Airport</div>
                <AirportPicker
                    url="/api/airport"
                    isDisabled={isFlightSelected}
                    value={filterState.airports ?? undefined}
                    onSelect={(val) => {
                        const value = val as AirportModel[];
                        filterState.airports = value.length === 0 ? undefined : value;
                        onAnyFieldChanged();
                    }}
                    filter={{ includeDisabled: true, onlyInUse: false }}
                    multi
                />
            </div>
            <div className="filter-wrap">
                <div>
                    <div className="label">Aircraft type</div>
                    <AircraftTypePicker<true>
                        value={filterState.aircraftTypes}
                        onChange={(val) => {
                            filterState.aircraftTypes = !val || val.length === 0 ? undefined : val as string[];
                            onAnyFieldChanged();
                        }}
                        multi
                        isDisabled={isFlightSelected}
                    />
                </div>
                <div>
                    <div className="label">Aircraft registration</div>
                    <AircraftPicker<true>
                        url="/api/aircraft?onlyWk=true&onlyInUse=true"
                        ref={aircraftPickerRef}
                        isDisabled={isFlightSelected}
                        value={filterState.aircrafts}
                        onChange={(val) => {
                            filterState.aircrafts = !val || val.length === 0 ? undefined : val as AircraftDto[];
                            onAnyFieldChanged();
                        }}
                        multi
                    />
                </div>
            </div>
            <div className="filter-wrap">
                <div>
                    <div className="label">Department</div>
                    <DepartmentPicker<true>
                        url="/api/department"
                        ref={departmentPickerRef}
                        isDisabled={isFlightSelected}
                        value={filterState.departments}
                        onChange={(val) => {
                            filterState.departments = !val || val.length === 0 ? undefined : val as string[];
                            onAnyFieldChanged();
                        }}
                        multi
                    />
                </div>
                <div>
                    <div className="label">Person</div>
                    <PersonPicker<false>
                        url="/api/principal"
                        ref={personPickerRef}
                        isDisabled={isFlightSelected}
                        value={filterState.person}
                        onChange={(value) => {
                            filterState.person = !!value ? value : undefined;
                            onAnyFieldChanged();
                        }}
                    />
                </div>
            </div>
            <div className="filter-wrap">
                <div>
                    <div className="label">Applicable for</div>
                    <ApplicationPicker<false>
                        value={filterState.applicableFor}
                        isDisabled={isFlightSelected}
                        onChange={(application) => {
                            filterState.applicableFor = application ? [application] : undefined;
                            onAnyFieldChanged();
                        }}
                    />
                </div>
                <div>
                    <div className="label">Leg</div>
                    <div className="w-100">
                        <div className="d-flex gap-2 align-items-center">
                            <FormInput formModel={filterState} name="flightNumRcd" hideValidationErrors disabled />
                            {filterState.flightNumRcd && <Icon name="close" title="Clear flight" className="select-flight-icon" onClick={() => clearFlightFilter()} />}
                            <Icon name="pen-to-square" title="Select leg from schedule" className="select-flight-icon" onClick={() => showFlightViewDialog()} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between">
                <div className="d-flex" style={{ gap: '16px' }}>
                    <FormCheckbox
                        disabled={isFlightSelected}
                        label="Active"
                        checked={filterState.isActive}
                        changeHandler={() => {
                            filterState.isActive = !filterState.isActive;
                            onAnyFieldChanged();
                        }}
                    />

                    <FormCheckbox
                        disabled={isFlightSelected}
                        label="LX Flight"
                        checked={filterState.forLxFlight}
                        changeHandler={() => {
                            filterState.forLxFlight = !filterState.forLxFlight;
                            onAnyFieldChanged();
                        }}
                    />
                </div>
                {hasActiveFilters && (
                    <span className="reset-filters" title="Clear filters" onClick={onClearFilters}>
                        <Icon name="xmark" /> Reset
                    </span>
                )}
            </div>
        </div>
    );
});

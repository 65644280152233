import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { CSSProperties, ChangeEvent, FormEvent } from 'react';
import { Icon } from '../Icon';

const styles: { [key: string]: CSSProperties } = {};

styles.wrapper = {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    maxWidth: '100%',
    minWidth: '100px',
    overflow: 'hidden',
    textAlign: 'left',
    verticalAlign: 'bottom',
};

styles.wrapperDefault = {
    ...styles.wrapper,
    border: '1px solid #ccc',
    backgroundColor: 'white',
};

styles.innerWrapper = {
    display: 'inline-block',
    overflowX: 'scroll',
    overflowY: 'hidden',
    position: 'relative',
    width: '100%',
    scrollbarWidth: 'none'
};

// Common style fro input and placeholder
const inputCommon: CSSProperties = {
    fontSize: '14px',
    fontFamily: 'sans-serif',
    boxSizing: 'border-box',
    borderWidth: '0',
    padding: '5px 0',
    lineHeight: '22px',
    // Stretch to fill wrapper
    minWidth: '100%',
    margin: '0',
};

styles.input = {
    ...inputCommon,
    position: 'relative',
    zIndex: 1,
    outline: 'none',
    caretColor: 'black',

    // Make transparent background and font to make placeholder visible
    backgroundColor: 'transparent',
    color: 'rgba(0,0,0,0)',
};

styles.stub = {
    display: 'block',
    position: 'fixed',
    left: '-10000px',
    opacity: 0,
    whiteSpace: 'pre',
};

styles.placeholder = {
    ...inputCommon,
    // Make movable
    position: 'absolute',
    // Move below input
    zIndex: 0,
    top: 0,
    left: 0,
    display: 'block',
    // Turn off double scrolling.
    overflow: 'hidden',
    // Set whitespace wrapping the same as input has.
    whiteSpace: 'pre',
};

// Input Data is using to allow filter and update functions be always optimized
class InputData {
    inputType: string;
    data: string | null;
    selectionStart: number | null;
    selectionEnd: number | null;

    constructor (inputType: string, data: string | null, selectionStart: number | null, selectionEnd: number | null) {
        this.inputType = inputType;
        this.data = data;
        this.selectionStart = selectionStart;
        this.selectionEnd =
            selectionEnd === undefined ? selectionStart : selectionEnd;
    }
}

export type TermsInputRenderDelegate = (value: string, uncoveredTerms?: string[]) => (JSX.Element | string)[] | null;

export type TermsInputProps = {
    className?: string;
    value?: string;
    uncoveredTerms?: string[];
    showSearchIcon?: boolean;
    render: TermsInputRenderDelegate;
    filter?: (newValue: string, value?: string, inputData?: InputData | null) => string;
    onChange: (value: string) => void;
    onUpdate?: (newValue: string, value?: string, inputData?: InputData | null) => void;
    modifiers?: {
        focus?: string;
    };
}

@observer
export class TermsInput extends React.Component<TermsInputProps> {
    private _wrapperRef = React.createRef<HTMLSpanElement>();
    private _inWrapperRef = React.createRef<HTMLSpanElement>();
    private _placeholderRef = React.createRef<HTMLSpanElement>();
    private _inputRef = React.createRef<HTMLInputElement>();
    private _stubRef = React.createRef<HTMLSpanElement>();
    @observable private _hasFocus: boolean = false;
    @observable.ref private _inputData: InputData | null = null;

    constructor (props: TermsInputProps) {
        super(props);
        makeObservable(this);
    }

    componentDidMount () {
        const { _stubRef, _inputRef, _placeholderRef, _inWrapperRef } = this;
        const stubElement = _stubRef.current;
        const inputElement = _inputRef.current;
        const placeholderElement = _placeholderRef.current;
        const wrapperElement = _inWrapperRef.current;

        if (stubElement == null)
            throw new Error('Ref to Stub is not defined');
        if (inputElement == null)
            throw new Error('Ref to Input is not defined');
        if (placeholderElement == null)
            throw new Error('Ref to Placeholder is not defined');
        if (wrapperElement == null)
            throw new Error('Ref to Wrapper is not defined');

        const style = window.getComputedStyle(inputElement);

        stubElement.style.paddingTop = style.paddingTop;
        stubElement.style.paddingBottom = style.paddingBottom;
        stubElement.style.paddingRight = style.paddingRight;
        stubElement.style.paddingLeft = style.paddingLeft;
        stubElement.style.border = style.border;
        stubElement.style.fontFamily = style.fontFamily;
        stubElement.style.fontSize = style.fontSize;

        inputElement.addEventListener('beforeinput', this._onBeforeInputUpdate);
        this._updateWidth();
    }

    componentWillUnmount () {
        this._inputRef.current?.removeEventListener('beforeinput', this._onBeforeInputUpdate);
    }

    render () {
        const {
            render,
            className,
            modifiers,
            value,
            showSearchIcon,
            uncoveredTerms,
            ...props
        } = this.props;
        const { _hasFocus } = this;
        const classes = [];
        let wrapperStyle;

        if (className) {
            classes.push(className);
            wrapperStyle = styles.wrapper;
        } else {
            wrapperStyle = styles.wrapperDefault;
        }

        if (_hasFocus) {
            classes.push(modifiers?.focus ?? '--focus');
        }

        return (
            <span
                ref={this._wrapperRef}
                className={classes.join(' ')}
                style={wrapperStyle}
            >
                {showSearchIcon && <Icon name="search"/>}
                <span ref={this._inWrapperRef} style={styles.innerWrapper}>
                    <input
                        {...props}
                        onChange={this._onInputChange}
                        style={styles.input}
                        ref={this._inputRef}
                        onInput={this._onInput}
                        onFocus={this._onInputFocus}
                        onBlur={this._onInputBlur}
                        value={value}
                    />
                    <span
                        aria-hidden
                        style={styles.placeholder}
                        ref={this._placeholderRef}
                    >
                        {render(value ?? '', uncoveredTerms) || '\u200B'}
                    </span>
                    <span aria-hidden ref={this._stubRef} style={styles.stub}/>
                </span>
            </span>
        );
    }

    @action.bound
    private _onInputChange (e: ChangeEvent) {
        const { onChange } = this.props;
        const input = e.target as HTMLInputElement;
        onChange?.(input.value);
    }

    @action.bound
    private _onBeforeInputUpdate (e: InputEvent) {
        const input = e.target as HTMLInputElement;
        this._inputData = new InputData(
            e.inputType,
            e.data,
            input.selectionStart,
            input.selectionEnd,
        );
    }

    @action.bound
    private _onInputFocus () {
        this._hasFocus = true;
    }

    @action.bound
    private _onInputBlur () {
        this._hasFocus = false;
    }

    @action.bound
    private _onInput (e: FormEvent<HTMLInputElement>) {
        const { props: { value }, _inputData } = this;
        const filter = this.props.filter;
        const target = e.target as HTMLInputElement;

        const newValue = filter ? filter(target.value, value, _inputData) : target.value;

        this._inputData = null;

        this._updateWidth();

        if (value !== newValue) {
            this.props.onUpdate?.(newValue, value, _inputData);
        }
    }

    private _updateWidth () {
        const stubElement = this._stubRef.current;
        const placeholderElement = this._placeholderRef.current;
        const inputElement = this._inputRef.current;

        if (stubElement == null)
            throw new Error('Ref to Stub is not defined');
        if (inputElement == null)
            throw new Error('Ref to Input is not defined');
        if (placeholderElement == null)
            throw new Error('Ref to Placeholder is not defined');

        stubElement.textContent = this.props.value ?? '';
        placeholderElement.style.width = inputElement.style.width = 2 + stubElement.offsetWidth + 'px';
    }
}
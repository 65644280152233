import * as React from 'react';
import { Button } from 'reactstrap';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Line } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';

import { MessageQueueItemDirectionType } from '@app/AppConstants';
import { DateTimeService } from '@services/DateTimeService';
import { displayName, isDateTime } from '@services/Validation';
import { BaseFormModel } from '@services/BaseFormModel';
import { emitter } from '@services/EventEmitter';
import { Icon } from '@components/Icon';
import { HealthBoardStore } from '../HealthBoard';
import { DatePickerValidatedControl } from '@components/DateTimeControls/DateTimeControls';

type RequestGraphProps = {
    healthBoardStore: HealthBoardStore;
};

@observer
export class RequestGraph extends React.Component<RequestGraphProps> {
    private _store: RequestGraphStore;

    constructor (props: RequestGraphProps) {
        super(props);
        makeObservable(this);
        this._store = new RequestGraphStore(props.healthBoardStore);
        emitter.on('theme', this.onThemeUpdate);
    }

    componentDidMount () {
        const {
            overlay, chartCanvas,
            wrapper, onMouseDown, onMouseMove,
            onMouseUp, onBodyMouseUp, onResize
        } = this._store;
        if (overlay && chartCanvas) {
            overlay.width = chartCanvas.width;
            overlay.height = chartCanvas.height;
        }
        wrapper?.addEventListener('mousedown', onMouseDown);
        wrapper?.addEventListener('mouseup', onMouseUp);
        wrapper?.addEventListener('mousemove', onMouseMove);
        document.body.addEventListener('mouseup', onBodyMouseUp);
        window.addEventListener('resize', onResize);
    }

    componentWillUnmount () {
        const { wrapper, onMouseDown, onMouseMove, onMouseUp, onBodyMouseUp, onResize } = this._store;
        wrapper?.removeEventListener('mousedown', onMouseDown);
        wrapper?.removeEventListener('mouseup', onMouseUp);
        wrapper?.removeEventListener('mousemove', onMouseMove);
        document.body.removeEventListener('mouseup', onBodyMouseUp);
        window.removeEventListener('resize', onResize);
        emitter.off('theme', this.onThemeUpdate);
    }

    render () {
        const {
            chartRef, overlayRef, wrapperRef,
            chartOptions, requestGraphData,
            form, updateFromDate, clearDate, isEmpty
        } = this._store;
        return (
            <>
                <div className="job-progress-node-filter">
                    <div className="label-title">From:</div>
                    <DatePickerValidatedControl
                        name="fromDateTime"
                        formModel={form}
                        onChange={updateFromDate}
                        pickerMode="datetime"
                        pickerSize="sm"
                        viewMode="time"
                    />
                    <Button
                        color="white"
                        size="sm"
                        className="rg-seft-test"
                        onClick={clearDate}
                        title="Clear"
                    >
                        <Icon name="times"/>
                    </Button>
                </div>
                <div className="chart-wrapper" ref={wrapperRef}>
                    <canvas className="chart-wrapper-overlay" ref={overlayRef}/>
                    <Line
                        key={document.body.className}
                        data={requestGraphData}
                        options={chartOptions}
                        height={50}
                        ref={chartRef}
                    />
                </div>
                {isEmpty && <span>No data for the last two hours</span>}
            </>
        );
    }

    @action.bound
    onThemeUpdate () {
        this._store.updateChartOptions();
    }
}

class FormModel extends BaseFormModel {
    @observable
    @displayName('From Time')
    @isDateTime()
    fromDateTime: string = '';

    constructor () {
        super();
        makeObservable(this);
    }

}

class RequestGraphStore {
    public wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
    public chartRef: React.RefObject<Line> = React.createRef();
    public overlayRef: React.RefObject<HTMLCanvasElement> = React.createRef();
    private _healthBoardStore: HealthBoardStore;
    private _startIndex: number = -1;
    private _endIndex: number = -1;
    private _drag: boolean = false;
    private _selectionRect = { w: 0, x: 0 };

    @observable public isDark: boolean;

    form: FormModel = new FormModel();
    liveMode: boolean = true;

    constructor (healthBoardStore: HealthBoardStore) {
        makeObservable(this);
        this._healthBoardStore = healthBoardStore;
        this.updateChartOptions();
    }

    updateChartOptions () {
        this.isDark = document.body.classList.contains('dark');
        const selectionContext = this.overlay?.getContext('2d');
        if (selectionContext) {
            selectionContext.fillStyle = this.isDark ? '#fff' : '#000';
        }
    }

    @computed
    get chartOptions () {
        const chartOptions: ChartOptions = {
            legend: {
                display: !this.isEmpty
            },
            maintainAspectRatio: false,
            responsive: true,
        };

        if (this.isDark) {
            chartOptions.scales = {
                yAxes: [{
                    gridLines: {
                        color: '#333',
                        zeroLineColor: '#333',
                    }
                }],
                xAxes: [{
                    gridLines: {
                        color: '#333',
                        zeroLineColor: '#333',
                    }
                }]
            };
            chartOptions.legend!.labels = {
                fontColor: '#888'
            };
        }

        return chartOptions;
    }

    @computed
    get isEmpty () {
        return !this._healthBoardStore.data.length;
    }

    @computed
    get requestGraphData () {
        return this.isEmpty ? this.emptyData() : this.serverData();
    }

    updateFromDate = (time: Date) => {
        if (!DateTimeService.isValidDate(time)) {
            this.form.fromDateTime = '';
        }
        this.updateData();
    };

    clearDate = () => {
        this.form.fromDateTime = '';
        this.updateData();
    };

    updateData () {
        if (!this.form.fromDateTime) {
            if (!this.liveMode) {
                this.liveMode = true;
                this._healthBoardStore.initStore();
            }
        } else {
            this.liveMode = false;
            void this._healthBoardStore.loadDataFromDate(DateTimeService.parseUiDateTime(this.form.fromDateTime));
        }
        this._clearSelection();
    }

    serverData () {
        const bgColors: Map<string, string> = new Map([
            ['errorin', 'rgba(205,44,36,0.4)'],
            ['aircraftmaintenancein', 'rgba(240, 215, 112, 0.4)'],
            ['aircraftin', 'rgba(79, 169, 184, 0.4)'],
            ['aircrafttypein', 'rgba(78,113,120,0.4)'],
            ['airportin', 'rgba(77,148,83,0.4)'],
            ['flightin', 'rgba(114,199,235,0.4)'],
            ['acarsoooiin', 'rgba(32,43,48,0.4)'],
            ['acarsm45', 'rgba(32,243,48,0.4)'],
            ['personin', 'rgba(191,175,127,0.4)'],
            ['rotationin', 'rgba(145,60,17,0.4)'],
            ['persondayin', 'rgba(165,170,73,0.4)'],
            ['persondayout', 'rgba(205,210,113,0.4)'],
            ['crewnotificationin', 'rgba(215,10,173,0.4)'],
            ['crewnotificationout', 'rgba(255,50,213,0.4)'],
            ['deadlettertoinbox', 'rgba(255,60,60,0.4)'],
            ['crewassignmentin', 'rgba(225, 128, 0, 0.4)'],
            ['crewassignmentout', 'rgba(255, 128, 0, 0.4)']
        ]);

        const borderColors: Map<string, string> = new Map([
            ['errorin', 'rgba(205, 44, 36, 1)'],
            ['aircraftmaintenancein', 'rgba(240, 215, 112, 1)'],
            ['aircraftin', 'rgba(79,169,184,1)'],
            ['aircrafttypein', 'rgba(78,113,120,1)'],
            ['airportin', 'rgba(77,148,83,1)'],
            ['flightin', 'rgba(114,199,235,1)'],
            ['acarsoooiin', 'rgba(32,43,48,1)'],
            ['acarsm45', 'rgba(32,243,48,1)'],
            ['personin', 'rgba(191,175,127,1)'],
            ['rotationin', 'rgba(145,60,17,1)'],
            ['persondayin', 'rgba(165,170,73,1)'],
            ['persondayout', 'rgba(205,210,113,1)'],
            ['crewnotificationin', 'rgba(215,10,173,1)'],
            ['crewnotificationout', 'rgba(255,50,213,1)'],
            ['deadlettertoinbox', 'rgba(60,60,60,1)'],
            ['crewassignmentin', 'rgba(225, 128, 0, 1)'],
            ['crewassignmentout', 'rgba(255, 128, 0, 1)']
        ]);

        const timePoints = this._healthBoardStore.data[0].statistics.map((item) => item.key);

        const dataset = this._healthBoardStore.data.map((item) => {
            let stat = new Array<number>(timePoints.length);
            stat = stat.fill(0);

            for (let i = 0; i < item.statistics.length; i++) {
                if (item.statistics[i].value === 0) continue; //count

                const index = timePoints.indexOf(item.statistics[i].key);
                if (index === -1) continue;

                stat[index] = item.statistics[i].value;
            }

            const label = item.directionType === MessageQueueItemDirectionType.DeadletterToInbox ? `${item.messageType} Deadletter` : `${item.messageType} ${item.directionType}`;
            const styleKey = (item.directionType === MessageQueueItemDirectionType.DeadletterToInbox ? item.directionType : item.messageType + item.directionType).toLowerCase();

            return {
                label: label,
                fill: false,
                backgroundColor: bgColors.get(styleKey) ?? 'rgba(128,128,128,0.5)',
                borderColor: borderColors.get(styleKey) ?? 'rgba(128,128,128,0.5)',
                data: stat
            };
        });

        return {
            labels: timePoints,
            datasets: dataset
        };
    }

    emptyData () {
        let dateTo = this.form.fromDateTime ? DateTimeService.addTime(DateTimeService.parseUiDateTime(this.form.fromDateTime), 0, 120) : DateTimeService.now();
        const labels: string[] = [];
        const data = new Array(120);
        for (let i = 0; i < 120; i++) {
            const time = DateTimeService.toUiTime(dateTo);
            labels.push(time);
            dateTo = DateTimeService.addTime(dateTo, 0, -1);
        }

        return {
            labels: labels.reverse(),
            datasets: [
                {
                    label: '',
                    fill: false,
                    backgroundColor: 'rgba(191, 175, 127, 0.4)',
                    borderColor: 'rgba(191,175,127,1)',
                    data: data
                }
            ]
        };
    }

    private _getPointIndexFromEvent (event: MouseEvent) {
        const points = (this.chartInstance as unknown as {
            getElementsAtXAxis: (e: MouseEvent) => [{ _index: number }]
        }).getElementsAtXAxis(event);
        return points.length ? points[0]._index : -1;
    }

    public onMouseDown = (event: MouseEvent) => {
        this._startIndex = this._getPointIndexFromEvent(event);
        if (this.chartInstance && this.chartCanvas && this._startIndex !== -1) {
            const rect = this.chartCanvas?.getBoundingClientRect();
            this._selectionRect = { w: 0, x: event.clientX - rect.left };
            this._drag = true;
        }
    };

    public onMouseMove = (event: MouseEvent) => {
        const selectionContext = this.overlay?.getContext('2d');
        if (this.chartInstance && this.chartCanvas && selectionContext && this._drag) {
            const rect = this.chartCanvas.getBoundingClientRect();
            this._endIndex = this._getPointIndexFromEvent(event);
            this._selectionRect.w = this._drag ? (event.clientX - rect.left) - this._selectionRect.x : this._selectionRect.w;
            selectionContext.fillStyle = this.isDark ? '#fff' : '#000';
            selectionContext.globalAlpha = 0.3;
            selectionContext.clearRect(0, 0, this.chartCanvas.width, this.chartCanvas.height);
            selectionContext.fillRect(this._selectionRect.x,
                this.chartInstance.chartArea.top,
                this._selectionRect.w,
                this.chartInstance.chartArea.bottom - this.chartInstance.chartArea.top);
        }
    };

    public onBodyMouseUp = () => {
        if (this._drag) {
            const startLabel = this.requestGraphData.labels[this._startIndex];
            const endLabel = this.requestGraphData.labels[this._endIndex];
            if (this._startIndex > this._endIndex) {
                this._updateSelection(endLabel, startLabel);
            } else {
                this._updateSelection(startLabel, endLabel);
            }
            this._drag = false;
        }
    };

    private _clearSelection () {
        const selectionContext = this.overlay?.getContext('2d');
        if (selectionContext && this.chartCanvas) {
            selectionContext.clearRect(0, 0, this.chartCanvas.width, this.chartCanvas.height);
            this._updateSelection();
        }
    }

    public onResize = () => {
        this._clearSelection();
    };

    public onMouseUp = (event: MouseEvent) => {
        const selectionContext = this.overlay?.getContext('2d');
        event.stopPropagation();
        if (selectionContext && this.chartCanvas && this.chartInstance) {
            const rect = this.chartCanvas.getBoundingClientRect();
            if (this._startIndex === this._endIndex) {
                this._clearSelection();
            } else {
                this._selectionRect.w = (event.clientX - rect.left) - this._selectionRect.x;
                const startLabel = this.requestGraphData.labels[this._startIndex];
                const endLabel = this.requestGraphData.labels[this._endIndex];
                if (this._startIndex > this._endIndex) {
                    this._updateSelection(endLabel, startLabel);
                } else {
                    this._updateSelection(startLabel, endLabel);
                }
            }
            this._drag = false;
        }
    };

    private _updateSelection (fromTime?: string, toTime?: string) {
        this._healthBoardStore.setRange(fromTime && toTime ? {
            dateFrom: DateTimeService.parseUiTime(this._healthBoardStore.date, fromTime),
            dateTo: DateTimeService.parseUiTime(this._healthBoardStore.date, toTime)
        } : null);
    }

    @computed
    get wrapper () {
        return this.wrapperRef.current;
    }

    @computed
    get overlay () {
        return this.overlayRef.current;
    }

    @computed
    get chartInstance () {
        return this.chartRef.current?.chartInstance;
    }

    @computed
    get chartCanvas () {
        return this.chartInstance?.canvas;
    }
}

import * as React from 'react';
import { observer } from 'mobx-react';

import { aircraftHeight, aircraftLineHeight, DisplayAircraft, GanttFlight, GanttMaintenance } from './FlightsGanttTypes';

type AircraftListItemProps<TFlight extends GanttFlight, TMaintenance extends GanttMaintenance> = {
    aircraft: DisplayAircraft<TFlight, TMaintenance>;
    className?: string;
    index: number;
    isActive: boolean;
};

@observer
export class AircraftListItem<TFlight extends GanttFlight, TMaintenance extends GanttMaintenance> extends React.Component<AircraftListItemProps<TFlight, TMaintenance>, {}> {

    public render() {
        const { className, index, isActive, aircraft: { registrationNumber, lines } } = this.props;

        let aircraftClassName = 'aircraft-list-item';
        if (className) {
            aircraftClassName += ' ' + className;
        }

        if (index % 2 === 1) {
            aircraftClassName += ' even';
        }

        if (isActive) {
            aircraftClassName += ' active';
        }

        const height = aircraftHeight + lines.length * aircraftLineHeight;

        return (
            <>
                <div className={aircraftClassName} style={{ height: height }}>
                    {registrationNumber ?? '??-???'}
                </div>
            </>
        );
    }
}
import { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Badge } from 'reactstrap';
import { useSearchParams } from 'react-router-dom';

import { Application, ToiShortModel } from '@app/Models/WebApiModels';
import { stringSearchService } from '@services/StringSearchService';
import { DateTimeService } from '@services/DateTimeService';

import { Icon } from '@components/Icon';
import { ModalButtonType, modalService } from '@components/Modal/Modal.tsx';
import { CreateEditToiDialog, CreateEditToiDialogProps } from '@components/CreateEditToiDialog/CreateEditToiDialog';
import './_note-item.scss';

type NoteItemProps = {
    item: ToiShortModel;
    search?: string;
    reloadToi: () => void;
}

export const NoteItem: FC<NoteItemProps> = observer(({ item, search, reloadToi }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    
    const _onDuplicateClick = useCallback(async () => {
        void showEditDialog({ id: item.id, isDuplicate: true, onSaveCallback: reloadToi });
    }, []);

    const _onEditClick = useCallback(async () => {
        setSearchParams({ id: item.id });
    }, [item.id, setSearchParams]);

    const showEditDialog = useCallback(async (props: CreateEditToiDialogProps) => {
        const res = await modalService.show(CreateEditToiDialog, props);
        switch (res.button) {
            case ModalButtonType.Delete:
            case ModalButtonType.Save: {
                void reloadToi();
                setSearchParams({});
                break;
            }
            case ModalButtonType.Cancel: {
                setSearchParams({});
                break;
            }
        }
    }, [reloadToi, setSearchParams]);

    useEffect(() => {
        if (searchParams.get('id') && searchParams.get('id') === item.id) {
            void showEditDialog({ id: item.id, onSaveCallback: reloadToi });
        }
    }, [searchParams, showEditDialog, item.id, reloadToi]);

    const highlightSearchedText = (text: string) => {
        return search ? stringSearchService.highlight(text, search.trim().split(' ')) : text;
    };

    const renderToiAssignment = () => {
        const { countryThreeLetterCodes, airportThreeLetterCodes, aircrafts, airlineRcd, flightNumber, isLongHaul } = item.assignment!;
        return (
            <div className="note-filter">
                {airportThreeLetterCodes?.map((c, i) => (
                    <Badge key={i}><Icon name="city" /> {c}</Badge>
                ))}
                {countryThreeLetterCodes?.map((c, i) => (
                    <Badge key={i}><Icon name="map-location" /> {c}</Badge>
                ))}
                {isLongHaul === true && <Badge><Icon name="globe" title="Long-Haul" /></Badge>}
                {isLongHaul === false && <Badge><Icon name="location-dot" title="Short-Haul" /></Badge>}
                {aircrafts?.map((a, i) => (
                    <Badge key={i}><Icon name="plane" /> {a}</Badge>
                ))}
                {typeof flightNumber == 'number' &&
                    <Badge><Icon name="plane" /> {airlineRcd ? `${airlineRcd}-` : ''}{flightNumber}</Badge>}
            </div>
        );
    };

    const renderToiAttachments = () => {
        const { attachments } = item;
        return (
            <div className="note-item-attachments">
                {attachments?.map((a, i) => (
                    <Badge key={i}><Icon name="file" /> {a.name}</Badge>
                ))}
            </div>
        );
    };

    const getApplicationLabel = useCallback(() => {
        const indexOfApplication = item.applicableFor ? Object.keys(Application).indexOf(item.applicableFor) : -1;
        return Object.values(Application)[indexOfApplication];
    }, [item.applicableFor]);

    return (
        <>
            <div className="note-item">
                <div onClick={_onEditClick} className="action" title="Edit" >
                    <Icon name="pen" />
                </div>
                <div onClick={_onDuplicateClick} className="action" title="Copy">
                    <Icon name="copy" />
                </div>
                <div className="note-item-subject">
                    <div dangerouslySetInnerHTML={{ __html: highlightSearchedText(item.subject || '[No subject]') }} />
                </div>
                <div className="note-item-content">
                    <div dangerouslySetInnerHTML={{ __html: highlightSearchedText(item.content || '') }} />
                </div>

                <div className="d-flex justify-content-between mt-2">
                    <div>
                        <Badge>{item.category}</Badge>
                        {!item.isActive && <Badge color="danger">Inactive</Badge>}
                        {!item.endUtc && <Badge>{DateTimeService.format(item.startUtc, "DD MMM 'YY")}</Badge>}
                        {item.endUtc && (
                            <Badge>
                                {DateTimeService.format(item.startUtc, `DD MMM 'YY`)} - {DateTimeService.format(item.endUtc, `DD MMM 'YY`)}
                            </Badge>
                        )}
                        {!!item.applicableFor && <Badge>{getApplicationLabel()}</Badge>}
                        {renderToiAttachments()}
                        <div className="note-filters">{renderToiAssignment()}</div>                        
                    </div>
                    <div className="note-item-person-info">
                        <div>
                            <Badge>Created at: {DateTimeService.toUiDateTime(item.createdAtUtc)}</Badge>
                            {item.createdByDisplayName && (
                                <div>
                                    <Badge>
                                        Created by: {item.createdByDisplayName}
                                        {item.createdByDepartment ? `, ${item.createdByDepartment}` : ''}
                                    </Badge>
                                </div>
                            )}
                        </div>
                        {item.modifiedAtUtc && (
                            <div>
                                <Badge>Modified at: {DateTimeService.toUiDateTime(item.modifiedAtUtc)}</Badge>
                                {item.modifiedByDisplayName && (
                                    <div>
                                        <Badge>
                                            Modified by: {item.modifiedByDisplayName}
                                            {item.modifiedByDepartment ? `, ${item.modifiedByDepartment}` : ''}
                                        </Badge>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
});

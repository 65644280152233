export class ClipboardService{
    public static copyToClipboard(text: string) {
        let input = document.createElement('input');
        input.style.position = 'fixed';
        input.style.top = '-5px';
        input.style.border = 'none';
        input.style.outline = 'none';
        input.style.boxShadow = 'none';
        input.style.background = 'transparent';
        input.value = text;
        document.body.appendChild(input);
        input.focus();
        input.select();
        try {
            document.execCommand('copy');
        } finally {
            document.body.removeChild(input);
        }
    }
}
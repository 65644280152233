import * as React from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

import { DateTime, ApiUrls } from '@app/AppConstants';
import { BaseFormModel } from '@services/BaseFormModel';

import { displayName, isDateAfter, isDateBefore, isDateTime, isRequired } from '@services/Validation';
import { DateTimeService } from '@services/DateTimeService';
import ApiService from '@app/Services/ApiService';

import { IModalDialogContent, ModalButtonType, ModalDialogOptions, ModalWindow } from '@components/Modal/Modal';
import { PromiseCompletion } from '@classes/PromiseCompletion';
import { getEncodedToken } from '@helpers/JWTHelper';
import { appStore } from '@app/AppStore';
import { FormFeedback } from 'reactstrap';
import { ReactSelect, ReactSelectSize } from '@components/ReactSelect/ReactSelect';
import { Range } from '@helpers/RangeHelper';
import { DatePickerValidatedControl } from '@components/DateTimeControls/DateTimeControls.tsx';

export type DownloadServiceMessagesDialogProps = {
    range: Range | null;
};

@observer
export class DownloadServiceMessagesDialog extends React.Component<DownloadServiceMessagesDialogProps> implements IModalDialogContent<void> {
    private _store: DownloadServiceMessagesStore;

    constructor(props: DownloadServiceMessagesDialogProps) {
        super(props);
        makeObservable(this);
        this._store = new DownloadServiceMessagesStore(props.range);
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        return {
            title: 'Select Time Range',
            buttons: [
                {
                    type: ModalButtonType.Cancel,
                    onClick: () => {
                        window.close();
                    }
                },
                {
                    type: ModalButtonType.Download,
                    onClick: async () => {
                        if (this._onSubmit()) {
                            window.close();
                        }
                    }
                }
            ],
            disableAutoFocus: true,
            loader: this._store.loader
        };
    }

    render() {
        const {
            _store: { form, messagesDates, minDate, maxDate, dateIsValid, loadLabels, labels, labelsLoader, loadTags, tags, tagsLoader }
        } = this;

        return (
            <>
                <div className="label-title">From:</div>
                <DatePickerValidatedControl name="fromDateTime" pickerMode="datetime" formModel={form} onChange={this._onChangeDate} minDate={form.toDateTime ? minDate : undefined} maxDate={form.toDateTime ? maxDate : undefined} pickerSize="sm" availableDates={messagesDates} />

                <div className="label-title">To:</div>
                <DatePickerValidatedControl name="toDateTime" pickerMode="datetime" formModel={form} onChange={this._onChangeDate} pickerSize="sm" minDate={form.fromDateTime ? minDate : undefined} maxDate={form.fromDateTime ? maxDate : undefined} availableDates={messagesDates} />

                {!dateIsValid && minDate && maxDate && form.validated && <div className="label-small mt-1 mb-2 text-danger">Fields To/From is not in range</div>}

                <div className="label-title">Label filter:</div>
                <ReactSelect
                    value={form.labelFilter}
                    selectSize={ReactSelectSize.Regular}
                    className="select-filter-full"
                    onMenuOpen={loadLabels}
                    isLoading={labelsLoader.isPending}
                    options={labels}
                    getOptionLabel={(x) => x}
                    getOptionValue={(x) => x}
                    onSelect={(value) => {
                        form.labelFilter = Array.isArray(value) ? value[0] : value || '';
                    }}
                    isDisabled={!dateIsValid}
                />
                {this._renderValidationError('labelFilter')}

                <div className="label-title">Tag filter:</div>
                <ReactSelect
                    value={form.tagFilter}
                    selectSize={ReactSelectSize.Regular}
                    className="select-filter-full"
                    onMenuOpen={loadTags}
                    isLoading={tagsLoader.isPending}
                    options={tags}
                    getOptionLabel={(x) => x}
                    getOptionValue={(x) => x}
                    isCreatable
                    onSelect={(value) => {
                        form.tagFilter = Array.isArray(value) ? value[0] : value || '';
                    }}
                    isClearable
                    isDisabled={!dateIsValid || !form.labelFilter}
                />
            </>
        );
    }

    private _renderValidationError(name: keyof FormModel) {
        return (
            this._store.form.validated &&
            this._store.form.validationErrorsName(name) &&
            !!this._store.form.validationErrorsName(name).length && (
                <FormFeedback style={{ display: 'block' }}>
                    {this._store.form.validationErrorsName(name).map((error: string) => (
                        <div key={error}>{error}</div>
                    ))}
                </FormFeedback>
            )
        );
    }

    @action.bound
    _onChangeDate() {
        this._store.clearLabels();
    }

    _onSubmit() {
        const { validateForm, form } = this._store;
        if (!validateForm()) return false;

        const fromDateTime = DateTimeService.parseUiDateTime(form.fromDateTime);
        const toDateTime = DateTimeService.parseUiDateTime(form.toDateTime);

        const encodedToken = getEncodedToken(appStore.currentToken);
        window.location.href = ApiUrls.ServiceDownloadMessagesUrl + '?fromDateTime=' + DateTimeService.format(fromDateTime, DateTime.jsonDateTimeFormat) + '&toDateTime=' + DateTimeService.format(toDateTime, DateTime.jsonDateTimeFormat) + '&labelFilter=' + encodeURIComponent(form.labelFilter) + '&tagFilter=' + encodeURIComponent(form.tagFilter) + '&token=' + encodeURIComponent(encodedToken);

        return true;
    }
}

class FormModel extends BaseFormModel {
    @observable
    @isRequired()
    @isDateTime()
    @isDateBefore('toDateTime', DateTime.viewFullFormat)
    @displayName('From')
    fromDateTime: string = '';

    @observable
    @isRequired()
    @isDateTime()
    @isDateAfter('fromDateTime', DateTime.viewFullFormat)
    @displayName('To')
    toDateTime: string = '';

    @observable
    @isRequired()
    @displayName('Label filter')
    labelFilter: string = '';

    @observable
    @displayName('Tag filter')
    tagFilter: string = '';

    @computed
    get selectedDate() {
        return DateTimeService.parseUiDate(this.fromDateTime || this.toDateTime);
    }

    constructor() {
        super();
        makeObservable(this);
    }
}

class DownloadServiceMessagesStore {
    @observable messagesDates: Date[] = [];
    @observable labels: string[] = [];
    @observable labelsLoader: PromiseCompletion = new PromiseCompletion();
    @observable tags: string[] = [];
    @observable tagsLoader: PromiseCompletion = new PromiseCompletion();
    form: FormModel = new FormModel();
    public loader: PromiseCompletion = new PromiseCompletion();

    constructor(range: Range | null) {
        makeObservable(this);
        if (range) {
            this.form.fromDateTime = DateTimeService.toUiDateTime(range.dateFrom);
            this.form.toDateTime = DateTimeService.toUiDateTime(range.dateTo);
        }
        void this._loadMessagesDatesAsync();
    }

    validateForm = () => {
        return this.form.validate() && this.dateIsValid;
    };

    @computed
    public get minDate() {
        const viewDate = DateTimeService.withTime(this.form.selectedDate, 0, 0);
        return DateTimeService.isValidDate(viewDate) ? DateTimeService.addDays(viewDate, -1) : undefined;
    }

    @computed
    public get maxDate() {
        const viewDate = DateTimeService.withTime(this.form.selectedDate, 0, 0);
        return DateTimeService.isValidDate(viewDate) ? DateTimeService.addDays(viewDate, 2) : undefined;
    }

    private async _loadMessagesDatesAsync() {
        const { data } = await ApiService.getTypedData<string[]>(ApiUrls.ServiceMessagesDatesUrl);
        this.messagesDates = data.map((date) => DateTimeService.fromString(date));
    }

    @action.bound
    public clearLabels() {
        this.labels = [];
        this.form.labelFilter = '';
        this.clearTags();
    }

    @action.bound
    public clearTags() {
        this.tags = [];
        this.form.tagFilter = '';
    }

    public loadLabels = async () => {
        if (this.dateIsValid) {
            const params = {
                fromDateTime: DateTimeService.parseUiDateTime(this.form.fromDateTime),
                toDateTime: DateTimeService.parseUiDateTime(this.form.toDateTime)
            };
            const { data } = await ApiService.getTypedData<string[]>(ApiUrls.ServiceMessagesLabelsUrl, params, { completion: this.labelsLoader });
            this.labels = data;
        }
    };

    public loadTags = async () => {
        const params = {
            fromDateTime: DateTimeService.parseUiDateTime(this.form.fromDateTime),
            toDateTime: DateTimeService.parseUiDateTime(this.form.toDateTime),
            labelFilter: this.form.labelFilter
        };
        const { data } = await ApiService.getTypedData<string[]>(ApiUrls.ServiceMessagesTagsUrl, params, { completion: this.tagsLoader });
        this.tags = data;
    };

    @computed
    get dateIsValid() {
        if (!this.minDate || !this.maxDate || !this.form.fromDateTime || !this.form.toDateTime) return false;
        if (DateTimeService.parseUiDateTime(this.form.fromDateTime) > this.maxDate || DateTimeService.parseUiDateTime(this.form.fromDateTime) < this.minDate) return false;
        return !(DateTimeService.parseUiDateTime(this.form.toDateTime) > this.maxDate || DateTimeService.parseUiDateTime(this.form.toDateTime) < this.minDate);
    }
}

import * as React from 'react';
import './job-list-title.scss';

type JobListTitleProps = {
    showExecutions?: boolean;
    executions: string[];
    failedJobs: string[];
};

export default class JobListTitle extends React.PureComponent<JobListTitleProps, {}> {
    public render() {
        const { showExecutions, executions, failedJobs } = this.props;
        return (
            <span className="job-list-title">
                Job List
                <span className="job-top-executions">
                    {!!failedJobs.length && (
                        <div className="job-top-executions-list">
                            <b>Failed:</b>
                            {failedJobs.map((x, i) => (
                                <span key={'topFailedJob_' + i} className="top-job-item badge bg-danger">
                                    {x}
                                </span>
                            ))}
                        </div>
                    )}
                    {showExecutions && (
                        <div className="job-top-executions-list">
                            <b>Executions:</b>
                            {executions.map((x, i) => (
                                <span key={'topExecutions_' + i} className="top-job-item-executions badge bg-info">
                                    {x}
                                </span>
                            ))}
                        </div>
                    )}
                </span>
            </span>
        );
    }
}
